import { FunctionComponent } from 'react';
import { assert } from '../../utils/assert';
import { getCurrentTimestamp } from '../../utils/date';
import { i18nInstance } from '../../i18n/client';
import { useTranslation } from 'react-i18next';

type TimestampProps = {
  date: string | number | Date;
};

const timeFormat = Intl.RelativeTimeFormat
  ? new Intl.RelativeTimeFormat(i18nInstance.language, {
      style: 'long',
    })
  : null;

export const Timestamp: FunctionComponent<TimestampProps> = ({ date }) => {
  const { t } = useTranslation();

  const now = getCurrentTimestamp();
  const utcTime = new Date(date).getTime();

  if (!timeFormat) {
    // Fallback - if the browser doesn't support Intl.RelativeTimeFormat render the date
    return new Date(date).toLocaleDateString();
  }

  if (utcTime < 0 || now <= utcTime) {
    return null;
  }

  return <>{formatTime(t, now - utcTime)}</>;
};

function formatTime(t: (key: string) => string, time: number): string {
  assert(timeFormat);

  const period = getTimePeriod(time);

  if (period === 'second') {
    return t('timestamp.secondsAgo');
  }

  const number = Math.floor(time / timePeriods[period]);

  return timeFormat.format(-number, period);
}

const timePeriods = {
  year: 31557600000,
  month: 2629800000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 0,
};

type TimePeriod = keyof typeof timePeriods;

function getTimePeriod(time: number): TimePeriod {
  for (const [name, period] of Object.entries(timePeriods)) {
    if (time >= period) {
      return name as TimePeriod;
    }
  }

  assert(false, 'unreachable'); // as long as time is >= 0 the loop will always return
}
