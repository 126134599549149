import clientConfig from '../config';

interface DonateUrlResponse {
  redirectUrl: string;
}

const getDonateUrlWithJwt = async (jwt: string): Promise<DonateUrlResponse> => {
  const res = await fetch(clientConfig.linkServiceStatelessDonateUrl, {
    body: JSON.stringify({ payload: jwt }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    console.error('Error fetching donate URL:', res.statusText);
    throw new Error('Failed to get donate URL');
  }

  return res.json();
};

export default getDonateUrlWithJwt;
