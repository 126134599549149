import React, { SVGProps } from 'react';

export const InlineMenuIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color,
  width = 24,
  height = 24,
  fill,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="4" r="2" fill="#999" />
      <circle cx="12" cy="12" r="2" fill="#999" />
      <circle cx="12" cy="20" r="2" fill="#999" />
    </svg>
  );
};
