import { v4 as uuidv4 } from 'uuid';

export function uuid() {
  return uuidv4(); // the built-in crypto.randomUUID() is not supported on Safari 14
}

export function createUidGenerator(prefix: string) {
  const id = uuid().substring(0, 8);

  return (suffix: string) => `${prefix}-${id}-${suffix}`;
}
