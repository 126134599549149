'use client';

import { GalleryIcon } from '../Icons/GalleryIcon';
import { LightboxOpener } from '../LightboxOpener/LightboxOpener';
import { LightboxProps } from '../Lightbox/Lightbox';
import styles from './CoverImage.module.scss';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { useTranslation } from 'react-i18next';

type ViewGalleryProps = {
  images: LightboxProps['images'];
};

export const ViewGallery: React.FC<ViewGalleryProps> = ({ images }) => {
  const isPageOwner = useIsPageOwner();
  const { t } = useTranslation();

  return (
    <LightboxOpener
      images={images}
      label={isPageOwner ? undefined : t('gallery.view') ?? undefined}
    >
      <div className={styles.actionButton}>
        {isPageOwner ? t('gallery.view') : <GalleryIcon />}
      </div>
    </LightboxOpener>
  );
};
