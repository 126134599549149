'use client';

import { PropsWithChildren, useEffect, useState } from 'react';

import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { usePage } from '../Providers/PageProvider';
import { optimizelyClient } from '../Providers/ExperimentProvider';
import { ActivateListenerPayload, enums } from '@optimizely/react-sdk';

const MAX_CHECKS = 50;

export const HeapEventEnricher = ({ children }: PropsWithChildren) => {
  const [heapLoaded, setHeapLoaded] = useState<boolean>(
    typeof window !== 'undefined' && !!window.heap?.loaded,
  );
  const isPageOwner = useIsPageOwner();
  const { status } = usePage();

  useEffect(() => {
    // The heap script is loaded lazily so we can't guarantee it will be loaded when this component is rendered
    let checkCount = 0;

    const intervalId = setInterval(() => {
      if (checkCount > MAX_CHECKS) {
        cleanup();
      }

      if (window.heap?.loaded) {
        setHeapLoaded(true);
        cleanup();
      }

      checkCount++;
    }, 100);

    function cleanup() {
      clearInterval(intervalId);
    }

    return cleanup;
  }, []);

  useEffect(() => {
    if (!heapLoaded) {
      return;
    }
    const isPageExpired = status === 'COMPLETED';

    window.heap?.clearEventProperties();
    window.heap?.addEventProperties({
      isPageOwner,
      'pageExpired': isPageExpired,
    });

    optimizelyClient?.notificationCenter.addNotificationListener<
      ActivateListenerPayload
    >(enums.NOTIFICATION_TYPES.ACTIVATE, payload => {
      const experimentKey = `experiment_${payload.experiment.key}`;
      window.heap?.addUserProperties({
        [experimentKey]: payload.variation.key,
      });
    });

  }, [isPageOwner, status, heapLoaded]);

  return <>{children}</>;
};
