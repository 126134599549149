import React, { FunctionComponent } from 'react';

export const PrintIcon: FunctionComponent = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3ZM8 5h8v3H8V5Zm8 14H8v-4h8v4Zm2-4v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2Z"
        fill="#fff"
      />
      <path d="M18 12.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#fff" />
    </svg>
  );
};
