import { useCarouselContext } from '../Carousel/Carousel';
import { ReactNode } from 'react';
import styles from './CarouselButton.module.scss';
import classNames from 'classnames';

export enum CarouselDirection {
  Next,
  Previous,
}

export type ButtonProps = {
  icon: ReactNode;
  className: string;
  ariaLabel: string;
  direction: CarouselDirection;
};

export function CarouselButton({
  ariaLabel,
  className,
  icon,
  direction,
}: ButtonProps) {
  const { activeTile, selectTile, loopAround, items } = useCarouselContext();
  const hide =
    direction === CarouselDirection.Previous
      ? (!loopAround && activeTile === 0) || items.length === 1
      : (!loopAround && items.length - 1 === activeTile) || items.length === 1;
  const onClick =
    direction === CarouselDirection.Previous
      ? () => selectTile(activeTile - 1)
      : () => selectTile(activeTile + 1);
  return (
    <button
      aria-label={ariaLabel}
      className={classNames(className, styles.carouselButton)}
      onClick={onClick}
      style={{
        visibility: hide ? 'hidden' : undefined,
      }}
    >
      {icon}
    </button>
  );
}
