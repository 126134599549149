'use client';

import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import {
  SupporterDetailsContext,
  useSupporterDetailsContext,
} from './SupporterDetailsContext';
import { Trans, useTranslation } from 'react-i18next';

import CpAvatar from '@justgiving/carepack-avatar';
import { DefaultAvatar } from '../DefaultAvatar/DefaultAvatar';
import DefaultTheme from '../Totaliser/themes/DefaultTheme/DefaultTheme';
import { Stack } from '../Stack/Stack';
import { Timestamp } from '../Timestamp/Timestamp';
import { Totaliser } from '../Totaliser/Totaliser';
import { childComponentSelector } from '../../utils/childComponentSelector';
import classNames from 'classnames';
import clientConfig from '../../client/config';
import { formatCurrency } from '../../utils/formatCurrency';
import { i18nInstance } from '../../i18n/client';
import styles from './SupporterDetails.module.scss';

export type SupporterDetailsProps = {
  image?: string;
  date?: string;
  donationAmount?: number | null;
  target?: number | null;
  currencyCode?: string;
  giftAidAmount?: number;
  userName?: ReactNode | null;
  message?: ReactNode | null;
  groupSupporterCount?: number;
  slug?: string | null;
  isCancelled?: boolean;
};

export function SupporterDetails({
  children,
  ...rest
}: PropsWithChildren<SupporterDetailsProps>) {
  const avatar = childComponentSelector(children, Avatar);
  const title = childComponentSelector(children, Title);
  const date = childComponentSelector(children, Date);
  const message = childComponentSelector(children, Message);
  const donationAmounts = childComponentSelector(children, DonationAmounts);
  const groupSupporterCount = childComponentSelector(
    children,
    GroupSupporterCount,
  );
  const totals = childComponentSelector(children, Totals);

  return (
    <SupporterDetailsContext.Provider value={rest}>
      <li className={styles.container} data-testid="supporter">
        {avatar && (
          <div className={styles.avatar} data-testid="avatar">
            {avatar}
          </div>
        )}
        <Stack
          className={styles.content}
          direction="column"
          alignItems="flex-start"
          gap="sizeSpacing04"
        >
          {(title || date) && (
            <header className={styles.header}>
              {title}
              {date}
            </header>
          )}
          {message}
          {donationAmounts}
          {groupSupporterCount}
        </Stack>
        {totals && <div className={styles.totals}>{totals}</div>}
      </li>
    </SupporterDetailsContext.Provider>
  );
}

type DonationAmountsProps = {
  displayDonationWhenZero?: boolean;
};

const DonationAmounts: FunctionComponent<DonationAmountsProps> = ({
  displayDonationWhenZero,
}) => {
  const { t } = useTranslation();
  const { donationAmount, currencyCode, giftAidAmount, isCancelled } =
    useSupporterDetailsContext();

  if (
    donationAmount == null ||
    (donationAmount <= 0 && !displayDonationWhenZero) ||
    !currencyCode
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.amount,
        {
          [styles.cancelled]: isCancelled,
        },
        'branded-text',
      )}
      data-testid="donation-amounts"
    >
      {formatCurrency(donationAmount, currencyCode, i18nInstance.language)}
      {typeof giftAidAmount === 'number' && giftAidAmount > 0 && (
        <span className={styles.giftAidAmount}>
          {` + `}
          {formatCurrency(
            giftAidAmount,
            currencyCode,
            i18nInstance.language,
          )}{' '}
          {t('giftAid')}
        </span>
      )}
    </div>
  );
};

const Avatar: FunctionComponent = () => {
  const { image, userName } = useSupporterDetailsContext();

  // Legacy in-mem pages use a default avatar which we don't want to display
  // https://www.justgiving.com/content/images/graphics/icons/avatars/facebook-avatar.gif
  if (!image || image.includes('facebook-avatar.gif')) {
    return (
      <DefaultAvatar size="small">
        {typeof userName === 'string' ? userName[0] : 'JG'}
      </DefaultAvatar>
    );
  }

  return <CpAvatar src={image} alt="" size="small" />;
};

const Title: FunctionComponent = () => {
  const { t } = useTranslation();
  const { userName, slug, isCancelled } = useSupporterDetailsContext();

  const title = (
    <h2
      className={classNames(
        styles.donorName,
        {
          [styles.cancelled]: isCancelled,
        },
        'cp-heading-extra-small',
        'm-0',
      )}
      data-testid="donor-name"
    >
      {userName || t('common.anonymous')}
    </h2>
  );

  if (slug && !isCancelled) {
    return (
      <a
        className="cp-text-link-dark"
        href={`${clientConfig.justGivingRootUrl}/${slug}`}
      >
        {title}
      </a>
    );
  }

  return <>{title}</>;
};

const Date: FunctionComponent = () => {
  const { date } = useSupporterDetailsContext();

  if (!date) return null;

  return (
    <span
      className={classNames(
        styles.date,
        'cp-body-small',
        'text-color-grey-300',
      )}
    >
      <Timestamp date={date} />
    </span>
  );
};

const Message: FunctionComponent = () => {
  const { message } = useSupporterDetailsContext();

  if (!message) return null;

  return <span className={styles.donationMessage}>{message}</span>;
};

const GroupSupporterCount: FunctionComponent = () => {
  const { t } = useTranslation();
  const { groupSupporterCount, isCancelled } = useSupporterDetailsContext();

  if (groupSupporterCount == null) return null;

  if (isCancelled) {
    return (
      <span className={styles.cancelled}>{t('supporters.cancelled')}</span>
    );
  }

  return (
    <span>
      <Trans
        i18nKey="supporters.raisedBy"
        defaults="raised by <bold>{{count}} supporter</bold>"
        values={{ count: groupSupporterCount }}
        components={{ bold: <span className="cp-font-bold" /> }}
      />
    </span>
  );
};

const Totals: FunctionComponent = () => {
  const { donationAmount, target, isCancelled } = useSupporterDetailsContext();

  if (donationAmount == null || !target || isCancelled) return null;

  const targetPercent = Math.round((donationAmount / target) * 100);

  return (
    <Totaliser
      targetPercent={targetPercent}
      isAnimated={false}
      theme={DefaultTheme}
    />
  );
};

SupporterDetails.Avatar = Avatar;
SupporterDetails.Title = Title;
SupporterDetails.Date = Date;
SupporterDetails.Message = Message;
SupporterDetails.DonationAmounts = DonationAmounts;
SupporterDetails.GroupSupporterCount = GroupSupporterCount;
SupporterDetails.Totals = Totals;
