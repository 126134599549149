'use client';

import { Modal } from '../../Modal/Modal';
import { Stack } from '../../Stack/Stack';
import styles from './EventReminderModal.module.scss';
import { EventParentPage } from '../../../utils/relationships';
import { AddToCalendarButton } from '../../AddToCalendarButton/AddToCalendarButton';
import { CalendarType } from '../../AddToCalendarButton/helpers/CalendarType';
import { useTranslation } from 'react-i18next';
import { usePage } from '../../Providers/PageProvider';

type EventReminderModalProps = {
  event: EventParentPage;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EventReminderModal = ({
  open,
  setOpen,
  event,
}: EventReminderModalProps) => {
  const page = usePage();
  const { t } = useTranslation();
  const owner = page.owner;
  const ownerType = page.product?.ownerType;
  const ownerName =
    ownerType !== 'ORGANISATION' && owner?.name ? owner.name : undefined;
  const charityName = page.charity.name;
  const eventName = event?.title;
  const rawStartDate = event?.launchDate;
  const rawEndDate = event?.endDate;
  const currentUrl = page.url;

  if (!charityName || !eventName || !rawStartDate) {
    return null;
  }

  const startDate = new Date(rawStartDate);
  const endDate = rawEndDate ? new Date(rawEndDate) : undefined;

  return (
    <Modal open={open} onClose={() => setOpen(false)} className={styles.modal}>
      <Modal.CloseButton />
      <Modal.Content>
        <Modal.Body className={styles.body}>
          <div className={styles.container} data-testid="event-reminder-modal">
            <div>
              <h1 className="cp-heading-medium">
                {t('eventReminder.heading')}
              </h1>
              <p className="cp-body-medium">
                {t('eventReminder.text', {
                  ownerName: ownerName ?? charityName,
                })}
              </p>
            </div>
            <div>
              <Stack alignItems="center">
                <AddToCalendarButton
                  calendarType={CalendarType.Apple}
                  className={styles.appleIconBoost}
                  url={currentUrl}
                  startTime={startDate}
                  endTime={endDate}
                />
                <AddToCalendarButton
                  calendarType={CalendarType.Google}
                  url={currentUrl}
                  startTime={startDate}
                  endTime={endDate}
                />
                <AddToCalendarButton
                  calendarType={CalendarType.Outlook}
                  url={currentUrl}
                  startTime={startDate}
                  endTime={endDate}
                />
                <AddToCalendarButton
                  calendarType={CalendarType.OutlookWeb}
                  buttonText={t('eventReminder.outlookWeb') ?? undefined}
                  url={currentUrl}
                  startTime={startDate}
                  endTime={endDate}
                />
                <AddToCalendarButton
                  calendarType={CalendarType.Yahoo}
                  url={currentUrl}
                  startTime={startDate}
                  endTime={endDate}
                />
              </Stack>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
