export const Experiments = {
  TrackingEvents: {
    shareSocialButtonClick: 'shareSocialButtonClick',
    shareSocialButtonClickFacebook: 'shareSocialButtonClickFacebook',
    shareSocialButtonClickFacebook_Native:
      'shareSocialButtonClickFacebook_Native',
    shareSocialButtonClickInstagram: 'shareSocialButtonClickInstagram',
    shareSocialButtonClickWhatsapp: 'shareSocialButtonClickWhatsapp',
    shareSocialButtonClickCopylink: 'shareSocialButtonClickCopylink',
    shareSocialButtonClickPrint: 'shareSocialButtonClickPrint',
    giveNowClick: 'donateButtonClick',
    donateNowAverageDonation: 'donatebuttonclick_average_donation',
  },
  LastDonation: {
    flagKey: 'consumerdarkslide_2903571_last_donationv2',
  },
  ProgressBar: {
    flagKey: 'consumerdarkslide_2624472_totalisersus',
  },
  facebookSharing: {
    flagKey: 'consumerdarkslide_3026942_facebook_content_1',
  },
  DonationAverage: {
    flagKey: 'consumerdarkslide_2903589_average_donation',
  },
  FBOpenGraphSharing: {
    flagKey: 'consumerdarkslide_3110509__fb_open_graph_content',
  },
};
