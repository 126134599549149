'use client';

import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
  messageKey: string;
};

export const PageAlertMessage: React.FC<Props> = ({ messageKey }) => {
  switch (messageKey) {
    case 'donationError': {
      return (
        <Trans i18nKey="page.alert.donationError">
          Looks like we&apos;re running into some trouble. You can try donating
          again, or{' '}
          <a
            className="cp-text-link"
            href="https://help.justgiving.com/hc/en-us/requests/new?ticket_form_id=5342"
            target="_blank"
            rel="noopener noreferrer"
          >
            reach out to our Customer Support team for help
          </a>
          .
        </Trans>
      );
    }
    case 'donationErrorNotSupported': {
      return (
        <Trans i18nKey="page.alert.donationErrorNotSupported">
          We&apos;re sorry - we&apos;re not able to process your donation to
          this charity.{' '}
          <a
            className="cp-text-link"
            href="https://help.justgiving.com/hc/en-us/articles/360009270137-Why-can-t-I-donate-to-certain-charities-outside-of-the-EU-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find out why
          </a>
          .
        </Trans>
      );
    }
    default: {
      return <Trans i18nKey="page.errorGeneric.heading" />;
    }
  }
};
