import { ProductNames } from '../constants/ProductNames';

export function getSlugWithProductPrefix(
  slug: string,
  productName: string,
): string {
  return productName === ProductNames.ProxyFundraising &&
    !slug?.toLowerCase()?.startsWith('fundraising/')
    ? 'fundraising/' + slug
    : slug;
}
