'use client';

import { invertColor, isLightColor, shadeColor } from '../../utils/colors';

import { Page } from '../../lib/pageData';
import { PropsWithChildren } from 'react';
import { color } from '@justgiving/carepack-themes';

interface PageBrandingProps extends PropsWithChildren {
  branding: Page['branding'];
}

export const PageBranding = ({ children, branding }: PageBrandingProps) => {
  const primaryColor = branding?.primaryColor;
  const invertedPrimaryColor = primaryColor && invertColor(primaryColor, true);
  const isPrimaryColorLight = primaryColor ? isLightColor(primaryColor) : false;
  const primaryOrDark =
    primaryColor && isPrimaryColorLight ? invertedPrimaryColor : primaryColor;

  const backgroundImage = branding?.backgroundImage;
  const hasCustomBackground = Boolean(branding?.backgroundColor || branding?.backgroundImage);

  return (
    <>
      {children}
      {primaryColor && (
        <style jsx global>{`
          :root {
            /* Carepack vars override */
            --color-brand-base: ${primaryColor};
            --color-brand-darker: ${shadeColor(primaryColor)};
            --color-interaction-base: ${isPrimaryColorLight
              ? color.colorInteractionBase
              : primaryColor};
            --color-interaction-dark: ${isPrimaryColorLight
              ? color.colorInteractionDark
              : shadeColor(primaryColor)};

            /* Additional vars to support branding */
            --brand-container-width: ${hasCustomBackground
              ? '1360px'
              : 'unset'};
            --brand-color-text: ${primaryOrDark};
            --brand-color-neutral: ${isPrimaryColorLight
              ? primaryOrDark
              : 'unset'};
            --brand-background-image: ${backgroundImage
              ? `url('${backgroundImage}')`
              : 'unset'};
            --brand-color-background: ${Boolean(branding.backgroundColor) ? branding.backgroundColor : 'unset'};
          }
        `}</style>
      )}
    </>
  );
};
