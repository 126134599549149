import prepareHex from './prepareHex';

export const shadeColor = (hex: string): string => {
  const SHADE_PERCENTAGE = -0.2;

  const preparedHex = prepareHex(hex);
  if (!preparedHex) {
    return '';
  }
  const red: number = parseInt(preparedHex.slice(0, 2), 16);
  const green: number = parseInt(preparedHex.slice(2, 4), 16);
  const blue: number = parseInt(preparedHex.slice(4, 6), 16);

  const newRed = changeValueByPercentage(
    red,
    SHADE_PERCENTAGE,
    0,
    255,
  ).toString(16);
  const newGreen = changeValueByPercentage(
    green,
    SHADE_PERCENTAGE,
    0,
    255,
  ).toString(16);
  const newBlue = changeValueByPercentage(
    blue,
    SHADE_PERCENTAGE,
    0,
    255,
  ).toString(16);

  const newHex = '#'
    .concat(
      newRed.padStart(2, '0'),
      newGreen.padStart(2, '0'),
      newBlue.padStart(2, '0'),
    )
    .toUpperCase();

  return newHex;
};

function changeValueByPercentage(
  numberToChange: number,
  percentage: number,
  minValue: number,
  maxValue: number,
): number {
  return Math.round(
    Math.min(
      Math.max(numberToChange + numberToChange * percentage, minValue),
      maxValue,
    ),
  );
}

export default shadeColor;
