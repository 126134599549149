'use client';

import React, { FunctionComponent } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import confetti from '../../../public/confetti.svg';

import styles from './WeDidItHeader.module.scss';

import { usePage } from '../Providers/PageProvider';
import { formatCurrency } from '../../utils/formatCurrency';
import { Stack } from '../Stack/Stack';
import { ShareButton } from '../ShareButton/ShareButton';
import { TimerIcon } from '../Icons';
import { getDate, getDateWithoutHours } from '../../utils/date';

export const WeDidItHeader: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const page = usePage();

  if (!page?.endDate) {
    return null;
  }

  const isExpired =
    getDateWithoutHours(getDate()).getTime() >
    getDateWithoutHours(page.endDate).getTime();

  if (!isExpired) {
    return null;
  }

  const totalDonations = formatCurrency(
    Math.ceil(page.donationSummary.totalAmount),
    page.currencyCode,
    i18n.language,
    {
      maximumFractionDigits: 2,
    },
  );

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${confetti.src})` }}
    >
      <Stack
        className="w-full"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <h1 className="cp-heading-large m-0">{t('weDidIt.title')}</h1>
        <p className="m-0 cp-body-medium">
          <Trans
            i18nKey="weDidIt.complete"
            defaults="Our campaign is now complete. <bold>{{count}} supporters</bold> helped us raise <branded>{{donationCount}}</branded>"
            values={{
              count: page.donationSummary.aggregatedDonationsCount,
              donationCount: totalDonations,
            }}
            components={{
              bold: <span className="cp-font-bold" />,
              branded: <span className="branded-text" />,
            }}
          />
        </p>
        <ShareButton id="share-button-cta" variant="PRIMARY" />
        <a
          className="cp-btn cp-btn-secondary"
          href={page.charity.landingPageUrl}
        >
          {t('weDidIt.cta')}
        </a>
        <Stack
          className="m-1"
          direction="row"
          gap="sizeSpacing01"
          alignItems="center"
        >
          <div className={styles.icon}>
            <TimerIcon />
          </div>
          <span className="text-color-grey-300">
            {t('weDidIt.closed', {
              endDate: page.endDate.toString(),
            })}
          </span>
        </Stack>
      </Stack>
    </div>
  );
};
