'use client';

import EditIcon from '../../../public/edit-icon.svg';
import Image from 'next/image';
import { Modal } from '../Modal/Modal';
import { Stack } from '../Stack/Stack';
import classNames from 'classnames';
import clientConfig from '../../client/config';
import styles from './StartFundraisingLink.module.scss';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { usePage } from '../Providers/PageProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';


export const StartFundraisingLink = () => {
  const { t } = useTranslation();
  const { event, charity, campaign } = usePage();
  const isPageOwner = useIsPageOwner();
  const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>();

  if (isPageOwner || !event || (event.endDate && event.endDate < new Date())) {
    return null;
  }

  if (event.startFundraisingUrl) {
    return (
      <>
        <ErrorBoundary>
          <button
            className="cp-text-link btn-unstyled"
            onClick={() => setRedirectModalOpen(true)}
          >
            {t('event.startFundraising')}
          </button>
        
        <Modal
          onClose={() => setRedirectModalOpen(false)}
          open={redirectModalOpen}
        >
          <Modal.CloseButton />
          <Modal.Content>
            <Modal.Body
              applyMaxWidth
              className={classNames(styles.modalBody, 'text-center my-6')}
            >
              <Image
                src={EditIcon}
                alt=""
                className={classNames(styles.icon, 'mb-7')}
                width={144}
                height={144}
              />
              <h1 className="cp-heading-small mb-4 mt-0">
                {t('event.redirectHeading', { eventName: event.name })}
              </h1>
              <p className="text-light m-0 cp-body-medium">
                {t('event.redirectDescription')}
              </p>
            </Modal.Body>
            <Modal.Footer applyMaxWidth>
              <Stack>
                <a
                  className="cp-btn cp-btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={event.startFundraisingUrl}
                >
                  <span>{t('event.redirectGoToLink')}&nbsp;</span>
                  <span className="cp-body-small">
                    {t('event.redirectGoToLinkNewTab')}
                  </span>
                </a>
                <button
                  className="cp-btn cp-btn-secondary w-auto"
                  onClick={() => setRedirectModalOpen(false)}
                >
                  {t('close')}
                </button>
              </Stack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        </ErrorBoundary>
      </>
    );
  }

  const href = new URL(
    `${clientConfig.justGivingRootUrl}/create-page?eventId=${event.externalId}&charityId=${charity.externalId}`,
  );

  if (campaign) {
    href.searchParams.set('campaignId', campaign.id);
  }

  return (
    <a className="cp-text-link" href={href.toString()}>
      {t('event.startFundraising')}
    </a>
  );
};
