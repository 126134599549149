'use client';

import { ReactNode } from 'react';
import { Page } from '../../lib/pageData';
import styles from './DonationPrompts.module.scss';
import { IconArrowRight, IconArrowLeft } from '@justgiving/carepack-icons';
import { DonationPromptTile } from '../DonationPromptTile/DonationPromptTile';
import { useTranslation } from 'react-i18next';
import {
  DonationPromptType,
  ValidDonationPrompt,
} from './helper/donationPromptHelper';
import { getFirstCharity } from '../../utils/relationships';
import { DonationPromptDots } from '../DonationPromptDots/DonationPromptDots';
import {
  CarouselButton,
  CarouselDirection,
} from '../CarouselButton/CarouselButton';
import { CarouselTrack } from '../CarouselTrack/CarouselTrack';
import { Carousel } from '../Carousel/Carousel';

export type DonationPromptsProps = {
  page: Page;
};

export default function DonationPrompts({ page }: DonationPromptsProps) {
  const { t } = useTranslation();
  const charity = getFirstCharity(page.relationships?.beneficiaries);

  if (!charity || !charity.donationPrompts) {
    return null;
  }

  const donationPrompts: ValidDonationPrompt[] = charity.donationPrompts
    ?.flatMap((prompt) => {
      if (!prompt.description || !prompt.amount || !prompt.type) {
        return [];
      }
      if (prompt.amount <= 0) {
        return [];
      }
      if (
        prompt.type !== DonationPromptType.Monthly &&
        prompt.type !== DonationPromptType.OneOff
      ) {
        return [];
      }
      return {
        description: prompt.description,
        amount: prompt.amount,
        type: prompt.type,
      };
    })
    .sort((a, b) => {
      return a.type === b.type
        ? a.amount - b.amount
        : a.type === DonationPromptType.OneOff
          ? -1
          : 1;
    });

  const currencyCode = page.targetWithCurrency?.currencyCode;

  if (!donationPrompts?.length) {
    return null;
  }

  const promptTiles: ReactNode[] = donationPrompts.map((prompt, index) => (
    <DonationPromptTile
      key={`donationPrompt-${index}`}
      amount={prompt.amount}
      description={prompt.description}
      type={prompt.type}
      charityId={charity.externalId}
      currency={currencyCode}
    />
  ));
  const loopAround = true;

  return (
    <div className={styles.box}>
      <h1 className={`cp-heading-extra-small ${styles.impactContainer}`}>
        {t('donationPrompt')}
      </h1>

      <Carousel
        loopAround={loopAround}
        items={promptTiles}
        className={styles.carousel}
      >
        <div className={styles.container}>
          <CarouselButton
            ariaLabel={t('previousPrompt') ?? 'Previous prompt'}
            className={`cp-btn-primary ${styles.arrow}`}
            icon={<IconArrowLeft className={styles.arrowSvg} />}
            direction={CarouselDirection.Previous}
          />
          <CarouselTrack />
          <CarouselButton
            ariaLabel={t('nextPrompt') ?? 'Next prompt'}
            className={`cp-btn-primary ${styles.arrow}`}
            icon={<IconArrowRight className={styles.arrowSvg} />}
            direction={CarouselDirection.Next}
          />
        </div>
        <DonationPromptDots numberOfTiles={promptTiles.length} />
      </Carousel>
    </div>
  );
}
