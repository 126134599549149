'use client';

import { GroupMembersList } from '../GroupMembersList/GroupMembersList';
import Link from 'next/link';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { Page } from '../../lib/pageData';
import clientConfig from '../../client/config';
import { isInMemoryOccasion } from '../../utils/inMemory';
import styles from './GroupMembers.module.scss';
import { useFetchGroupMembers } from '../../hooks/useFetchGroupMembers';
import { useTranslation } from 'react-i18next';

type GroupMembersProps = {
  page: Page;
  className?: string;
};

export const GroupMembers = ({ page, className }: GroupMembersProps) => {
  const { t } = useTranslation();

  const isInMemoryTeam = isInMemoryOccasion(page.occasion?.type);
  const { data, loading, handleFetchMore } = useFetchGroupMembers(page);

  const fundraisers = data?.page?.fundraisers;
  const members = data?.page?.relationships?.members;

  if (!fundraisers && !members) {
    return null;
  }

  return (
    <section data-testid="group-members-section" className={className}>
      <div className={styles.sectionHeader}>
        <h1 className="cp-heading-small m-0">
          {t(isInMemoryTeam ? 'groupMembers.inMemTitle' : 'groupMembers.title')}{' '}
          ({fundraisers?.totalCount ?? members?.totalCount})
        </h1>
        {page.membershipPolicy === 'open' && (
          <Link
            className="cp-text-link"
            href={`${clientConfig.justGivingRootUrl}/create-page/team/${page.legacyId}/join-team`}
          >
            {t(isInMemoryTeam ? 'joinTeamInMem' : 'joinTeam')}
          </Link>
        )}
      </div>
      <GroupMembersList
        page={{
          ...page,
          ...data.page,
          relationships: {
            ...page.relationships!,
            members: {
              nodes: [],
              totalCount: 0,
              pageInfo: {
                hasNextPage: false,
              },
              ...data.page?.relationships?.members,
            },
          },
        }}
      />
      {(fundraisers?.pageInfo.hasNextPage || members?.pageInfo.hasNextPage) && (
        <LoadingButton
          onClick={handleFetchMore}
          className="cp-btn cp-btn-secondary mt-2 w-full"
          loading={loading}
        >
          {t('showMore')}
        </LoadingButton>
      )}
    </section>
  );
};
