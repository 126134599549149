import { Children, ReactNode } from 'react';

import { DefaultAvatar } from '../DefaultAvatar/DefaultAvatar';
import React from 'react';
import classNames from 'classnames';
import { colors } from '@justgiving/carepack-design-tokens';
import styles from './AvatarGroup.module.scss';
import { AvatarProps } from '../Avatar/Avatar';

type AvatarGroupProps = {
  max: number;
  children: ReactNode;
  totalCount?: number;
  as?: React.ElementType;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  ariaLabel?: string | null;
  isDisabled?: boolean;
  avatarSize?: AvatarProps['size'];
};

export const AvatarGroup = ({
  children,
  max,
  totalCount,
  as = 'div',
  className,
  onClick,
  ariaLabel,
  isDisabled,
  avatarSize,
}: AvatarGroupProps) => {
  const Element = as;
  const childrenCount = totalCount ?? Children.count(children);
  const displayChildren = Children.toArray(children).slice(0, max);
  const remaining = childrenCount - max;

  return (
    <Element
      className={classNames(styles.avatarGroup, className)}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={isDisabled}
    >
      {displayChildren.map((c) =>
        React.isValidElement<AvatarProps>(c)
          ? React.cloneElement<AvatarProps>(c, {
              size: avatarSize,
              className: styles.avatarItem,
            })
          : null,
      )}
      {childrenCount > max ? (
        <DefaultAvatar
          fillColor={colors.colorNeutral600}
          className={styles.defaultAvatar}
          textColor={colors.colorGrey400}
          size={avatarSize}
        >
          +{remaining}
        </DefaultAvatar>
      ) : null}
    </Element>
  );
};
