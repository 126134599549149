import {
  ButtonHTMLAttributes,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LoadingIndicator,
  LoadingIndicatorProps,
} from '@justgiving/page-components';

type LoadingButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loaderColor?: LoadingIndicatorProps['color'];
};

export const LoadingButton: FunctionComponent<LoadingButtonProps> = ({
  loading,
  loaderColor,
  children,
  ...props
}) => {
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const timer = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (loading) {
      timer.current = window.setTimeout(() => {
        setLoadingFeedback(true);
      }, 250);
    } else {
      setLoadingFeedback(false);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [loading]);
  return (
    <button {...props}>
      {loading && loadingFeedback ? (
        <LoadingIndicator size="small" color={loaderColor} />
      ) : (
        children
      )}
    </button>
  );
};
