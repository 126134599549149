'use client';

import classNames from 'classnames';
import { FunctionComponent, useCallback, useState } from 'react';
import { useAnimationFrame } from '../../hooks/useAnimationFrame';
import { useFirstRender } from '../../hooks/useFirstRender';
import { usePrefersReducesMotion } from '../../hooks/usePrefersReducedMotion';
import { TotaliserProps, TotaliserThemeProps } from './props';
import styles from './Totaliser.module.scss';

export const Totaliser: FunctionComponent<TotaliserProps> = ({
  isAnimated,
  className,
  targetPercent,
  theme: Theme,
  reduceTextSize,
  outerColor,
  innerColor,
  textColor,
}) => {
  const fontSize = getFontSizeByDigits(targetPercent, reduceTextSize);

  const roundedTargetPercent = Math.floor(targetPercent);

  return (
    <div className={classNames(styles.totaliser, className)}>
      <div className={styles.svgContainer}>
        <svg
          className={styles.svg}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
        >
          {isAnimated && roundedTargetPercent > 0 ? (
            <AnimatedTheme
              targetPercent={targetPercent}
              theme={Theme}
              outerColor={outerColor}
              innerColor={innerColor}
              textColor={textColor}
              fontSize={fontSize}
            />
          ) : (
            <Theme
              currentPercent={roundedTargetPercent}
              currentPosition={getCurrentPosition(
                roundedTargetPercent,
                roundedTargetPercent,
              )}
              hidePercentage={false}
              isAnimated={false}
              outerColor={outerColor}
              innerColor={innerColor}
              textColor={textColor}
              fontSize={fontSize}
            />
          )}
        </svg>
      </div>
    </div>
  );
};

type AnimatedThemeProps = {
  targetPercent: number;
  theme: FunctionComponent<TotaliserThemeProps>;

  outerColor: string | undefined;
  innerColor: string | undefined;
  textColor: string | undefined;
  fontSize: string;
};

const AnimatedTheme: FunctionComponent<AnimatedThemeProps> = ({
  targetPercent,
  theme: Theme,
  outerColor,
  innerColor,
  textColor,
  fontSize,
}) => {
  // The first render happens server-side and we don't want to display the percentage label when the page is still loading,
  // as displaying 0% could be misleading if it's displayed for too long without any loading state before the rehydration
  // happens and the animation kicks off.
  const firstRender = useFirstRender();

  const reducedMotion = usePrefersReducesMotion() ?? false;

  // If reduced motion preference is enabled, setting the animation duration to 0 will
  // cause the totaliser to be updated with the final state the first time the animation
  // frame callback is invoked.
  const animationDuration = reducedMotion
    ? 0
    : getAnimationDuration(targetPercent);

  const [currentPercent, setCurrentPercent] = useState<number>(0);

  useAnimationFrame(
    useCallback(
      (elapsedTime: number) => {
        if (elapsedTime >= animationDuration) {
          setCurrentPercent(Math.floor(targetPercent));
          return true;
        }

        const tweenedPercentOfDuration = outExpo(
          elapsedTime / animationDuration,
        );
        const currentPercent = Math.floor(
          targetPercent * tweenedPercentOfDuration,
        );

        setCurrentPercent(currentPercent);

        return false;
      },
      [animationDuration, targetPercent],
    ),
  );

  return (
    <Theme
      currentPercent={currentPercent}
      currentPosition={getCurrentPosition(targetPercent, currentPercent)}
      isAnimated={!firstRender && !reducedMotion}
      hidePercentage={firstRender}
      outerColor={outerColor}
      innerColor={innerColor}
      textColor={textColor}
      fontSize={fontSize}
    />
  );
};

function getFontSizeByDigits(percent: number, textReduction?: number): string {
  const percentageDigits = String(Math.round(percent)).length;

  const fontSizeByDigits = [22, 22, 17, 14, 12, 10, 9];

  const reduction = textReduction || 0;
  return `${fontSizeByDigits[percentageDigits - (1 - reduction)] || 7.5}px`;
}

function getCurrentPosition(
  targetPercent: number,
  currentPercent: number,
): number {
  return targetPercent >= 100
    ? 100 - (100 / targetPercent) * currentPercent
    : 100 - currentPercent;
}

function getAnimationDuration(percent: number): number {
  if (percent === 0) {
    return 0;
  }

  const animationDuration = 4800;

  if (percent < 33) {
    return animationDuration / 3;
  }

  if (percent < 100) {
    return (percent / 100) * animationDuration;
  }

  return animationDuration;
}

function outExpo(n: number): number {
  return n === 1 ? n : 1 - 2 ** (-10 * n);
}
