import { Page } from '../../lib/pageData';
import { getFirstCharity } from '../../utils/relationships';
import { isInMemoryOccasion } from '../../utils/inMemory';

type GroupPageSummaryProps = {
  page: Page;
  t: (string: string) => string;
};

export const GroupPageSummary = ({ page, t }: GroupPageSummaryProps) => {
  const charities = page.relationships?.beneficiaries;
  const isInMemoryTeam = isInMemoryOccasion(page.occasion?.type);
  const firstCharity = getFirstCharity(page.relationships?.beneficiaries);

  return (
    <div
      data-testid="group-page-summary"
      className="cp-body-medium text-color-grey-400"
    >
      <span>
        {isInMemoryTeam ? t('pageSummary.inMemType') : t('pageSummary.type')}
      </span>
      <span> • </span>
      <span>
        {page.fundraisers?.totalCount ??
          page.relationships?.members?.totalCount}{' '}
        {t('pageSummary.members')}
      </span>
      <span> • </span>
      <span>
        {charities?.nodes.length === 1
          ? firstCharity?.displayName
          : `${charities?.nodes.length} ${t('pageSummary.charities')}`}
      </span>
    </div>
  );
};
