import React, { FunctionComponent } from 'react';

export const WheelchairIcon: FunctionComponent = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 6.54a2 2 0 100-4 2 2 0 000 4zM14 17h-2a3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3v-2a5 5 0 105 5zm3-3.5h-1.86l1.67-3.67A2 2 0 0014.96 7h-5.2c-.81 0-1.54.47-1.87 1.2L7.22 10l1.92.53L9.79 9H12l-1.83 4.1c-.6 1.33.39 2.9 1.85 2.9H17v5h2v-5.5a2 2 0 00-2-2z"
        fill="#999"
      />
    </svg>
  );
};
