import { Children, ComponentType, ReactNode } from 'react';

export function childComponentSelector(
  children: ReactNode,
  matchingComponent: ComponentType<any>,
) {
  const component = Children.map(children, (child) => child)?.find(
    (child) => (child as any).type === matchingComponent,
  );

  if (!component) return null;

  return component;
}
