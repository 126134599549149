import { captureException } from '@sentry/nextjs';
import getDonateUrlWithJwt from "../client/api/linkService";
import clientConfig from "../client/config";

const getDonateUrl = async (jwt: string, backupUrl: string): Promise<string> => {
    const useStatelessCheckout = clientConfig.featureFlags.statelessCheckoutEnabled;

    if (!useStatelessCheckout) {
        return backupUrl;
    }

    try {
        const { redirectUrl } = await getDonateUrlWithJwt(jwt);
        return redirectUrl;

    } catch (e) {
        captureException(e);
        return backupUrl
    }
}

export default getDonateUrl;