'use client';

import React, { useId } from 'react';
import { getFirstCharity, isCharity } from '../../utils/relationships';

import { Avatar } from '../Avatar/Avatar';
import { FundraiserCharityFragment } from '../../lib/graphql';
import { IconClose } from '@justgiving/carepack-icons';
import Link from 'next/link';
import { Page } from '../../lib/pageData';
import { Progress } from '@justgiving/carepack-progress';
import { Stack } from '../Stack/Stack';
import { Tooltip } from '@justgiving/carepack-tooltip';
import classNames from 'classnames';
import { formatCurrency } from '../../utils/formatCurrency';
import { isInMemoryOccasion } from '../../utils/inMemory';
import styles from './GroupMembersList.module.scss';
import { useBuildDonateUrlWithQueryString } from '../../hooks/useBuildDonateUrlWithQueryString';
import { useTranslation } from 'react-i18next';

type GroupMembersListProps = {
  page: Page;
  className?: string;
};

export const GroupMembersList = ({
  page,
  className,
}: GroupMembersListProps) => {
  const { t, i18n } = useTranslation();
  const members = page?.fundraisers ?? {
    nodes: page.relationships?.members?.nodes ?? [],
    totalCount: 0,
  };
  const teamCharity = getFirstCharity(page.relationships?.beneficiaries);
  const isInMemoryTeam = isInMemoryOccasion(page.occasion?.type);
  const memberCharities: FundraiserCharityFragment[] = [];
  const id = useId();
  const { buildDonateUrlWithQueryString } = useBuildDonateUrlWithQueryString()

  members?.nodes
    .flatMap((x) => x.relationships?.beneficiaries.nodes)
    .forEach((x) => {
      if (!x || !isCharity(x)) {
        return;
      }

      memberCharities.push(x);
    });

  const sameCharity = memberCharities.every(
    (x) => x.legacyId === teamCharity?.legacyId,
  );

  if (!members) {
    return null;
  }

  return (
    <Stack
      as="ul"
      gap="sizeSpacing05"
      className={classNames('list-unstyled', className)}
    >
      {members.nodes.map((x) => (
        <li key={x.legacyId} className={styles.memberListItem}>
          <div id={id}>
            <Stack
              direction="row"
              alignItems="center"
              gap="sizeSpacing04"
              className="mb-5"
            >
              {x.status === 'CANCELLED' ? (
                <Avatar src={x.owner?.avatar} variant="INDIVIDUAL" />
              ) : (
                <Link href={x.url}>
                  <Avatar src={x.owner?.avatar} variant="INDIVIDUAL" />
                </Link>
              )}
              <div>
                <Stack
                  className="cp-heading-extra-small mb-1"
                  direction="row"
                  gap="sizeSpacing02"
                  alignItems="center"
                >
                  {x.status === 'CANCELLED' ? (
                    <div className="cp-text-dark">{x.owner?.name}</div>
                  ) : (
                    <Link href={x.url} className="cp-text-link-dark">
                      {x.owner?.name}
                    </Link>
                  )}
                  {x.owner?.legacyId === page.owner?.legacyId && (
                    <Tooltip>
                      <Tooltip.Trigger
                        className={classNames(
                          styles.captainBadge,
                          'cp-body-small',
                          'btn-unstyled',
                        )}
                      >
                        C
                      </Tooltip.Trigger>
                      <Tooltip.Content portalNodeId={id}>
                        <Stack direction="row" alignItems="center">
                          <span className="cp-body-medium text-color-grey-400">
                            {t(
                              isInMemoryTeam
                                ? 'groupMembers.captainInMem'
                                : 'groupMembers.captain',
                              {
                                memberName: x.owner?.name?.split(' ')[0],
                              },
                            )}
                          </span>
                          <Tooltip.CloseTrigger
                            className={classNames(
                              'cp-btn',
                              'cp-btn-ghost-small',
                              styles.closeButton,
                            )}
                          >
                            <IconClose />
                          </Tooltip.CloseTrigger>
                        </Stack>
                      </Tooltip.Content>
                    </Tooltip>
                  )}
                </Stack>
                {!sameCharity && (
                  <div className="cp-body-small text-color-grey-300">
                    {t('groupMembers.fundraisingFor', {
                      causeName: getFirstCharity(x.relationships?.beneficiaries)
                        ?.displayName,
                    })}
                  </div>
                )}
              </div>
            </Stack>
            {x.targetWithCurrency?.value ? (
              <Progress
                max={x.targetWithCurrency?.value / 100}
                value={(x.donationSummary?.totalAmount?.value ?? 0) / 100}
                className={styles.progress}
              />
            ) : (
              <div className="text-color-grey-300">
                {t('groupMembers.noTarget', {
                  memberName: x.owner?.name?.split(' ')[0],
                })}
              </div>
            )}
            <div className="cp-body-medium mb-4">
              {formatCurrency(
                (x.donationSummary?.totalAmount?.value ?? 0) / 100,
                x.donationSummary?.totalAmount?.currencyCode ?? 'GBP',
                i18n.language,
                {
                  maximumFractionDigits: 0,
                },
              )}{' '}
              {x.targetWithCurrency?.value ? (
                <span className="cp-body-small text-color-grey-300">
                  {t('of')}{' '}
                  {formatCurrency(
                    x.targetWithCurrency.value / 100,
                    x.targetWithCurrency.currencyCode,
                    i18n.language,
                    {
                      maximumFractionDigits: 0,
                    },
                  )}
                </span>
              ) : null}
            </div>
          </div>
          {x.status === 'ACTIVE' && (
            <div className={styles.donateLinkWrapper}>
              <Link
                className="cp-text-link"
                href={buildDonateUrlWithQueryString(false, x.url)}
              >
                {t('groupMembers.donateToMember', {
                  memberName:
                    x.owner?.name?.split(' ')[0] ?? t('groupMembers.member'),
                })}
              </Link>
            </div>
          )}
        </li>
      ))}
    </Stack>
  );
};
