import { Page } from '../lib/pageData';

import { Page as PageProvider } from '../components/Providers/PageProvider';

interface WhenOwnerTypeOptions<T> {
  isIndividual?: (page: Page | PageProvider) => T;
  isOrganisation?: (page: Page | PageProvider) => T;
  isPeerGroup?: (page: Page | PageProvider) => T;
}

export function whenOwnerType<T>(
  page: Page | PageProvider,
  { isIndividual, isOrganisation, isPeerGroup }: WhenOwnerTypeOptions<T>,
) {
  const ownerType = page.product?.ownerType;

  switch (ownerType) {
    case 'INDIVIDUAL':
      return isIndividual?.(page);
    case 'ORGANISATION':
      return isOrganisation?.(page);
    case 'PEER_GROUP':
      return isPeerGroup?.(page);
    default:
      throw new Error(
        `Could not determine owner type; given owner: ${ownerType} is not valid; must be one of "INDIVIDUAL", "ORGANISATION", "PEER_GROUP"`,
      );
  }
}
