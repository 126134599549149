import clientConfig from '../client/config';

export function makePhotoUrl(photo: string, template?: string): string {
  return isUrlAbsolute(photo)
    ? makePhotoUrlFromAbsoluteUrl(photo, template)
    : makePhotoUrlFromRelativeUrl(photo, template);
}

export function isUrlAbsolute(url: string) {
  return url.indexOf('://') > 0 || url.indexOf('//') === 0;
}

export const makePhotoUrlFromAbsoluteUrl = (
  photo: string,
  template?: string,
) => {
  return `${photo}${template ? `?template=${template}` : ''}`;
};

export const makePhotoUrlFromRelativeUrl = (
  photo: string,
  template?: string,
) => {
  return `${clientConfig.imageServiceUrl}${photo}${
    template ? `?template=${template}` : ''
  }`;
};
