'use client';
import { usePathname } from 'next/navigation';

import { LoadingIndicator } from '@justgiving/page-components';
import { Modal } from '../Modal/Modal';
import { SocialButton } from '../SocialButton/SocialButton';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const QrCodeModalContent = dynamic(
  async () => (await import('./QrCodeModalContent')).QrCodeModalContent,
  {
    loading: () => <LoadingIndicator className="m-5" />,
  },
);

interface QrCodeButtonProps {
  isOpen?: boolean;
}

export const QrCodeButton : React.FC<QrCodeButtonProps> = ({isOpen}) => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const [isModalOpen, setIsModalOpen] = useState(isOpen ?? false);

  function dismissModal() {
    window.history.replaceState({}, '', `${pathname.replace('/qrcode', '')}`);
    setIsModalOpen(false);
  }

  return (
    <>
      <SocialButton type="qrCode" onClick={() => setIsModalOpen(true)}>
        {t('sharePage.qrCode')}
      </SocialButton>
      <Modal onClose={dismissModal} open={isModalOpen}>
        <Modal.Content>
          <Modal.CloseButton />
          <QrCodeModalContent />
        </Modal.Content>
      </Modal>
    </>
  );
};
