import { AcceptedCurrencyCode } from '../lib/graphql';

export const formatCurrencySymbol = (
  currencyCode: AcceptedCurrencyCode,
): string => {
  switch (currencyCode) {
    case 'GBP':
      return '£';
    case 'AUD':
    case 'CAD':
    case 'USD':
    case 'NZD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '£';
  }
};

export const getCurrencyFlag = (currencyCode: AcceptedCurrencyCode): string => {
  switch (currencyCode) {
    case 'GBP':
      return '🇬🇧';
    case 'AUD':
      return '🇦🇺';
    case 'CAD':
      return '🇨🇦';
    case 'USD':
      return '🇺🇸';
    case 'NZD':
      return '🇳🇿';
    case 'EUR':
      return '🇪🇺';
    default:
      return '';
  }
};