import {
  GetGroupMembersQuery,
  GetGroupMembersQueryVariables,
} from '../lib/graphql/types';
import {
  fundraiserFragment,
  fundraisersFragment,
  membersFragment,
} from '../lib/graphql/gqlPageDataQuery';

import { Page } from '../lib/pageData';
import { useQuery } from './useQuery';

const getGroupMembersQuery = /* GraphQL */ `
  ${fundraiserFragment}
  ${fundraisersFragment}
  ${membersFragment}

  query GetGroupMembers(
    $pageType: PageType
    $slug: Slug!
    $after: String
    $includeMembers: Boolean!
  ) {
    page(slug: $slug, type: $pageType) {
      fundraisers: leaderboard(type: FUNDRAISERS, first: 5, after: $after)
        @skip(if: $includeMembers) {
        ...Fundraisers
      }
      relationships @include(if: $includeMembers) {
        members(first: 5, after: $after) {
          ...Members
        }
      }
    }
  }
`;

export const useFetchGroupMembers = (page: Page) => {
  const { fetchMore, data, loading } = useQuery<
    GetGroupMembersQuery,
    GetGroupMembersQueryVariables
  >(getGroupMembersQuery, {
    initialData: {
      page: {
        ...page,
        relationships: {
          ...page.relationships,
          members: page.relationships?.members ?? {
            nodes: [],
            totalCount: 0,
            pageInfo: {
              hasNextPage: false,
            },
          },
        },
      },
      __typename: 'Query',
    },
  });

  const fundraisers = data?.page?.fundraisers;
  const members = page.relationships?.members;

  function handleFetchMore() {
    fetchMore(
      {
        variables: {
          after: fundraisers?.pageInfo.endCursor ?? members?.pageInfo.endCursor,
          slug: page.slug!,
          includeMembers: page.type === 'IN_MEMORY',
          pageType: page.type === 'IN_MEMORY' ? 'IN_MEMORY' : undefined,
        },
      },
      (existing, newData) => {
        if (!existing) {
          return newData;
        }

        const existingMembers = existing.page?.fundraisers?.nodes ?? [];
        const newFundraisers = newData.page?.fundraisers;
        const newMembers = newFundraisers?.nodes ?? [];
        return {
          ...newData,
          page: {
            ...newData.page,
            fundraisers:
              page.type !== 'IN_MEMORY'
                ? {
                    ...newFundraisers,
                    pageInfo: {
                      ...newFundraisers?.pageInfo,
                      hasNextPage:
                        newFundraisers?.pageInfo.hasNextPage ?? false,
                    },
                    nodes: [...existingMembers, ...newMembers],
                    totalCount: newFundraisers?.totalCount ?? 0,
                  }
                : undefined,
            relationships: {
              ...newData.page?.relationships,
              members: {
                ...newData.page?.relationships?.members,
                nodes: [
                  ...(existing.page?.relationships?.members?.nodes ?? []),
                  ...(newData.page?.relationships?.members?.nodes ?? []),
                ],
                pageInfo: {
                  ...newData.page?.relationships?.members?.pageInfo,
                  hasNextPage:
                    newData.page?.relationships?.members?.pageInfo
                      .hasNextPage ?? false,
                  endCursor:
                    newData.page?.relationships?.members?.pageInfo.endCursor,
                },
                totalCount:
                  newData.page?.relationships?.members?.totalCount ?? 0,
              },
            },
          },
        };
      },
    );
  }
  return { data, loading, handleFetchMore };
};
