export const isFundraisingPage = (name: string | undefined) => {
  return name === 'proxy_fundraising' || name === 'fundraising';
};

export const isCampaignPage = (name: string | undefined) => {
  return name === 'campaign';
};

export const isTeamsPage = (name: string | undefined) => {
  return name?.includes('team');
};

export const isCharityPage = (name: string | undefined) => {
  return name === 'proxy_charity';
};

export const isCrowdfundingPage = (name: string | undefined) => {
  return name === 'crowdfunding';
};
