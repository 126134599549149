export const LinkTreeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="50px"
    viewBox="150 30 400 490"
    preserveAspectRatio="xMidYMid"
  >
    <g fill="#43e660" fillRule="evenodd">
      <path d="M329.5 313.9h53.6v120.5h-53.6V313.9" />
      <path d="M205.6 190.5h91.1l-64.9-61.4 35.7-36.4 61.7 63.1V66h53.6v89.8l61.7-63.1 35.7 36.4-64.9 61.4h91.1v50.8h-91.7l65.2 63.1-35.7 35.6-88.5-88.7-88.5 88.7-35.7-35.6 65.2-63.1H205v-50.8h.6" />
    </g>
  </svg>
);
