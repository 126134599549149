'use client';

import { CTA_SECTION_ID } from '../../constants/ElementIds';
import { PropsWithChildren } from 'react';
import { Stack } from '../Stack/Stack';
import styles from './StickyBar.module.scss';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { usePreview } from '../Providers/PreviewProvider';

interface StickyBarProps extends PropsWithChildren {}

export const StickyBar = ({ children }: StickyBarProps) => {
  const intersection = useIntersectionObserver({
    target: {
      current:
        typeof document !== 'undefined'
          ? document.getElementById(CTA_SECTION_ID) // Using ID instead of ref to avoid converting the CTA section to a client component
          : null,
    },
  });
  const { isPreview } = usePreview();

  // Hidden when in preview mode as the edit bar is made sticky instead
  if (!intersection || intersection?.isIntersecting || isPreview) {
    return null;
  }

  return (
    <section data-testid="sticky-bar" className={styles.stickySummary}>
      {/* Client components cannot import server components (for example, the DonateButton component).
      Therefore they have to be passed as children */}
      <div className={styles.innerWrapper}>{children}</div>
    </section>
  );
};

export const StickyBarPageSummary: React.FC<PropsWithChildren> = ({
  children,
}) => <div className={styles.pageSummary}>{children}</div>;

export const StickyBarCallsToAction: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Stack className={styles.callsToAction} direction="row">
    {children}
  </Stack>
);
