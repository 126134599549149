import { FunctionComponent } from 'react';
import { TotaliserThemeProps } from '../../props';
import classNames from 'classnames';
import classnames from 'classnames';
import { isLightColor } from '../../../../utils/colors';
import styles from '../../Totaliser.module.scss';
import { usePage } from '../../../Providers/PageProvider';

const DefaultTheme: FunctionComponent<TotaliserThemeProps> = ({
  outerColor,
  innerColor,
  textColor,
  currentPosition,
  fontSize,
  currentPercent,
  hidePercentage,
  isAnimated,
}) => {
  const { branding } = usePage();
  const adjustmentForBorder = 7.5;

  return (
    <>
      <circle
        style={{ stroke: outerColor, fill: innerColor }}
        strokeWidth="5"
        cx="52.5"
        cy="52.5"
        r="45"
        className={classNames(styles.background, {
          [styles.light]: branding.primaryColor
            ? isLightColor(branding.primaryColor)
            : false,
        })}
      />
      <svg viewBox="0 0 100 100" width="100" height="100" x="2.5" y="2.5">
        <g>
          <defs>
            <mask id="waveMask" x="0" y="0" width="100" height="100">
              <circle cx="50" cy="50" r="40" fill="white" />
            </mask>
          </defs>
          <g mask="url(#waveMask)">
            <svg
              viewBox="0 0 100 100"
              width="100"
              height="100"
              x="0"
              y={currentPosition + adjustmentForBorder}
            >
              <path
                id="waveShape"
                style={{ fill: outerColor }}
                className={classnames(styles.wave, {
                  [styles.waveAnimated]: isAnimated,
                })}
                d="M300,200 L300,0 C252.793296,1.1589526e-13 224.972067,1.77243457e-13 216.536313,1.8404459e-13 C203.882682,1.9414359e-13 182.513966,1.6 182.513966,1.6 C172.206704,2.33333333 161.731844,2.86666667 155.782123,2.86666667 C149.832402,2.86666667 139.273743,2.33333333 128.96648,1.6 C128.96648,1.6 107.346369,0.0666666667 94.7765363,1.8404459e-13 C82.2067039,1.8404459e-13 60.5865922,1.6 60.5865922,1.6 C50.2793296,2.33333333 40.726257,2.86666667 33.7709497,2.86666667 C26.8156425,2.86666667 17.0111732,2.33333333 6.7877095,1.6 C6.7877095,1.6 4.18994413,1.4 0,1.13333333 L0,200 L300,200 Z"
              />
            </svg>
          </g>
        </g>
        {!hidePercentage && (
          <g style={{ fontSize }}>
            <text
              style={{ fill: textColor }}
              className={styles.percentage}
              textAnchor="middle"
              x="50"
              y="50"
            >
              <tspan className={styles.percentageNumber} dy="0.3em">
                {currentPercent}
              </tspan>
              <tspan dx="3">%</tspan>
            </text>
          </g>
        )}
      </svg>
    </>
  );
};

export default DefaultTheme;
