export enum DonationPromptType {
  Monthly = 'Monthly',
  OneOff = 'OneOff',
}

export type ValidDonationPrompt = {
  amount: number;
  description: string;
  type: DonationPromptType;
};
