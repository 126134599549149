import { Page } from '../lib/pageData/types';

type Beneficiaries = {
  __typename?: 'BeneficiaryConnection';
  nodes: ({ __typename?: 'Charity' } | { __typename?: 'User' })[];
};

type BeneficiaryCharity<T extends Beneficiaries> = Extract<
  T['nodes'][number],
  { __typename: 'Charity' }
>;

export type TeamParentPage = NonNullable<
  NonNullable<Page['relationships']>['teams']['nodes'][number]['page']
>;

export type CampaignParentPage = NonNullable<
  NonNullable<Page['relationships']>['campaigns']['nodes'][number]['page']
>;

export type EventParentPage = NonNullable<
  NonNullable<Page['relationships']>['events']['nodes'][number]['page']
>;

export function isCharity<T>(value: {
  __typename?: string;
}): value is T & { __typename: 'Charity' } {
  return value.__typename === 'Charity';
}

export function getCharities<T extends Beneficiaries>(
  beneficiaries?: T,
): BeneficiaryCharity<T>[] {
  if (!beneficiaries) {
    return [];
  }

  return beneficiaries.nodes.filter(
    (n): n is BeneficiaryCharity<T> => n.__typename === 'Charity',
  );
}

export function getFirstCharity<T extends Beneficiaries>(
  beneficiaries?: T,
): BeneficiaryCharity<T> | null {
  return getCharities(beneficiaries)[0] ?? null;
}
