import appleCalendarIcon from '../images/apple-calendar-icon.svg';
import googleCalendarIcon from '../images/google-calendar-icon.svg';
import outlookCalendarIcon from '../images/outlook-calendar-icon.svg';
import outlookWebCalendarIcon from '../images/outlook-web-calendar-icon.svg';
import yahooCalendarIcon from '../images/yahoo-calendar-icon.svg';
import { CalendarType } from './CalendarType';

export const getCalendarIcon = (calendarType: CalendarType): string => {
  switch (calendarType) {
    case CalendarType.Apple:
      return appleCalendarIcon;
    case CalendarType.Google:
      return googleCalendarIcon;
    case CalendarType.Outlook:
      return outlookCalendarIcon;
    case CalendarType.OutlookWeb:
      return outlookWebCalendarIcon;
    case CalendarType.Yahoo:
      return yahooCalendarIcon;
    default:
      return '';
  }
};
