import React, { FunctionComponent } from 'react';

export const RunIcon: FunctionComponent = () => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.49 4.47998C12.59 4.47998 13.49 3.57998 13.49 2.47998C13.49 1.37998 12.59 0.47998 11.49 0.47998C10.39 0.47998 9.49 1.37998 9.49 2.47998C9.49 3.57998 10.39 4.47998 11.49 4.47998ZM7.89 18.38L8.89 13.98L10.99 15.98V21.98H12.99V14.48L10.89 12.48L11.49 9.47998C12.79 10.98 14.79 11.98 16.99 11.98V9.97998C15.09 9.97998 13.49 8.97998 12.69 7.57998L11.69 5.97998C11.29 5.37998 10.69 4.97998 9.99 4.97998C9.69 4.97998 9.49 5.07998 9.19 5.07998L3.99 7.27998V11.98H5.99V8.57998L7.79 7.87998L6.19 15.98L1.29 14.98L0.889999 16.98L7.89 18.38Z"
        fill="#999999"
      />
    </svg>
  );
};
