import { SVGProps } from 'react';

export const QrCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="#fff"
      d="M10 18v-2h2v2h-2Zm-2-2v-5h2v5H8Zm8-3V9h2v4h-2Zm-2-4V7h2v2h-2ZM2 11V9h2v2H2ZM0 9V7h2v2H0Zm9-7V0h2v2H9ZM1.5 4.5h3v-3h-3v3ZM0 6V0h6v6H0Zm1.5 10.5h3v-3h-3v3ZM0 18v-6h6v6H0ZM13.5 4.5h3v-3h-3v3ZM12 6V0h6v6h-6Zm2 12v-3h-2v-2h4v3h2v2h-4Zm-4-7V9h4v2h-4Zm-4 0V9H4V7h6v2H8v2H6Zm1-5V2h2v2h2v2H7ZM2.25 3.75v-1.5h1.5v1.5h-1.5Zm0 12v-1.5h1.5v1.5h-1.5Zm12-12v-1.5h1.5v1.5h-1.5Z"
    />
  </svg>
);
