'use client';

import React, { useState } from 'react';
import { Alert } from '../Alert/Alert';
import { PageAlertMessage } from './PageAlertMessage';

type Props = {
  messageKey: string;
};

export const PageAlert: React.FC<Props> = ({ messageKey }) => {
  const [isClosed, setIsClosed] = useState(false);

  if (isClosed) {
    return null;
  }

  return (
    <Alert onClose={() => setIsClosed(true)}>
      <PageAlertMessage messageKey={messageKey} />
    </Alert>
  );
};
