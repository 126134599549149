'use client';

import { ProductNames } from '../../constants/ProductNames';
import classnames from 'classnames';
import layoutStyles from '../Layout/DefaultLayout.module.scss';
import styles from './PageOwnerActions.module.scss';
import { useAuth } from '../Providers/AuthProvider';
import { useCharityAdmin } from '../../hooks/useCharityAdmin';
import { useEditUrl } from '../../hooks/useEditUrl';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { usePage } from '../Providers/PageProvider';
import { usePreview } from '../Providers/PreviewProvider';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export const PageOwnerActions: React.FC = () => {
  const isPageOwner = useIsPageOwner();
  const auth = useAuth();
  const { t } = useTranslation();
  const page = usePage();
  const { isPreview } = usePreview();
  const isCharityAdmin = useCharityAdmin();
  const editUrl = useEditUrl();

  if (!isPageOwner && !isCharityAdmin && !auth?.isStaff()) {
    return null;
  }

  if (
    !isPageOwner &&
    !isCharityAdmin &&
    auth?.isStaff() &&
    page.product.name === ProductNames.ProxyFundraising
  ) {
    // CS admins cannot edit proxy FRPs yet
    return null;
  }

  return (
    <div
      className={classnames('bg-color-neutral-300', styles.container, {
        [styles.preview]: isPreview,
      })}
    >
      <div
        className={classnames(layoutStyles.container, styles.pageOwnerActions)}
      >
        <a href={editUrl} className="cp-btn cp-btn-secondary">
          {t('pageOwnerActions.editPage')}
        </a>
        {isPreview && (
          <span className={styles.tab}>{t('page.preview', 'Preview')}</span>
        )}
      </div>
    </div>
  );
};

export const PageOwnerActionsWithBoundaries = () => (
  <ErrorBoundary>
    <PageOwnerActions />
  </ErrorBoundary>
);
