import React, { FunctionComponent } from 'react';
import styles from './AddToCalendarButton.module.scss';
import { addToCalendarHandler } from './helpers/addToCalendar';
import Image from 'next/image';

import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { uuid } from '../../utils/uid';
import { getCalendarIcon } from './helpers/getCalendarIcon';
import { CalendarType } from './helpers/CalendarType';
import classNames from 'classnames';
import { formatCurrency } from '../../utils/formatCurrency';
import i18next from 'i18next';
import { usePage } from '../Providers/PageProvider';

type AddToCalendarButtonProps = {
  buttonText?: string;
  calendarType: CalendarType;
  url: string;
  className?: string;
  startTime: Date;
  endTime?: Date;
};

export const AddToCalendarButton: FunctionComponent<
  AddToCalendarButtonProps
> = ({ calendarType, startTime, endTime, buttonText, url, className }) => {
  const { t } = useTranslation('common', { useSuspense: false });
  const { charity, event, currencyCode, targetAmount, owner } = usePage();
  const charityName = charity.name;
  const eventName = event?.name;
  const ownerName = owner?.name;
  const handleButtonClick = () => {
    const title = ownerName
      ? t('eventReminder.calendar.title.withOwner', {
          ownerName,
          charityName,
        })
      : t('eventReminder.calendar.title.withNoOwner', {
          eventName,
          charityName,
        });

    const targetAmountFormatted =
      targetAmount && currencyCode
        ? formatCurrency(targetAmount, currencyCode, i18next.language, {
            maximumFractionDigits: 0,
          })
        : null;
    const shareHash = uuid().replace(/-/g, '');
    const urlWithQueryString = `${url}?${queryString.stringify({
      utm_source: `${calendarType.toLowerCase()}-reminder`,
      utm_term: shareHash,
    })}`;
    const doubleLineSpacing =
      calendarType === CalendarType.Apple ||
      calendarType === CalendarType.Outlook
        ? '\\n\\n'
        : '\n\n';

    let descriptionLine1 = '';
    if (ownerName) {
      descriptionLine1 = targetAmountFormatted
        ? t('eventReminder.calendar.descriptionLine1.withOwnerWithTarget', {
            ownerName,
            eventName,
            targetAmountFormatted,
            charityName,
          })
        : t('eventReminder.calendar.descriptionLine1.withOwnerNoTarget', {
            ownerName,
            eventName,
            charityName,
          });
    } else {
      descriptionLine1 = t(
        'eventReminder.calendar.descriptionLine1.withNoOwner',
        {
          charityName,
          eventName,
        },
      );
    }

    const description = `${descriptionLine1}${doubleLineSpacing}${t(
      'eventReminder.calendar.descriptionLine2',
      {
        url: urlWithQueryString,
      },
    )}`;

    const event = {
      startTime,
      endTime: endTime || startTime,
      location: t('common.justgiving'),
      title,
      description,
    };

    addToCalendarHandler(event, calendarType);
  };

  const label = buttonText || calendarType;
  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={classNames(
        'w-auto',
        'cp-btn',
        'cp-btn-secondary',
        styles.reminder,
        className,
      )}
      data-qa={`add-calendar-button-${calendarType.toLowerCase()}`}
    >
      <Image
        src={getCalendarIcon(calendarType)}
        className="cp-img reminder"
        alt=""
      />
      {label}
    </button>
  );
};
