import React, { CSSProperties, HTMLProps, PropsWithChildren } from 'react';

import classnames from 'classnames';
import { layout } from '@justgiving/carepack-design-tokens';
import styles from './Stack.module.scss';

interface StackProps extends PropsWithChildren {
  alignItems?: CSSProperties['alignItems'];
  as?: React.ElementType;
  className?: string;
  direction?: CSSProperties['flexDirection'];
  gap?: keyof typeof layout;
  justifyContent?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
  flex?: CSSProperties['flex'];
}

export const Stack: React.FC<StackProps & HTMLProps<HTMLElement>> = ({
  alignItems,
  as = 'div',
  children,
  className,
  direction = 'column',
  gap = 'sizeSpacing03',
  justifyContent,
  wrap,
  flex,
  ...rest
}) => {
  const Element = as;

  return (
    <Element
      className={classnames(styles.stack, className)}
      style={{
        '--gap': layout[gap], // Pass gap as a variable, needed so that we can set the gap property in css so that PostCSS picks it up
        flexDirection: direction,
        alignItems,
        justifyContent,
        flexWrap: wrap,
        flex,
      }}
      {...rest}
    >
      {children}
    </Element>
  );
};
