'use client';

import {
  GetTimelineTotalQuery,
  GetTimelineTotalQueryVariables,
} from '../../lib/graphql';

import { FunctionComponent } from 'react';
import { GET_TIMELINE_TOTAL_QUERY } from '../TimelineSection/queries';
import classNames from 'classnames';
import styles from './TimelineUpdatesCount.module.scss';
import { usePage } from '../Providers/PageProvider';
import { useQuery } from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';

export const TimelineUpdatesCount: FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = usePage();

  const { data: timelineData, loading: timelineUpdatesLoading } = useQuery<
    GetTimelineTotalQuery,
    GetTimelineTotalQueryVariables
  >(GET_TIMELINE_TOTAL_QUERY, {
    variables: {
      pageId: id,
    },
  });

  if (!timelineData) {
    return null;
  }

  const totalCount = timelineData?.page?.timeline?.totalCount ?? 0;

  return (
    !timelineUpdatesLoading &&
    totalCount > 0 && (
      <div
        className={classNames('mt-0 mb-3', styles.timelineUpdatesCount)}
        data-testid="timeline-updates-count"
      >
        <span className={styles.updatesPointer}></span>
        <a href="#timelineUpdates" className={classNames('cp-text-link')}>
          {totalCount} {t('timeline.totalCountTitle')}
        </a>
      </div>
    )
  );
};
