import { SVGProps } from 'react';
export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      width="26px"
      height="26px"
      {...props}
    >
      <path
        d="M481.3 50C382.7 50 325 102.1 325 220.7V325H200v125h125v300h125V450h100l25-125H450v-83.2c0-44.7 14.6-66.8 56.5-66.8H575V55.1c-11.9-1.6-46.4-5.1-93.7-5.1Z"
        fill="#ffffff"
      />
    </svg>
  );
};
