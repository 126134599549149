'use client';

import React from 'react';
import classNames from 'classnames';
import { usePreview } from '../Providers/PreviewProvider';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

type ShareButtonProps = {
  id: string;
  variant?: 'PRIMARY' | 'SECONDARY';
  className?: string;
};

export const ShareButton = ({
  id,
  variant = 'SECONDARY',
  className,
}: ShareButtonProps) => {
  const { t } = useTranslation();
  const { isPreview } = usePreview();
  const router = useRouter();

  function handleOnClick() {
    if (isPreview) return;
    router.push('#sharePage', { scroll: true });
  }

  return (
    <>
      <button
        id={id}
        className={classNames(
          className,
          'cp-btn',
          { 'cp-btn-primary': variant === 'PRIMARY' },
          { 'cp-btn-secondary': variant === 'SECONDARY' },
        )}
        onClick={handleOnClick}
      >
        {t('share')}
      </button>
    </>
  );
};
