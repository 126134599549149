/* eslint-disable @next/next/no-img-element */
import React, { FunctionComponent } from 'react';

import { ChevronDown } from '../Icons/ChevronDown';
import { GroupMembersList } from '../GroupMembersList/GroupMembersList';
import { GroupPageSummary } from '../PageSummary/GroupPageSummary';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { Modal } from '../Modal/Modal';
import { Page } from '../../lib/pageData';
import { Stack } from '../Stack/Stack';
import classNames from 'classnames';
import { isInMemoryOccasion } from '../../utils/inMemory';
import styles from './DonateToGroupModal.module.scss';
import { useFetchGroupMembers } from '../../hooks/useFetchGroupMembers';
import { useTranslation } from 'react-i18next';

type DonateToGroupModalProps = {
  open: boolean;
  onClose: () => void;
  page: Page;
};

export const DonateToGroupModal: FunctionComponent<DonateToGroupModalProps> = ({
  open,
  onClose,
  page,
}) => {
  const { data, loading, handleFetchMore } = useFetchGroupMembers(page);

  const { t } = useTranslation();
  const isInMemoryTeam = isInMemoryOccasion(page.occasion?.type);
  const members = data?.page?.fundraisers || data?.page?.relationships?.members;

  if (!members) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <Modal.CloseButton />
      <Modal.Body className={styles.modalBody}>
        <Stack className="mb-5" gap="sizeSpacing04">
          <h1 className="cp-heading-medium mb-0 mt-0">
            {t(
              isInMemoryTeam
                ? 'groupMembersModal.titleInMem'
                : 'groupMembersModal.title',
            )}
          </h1>
          <GroupPageSummary page={page} t={t} />
        </Stack>
        <Stack className={styles.section}>
          <GroupMembersList
            page={{
              ...page,
              ...data?.page,
              relationships: {
                ...page.relationships!,
                members: {
                  nodes: [],
                  totalCount: 0,
                  pageInfo: {
                    hasNextPage: false,
                  },
                  ...data.page?.relationships?.members,
                },
              },
            }}
            className={classNames(styles.groupMemberList, {
              [styles.withBorder]: members.pageInfo.hasNextPage,
            })}
          />
          {members.pageInfo.hasNextPage && (
            <LoadingButton
              onClick={handleFetchMore}
              className={classNames('cp-text-link', styles.loadMoreButton)}
              loading={loading}
            >
              {t('groupMembers.loadMoreTeamMembers')}
              <ChevronDown />
            </LoadingButton>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <button
          type="button"
          className="cp-btn cp-btn-secondary  "
          onClick={onClose}
        >
          {t('close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
