import React, { SVGProps } from 'react';

export const XIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color,
  width = 40,
  height = 40,
  fill,
  ...props
}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="#fff"
        d="M14.283 10.162 23.218 0H21.1l-7.758 8.824L7.147 0H0l9.37 13.343L0 24h2.117l8.193-9.318L16.853 24H24l-9.717-13.838Zm-2.9 3.299-.95-1.329L2.88 1.56h3.252l6.096 8.532.95 1.329 7.924 11.09h-3.253l-6.466-9.05Z"
      />
    </svg>
  );
};
