import { SVGProps } from "react";

export const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
  <g clipPath="url(#a)">
    <path
      d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41Z"
      fill="#1667D9"
    />
  </g>
</svg>
);
