'use client';

import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  isCampaignPage,
  isCharityPage,
  isFundraisingPage,
} from '../../utils/pageType';

import { AverageDonation } from '../AverageDonation/AverageDonation';
import { Experiments } from '../../constants/experiments';
import { Page } from '../../lib/pageData';
import { SIZE_136_136 } from '../../constants/imageTemplateStrings';
import { SupporterDetailsProps } from '../SupporterDetails/SupporterDetails';
import { SupporterFragment } from '../../lib/graphql/types';
import { SupportersList } from '../SupportersList/SupportersList';
import { makePhotoUrl } from '../../utils/makePhotoUrl';
import { supporterFragment } from '../../lib/graphql/gqlPageDataQuery';
import { useDecision } from '@optimizely/react-sdk';
import { whenOwnerType } from '../../utils/ownerType';

const fetchNextPageQuery = /* GraphQL */ `
  ${supporterFragment}

  query DirectSupportersListNextPage(
    $pageId: ID
    $after: String
    $pageType: PageType
    $sortBy: SupportersSortInput
    $limit: Int
  ) {
    page(id: $pageId, type: $pageType) {
      supporters(first: $limit, after: $after, sort: $sortBy) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...Supporter
        }
      }
    }
  }
`;

type DirectSupportersListProps = {
  page: Page;
};

export const DirectSupportersList: FunctionComponent<
  DirectSupportersListProps
> = ({ page }) => {
  const { t } = useTranslation();

  const averageDonationsDecision = useDecision(
    Experiments.DonationAverage.flagKey,
  )[0].enabled;
  const showAverageDonation =
    averageDonationsDecision && isFundraisingPage(page.product?.name);
  const showTopDonations =
    isFundraisingPage(page.product?.name) || isCampaignPage(page.product?.name);

  // Group pages don't support direct donations. Display members count instead of direct donation count
  const supporterCount = whenOwnerType(page, {
    isIndividual: () => page.donationSummary?.donationCount,
    isOrganisation: () =>
      isCharityPage(page.product?.name)
        ? page.donationSummary?.onlineDonationCount
        : page.donationSummary?.donationCount,
    isPeerGroup: () =>
      page.donationSummary?.onlineDonationCount ?? page.donations?.totalCount, // legacy in-mem collection pages read the value from donations.totalCount
  });

  return (
    <SupportersList<SupporterFragment[]>
      page={page}
      supporters={page.supporters}
      supporterCount={supporterCount ?? 0}
      fetchNextPageQuery={fetchNextPageQuery}
      supporterToSupporterDetailsMapper={mapSupportersToSupporterDetails}
    >
      <SupportersList.Title>
        {t('supporters.direct.title')}
      </SupportersList.Title>
      {showAverageDonation && page.donationSummary && (
        <AverageDonation page={page} />
      )}
      {showTopDonations && <SupportersList.TopDonations />}
      <SupportersList.Supporters variant="direct" />
      <SupportersList.Placeholder>
        {whenOwnerType(page, {
          isIndividual: () =>
            t('fundraising.noSupportersMessage', {
              name: page.owner?.name ?? '',
            }),
          isOrganisation: () => t('campaign.noSupportersMessage'),
          isPeerGroup: () =>
            t('fundraising.noSupportersMessage', {
              name: page.owner?.name ?? '',
            }),
        })}
      </SupportersList.Placeholder>
      <SupportersList.LoadMore>
        <Trans i18nKey="supporters.direct.showMore">
          Show more
          <span className="cp-sr-only"> supporters</span>
        </Trans>
      </SupportersList.LoadMore>
      {whenOwnerType(page, {
        isIndividual: () => <SupportersList.Donate variant="direct" />,
        isOrganisation: () => <SupportersList.Donate variant="direct" />,
        isPeerGroup: () => <SupportersList.Donate variant="group" />,
      })}
      <SupportersList.Error>
        {t('supporters.showMoreError')}
      </SupportersList.Error>
    </SupportersList>
  );
};

const mapSupportersToSupporterDetails = (
  supporters?: SupporterFragment[] | null,
): ({ id: string } & SupporterDetailsProps)[] =>
  (supporters ?? []).map((supporter) => ({
    id: supporter.legacyId ?? '',
    date: supporter.creationDate ?? '',
    userName: supporter.displayName,
    image: supporter.avatar
      ? makePhotoUrl(supporter.avatar, SIZE_136_136)
      : undefined,
    message: supporter.message,
    currencyCode: supporter.amount?.currencyCode ?? 'GBP',
    donationAmount: (supporter.amount?.value ?? 0) / 100,
    giftAidAmount: (supporter.giftAidAmount?.value ?? 0) / 100,
  }));
