import { SVGProps } from 'react';
export const TiktokIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    width="26px"
    height="26px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.92 139.86"
    {...props}
  >
    <defs></defs>
    <path
      fill="#ffffff"
      d="M88.54 46.76a57.237 57.237 0 0 0 33.38 10.68V33.5c-2.36 0-4.71-.24-7.01-.73v18.84c-12.45 0-23.97-3.95-33.38-10.67v48.85c0 24.44-19.82 44.25-44.27 44.25-9.12 0-17.6-2.76-24.65-7.48 8.04 8.22 19.25 13.31 31.65 13.31 24.45 0 44.27-19.81 44.27-44.25V46.77Zm8.65-24.15a33.38 33.38 0 0 1-8.65-19.53V0H81.9c1.67 9.53 7.38 17.68 15.29 22.61ZM28.08 107.8a20.107 20.107 0 0 1-4.13-12.25c0-11.18 9.07-20.24 20.25-20.24 2.08 0 4.16.32 6.14.95V51.79c-2.32-.32-4.66-.45-7.01-.4v19.05c-1.99-.63-4.06-.95-6.15-.95-11.19 0-20.25 9.06-20.25 20.24 0 7.9 4.53 14.75 11.14 18.08Z"
    />
    <path
      fill="#ffffff"
      d="M81.53 40.94c9.41 6.72 20.93 10.67 33.38 10.67V32.77a33.523 33.523 0 0 1-17.72-10.15C89.27 17.68 83.57 9.53 81.9 0H64.45v95.61c-.04 11.15-9.09 20.17-20.25 20.17-6.58 0-12.42-3.13-16.12-7.99-6.61-3.33-11.14-10.18-11.14-18.08 0-11.18 9.07-20.24 20.25-20.24 2.14 0 4.21.33 6.15.95V51.37C19.32 51.87 0 71.49 0 95.61c0 12.04 4.81 22.96 12.62 30.94 7.04 4.73 15.52 7.48 24.65 7.48 24.45 0 44.27-19.81 44.27-44.25V40.93Z"
    />
  </svg>
);
