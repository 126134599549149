'use client';

import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import clientConfig from '../../client/config';
import { getSlugWithProductPrefix } from '../../utils/slug';
import styles from './ManageSettingsButton.module.scss';
import { useFitnessAppsState } from '../Providers/FitnessProvider';
import { usePage } from '../Providers/PageProvider';

type ManageSettingsButtonProps = {
  application: 'strava' | 'fitbit';
} & PropsWithChildren;

export const ManageSettingsButton = ({
  application,
  children,
}: ManageSettingsButtonProps) => {
  const fitnessAppsState = useFitnessAppsState();
  const { slug, product } = usePage();
  const slugWithPrefix = getSlugWithProductPrefix(slug, product.name);
  const url = fitnessAppsState[application].isConnected
    ? `${clientConfig.justGivingRootUrl}/${slugWithPrefix}/admin/settings/${application}`
    : `${clientConfig.justGivingRootUrl}/${slugWithPrefix}/admin/settings#fitness-section`;

  return (
    <a
      className={classNames(
        'cp-btn',
        styles[application],
        styles.manageSettingsButton,
      )}
      href={url}
    >
      {children}
    </a>
  );
};
