'use client';

import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';

type PreviewContextProps = {
  isPreview: boolean;
};

export const PreviewContext = createContext<PreviewContextProps | null>(null);

type PreviewProviderProps = {
  isPreview: boolean;
};

export const PreviewProvider: FunctionComponent<
  PropsWithChildren<PreviewProviderProps>
> = ({ isPreview, children }) => {
  return (
    <PreviewContext.Provider value={{ isPreview }}>
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreview = () => useNonNullableContext(PreviewContext);
