import { Card } from '../Card/Card';
import { ConnectionStatus } from './ConnectionStatus';
import { FitnessTotaliser } from '../FitnessTotaliser/FitnessTotaliser';
import { ManageSettingsButton } from './ManageSettingsButton';
import { PropsWithChildren } from 'react';
import { Stack } from '../Stack/Stack';
import classNames from 'classnames';
import styles from './FitnessCard.module.scss';

type FitnessCardProps = {
  className?: string;
} & PropsWithChildren;

export const FitnessCard = ({ className, children }: FitnessCardProps) => {
  return (
    <Card className={className}>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

const Header = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      className="mb-4"
      gap="sizeSpacing02"
      direction="row"
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

const Footer = ({ children }: PropsWithChildren) => {
  return (
    <div className={classNames(styles.footer, 'mt-4', 'pt-4')}>{children}</div>
  );
};

FitnessCard.Header = Header;
FitnessCard.Totaliser = FitnessTotaliser;
FitnessCard.Footer = Footer;
FitnessCard.ManageSettingsButton = ManageSettingsButton;
FitnessCard.ConnectionStatus = ConnectionStatus;
