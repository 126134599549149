'use client';

import React, { PropsWithChildren } from 'react';

import { useNonNullableContext } from '../../hooks/useNonNullableContext';

interface DonationProviderProps extends PropsWithChildren {
  jwt: string;
}

const DonationContext = React.createContext<string | null>(null);
DonationContext.displayName = 'DonateContext';

export const DonationProvider: React.FC<DonationProviderProps> = ({
  children,
  jwt,
}) => {
  return <DonationContext.Provider value={jwt}>{children}</DonationContext.Provider>;
};

export const useDonationJwt = () => useNonNullableContext(DonationContext);
