import { createHash } from 'crypto';

export const fundraiserFragment = /* GraphQL */ `
  fragment FundraiserCharity on Charity {
    legacyId
    displayName
  }

  fragment Fundraiser on Page {
    legacyId
    slug
    url
    status
    owner {
      legacyId
      avatar
      name
    }
    targetWithCurrency {
      value
      currencyCode
    }
    donationSummary {
      donationCount
      totalAmount {
        value
        currencyCode
      }
    }
    relationships {
      beneficiaries(first: 1) {
        nodes {
          __typename
          ...FundraiserCharity
        }
      }
    }
  }
`;

export const fundraisersFragment = /* GraphQL */ `
  fragment Fundraisers on PageLeaderboardConnection {
    nodes {
      ...Fundraiser
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
`;

export const teamFragment = /* GraphQL */ `
  fragment Team on Page {
    legacyId
    slug
    title
    status
    owner {
      id
      avatar
      name
    }
    donationSummary {
      totalAmount {
        value
        currencyCode
      }
      aggregatedDonationsCount
    }
    targetWithCurrency {
      value
    }
  }
`;

export const donationFragment = /* GraphQL */ `
  fragment Donation on Donation {
    id
    legacyId
    creationDate
    amount {
      value
      currencyCode
    }
    giftAidAmount {
      value
      currencyCode
    }
    displayName
    avatar
    message
  }
`;

export const supporterFragment = /* GraphQL */ `
  fragment Supporter on Supporter {
    id
    legacyId
    creationDate
    amount {
      value
      currencyCode
    }
    giftAidAmount {
      value
      currencyCode
    }
    displayName
    avatar
    message
  }
`;

export const membersFragment = /* GraphQL */ `
  fragment Members on PageRelationshipConnection {
    nodes {
      ...Fundraiser
    }
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`;

export const pageDataQuery = /* GraphQL */ `
  ${fundraiserFragment}
  ${fundraisersFragment}
  ${teamFragment}
  ${donationFragment}
  ${supporterFragment}
  ${membersFragment}

  fragment PageCoverImage on ImageMedia {
    url
    alt
  }

  fragment PageCoverVideo on VideoMedia {
    embeddedVideoURL
  }

  fragment PageCoverLivestream on LivestreamMedia {
    embeddedStreamURL
    embeddedStreamChatURL
  }

  fragment PageGalleryImage on ImageMedia {
    id
    url
    caption
  }

  query getPageData(
    $slug: Slug
    $id: ID
    $pageType: PageType
    $includeMembers: Boolean!
  ) {
    page(slug: $slug, id: $id, type: $pageType) {
      legacyId
      slug
      type
      title
      subtitle
      location {
        name
        latitude
        longitude
      }
      categories
      logo
      status
      endDate
      startDate
      url
      status
      attribution
      redirectionPageUrl
      product {
        ownerType
        name
        allowedPointsOfView
      }
      owner {
        id
        legacyId
        name
        avatar
      }
      cause {
        type
        marketCode
        bbms {
          merchantAccountId
        }
      }
      cover {
        __typename
        ...PageCoverImage
        ...PageCoverVideo
        ...PageCoverLivestream
      }
      gallery(first: 100) {
        nodes {
          __typename
          ...PageGalleryImage
        }
      }
      storyWithType {
        type
        content
      }
      summary
      targetWithCurrency {
        currencyCode
        value
      }
      activity {
        name
        date
      }
      occasion {
        type
        date
        names
        description
      }
      donationSummary {
        donationCount
        aggregatedDonationsCount
        onlineDonationCount
        totalAmount {
          value
          currencyCode
        }
        totalMatched {
          type
          amount {
            value
            currencyCode
          }
        }
        onlineAmount {
          value
          currencyCode
        }
        offlineAmount {
          value
          currencyCode
        }
        directDonationAmount {
          value
          currencyCode
        }
        fundraiserRaisedAmount {
          value
          currencyCode
        }
        textDonations {
          value
          currencyCode
        }
      }
      branding {
        primaryColor
        backgroundColor
        backgroundImage
        headerImage
        logo
        externalFooterBackgroundColor
        externalFooterForegroundColor
        externalHeaderBackgroundColor
        externalHeaderForegroundColor
      }
      reason {
        icon
        reason
        reasonUrl
      }
      donations(first: 5) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...Donation
        }
      }
      supporters(first: 5) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...Supporter
        }
      }
      relationships {
        config {
          membersDisabled
        }
        beneficiaries(first: 6) {
          nodes {
            __typename
            ... on Charity {
              id
              legacyId
              externalId
              externalUrl
              displayName
              description
              logo
              registrationNumber
              profilePageUrl
              countryCode
              impactStatement
              slug
              thankYouMessage
              entitlements {
                hasPoweredBy
                hasCampaignStartFundraisingRedirect
                hasCampaignFundraisingPageRedirect
                hasCampaignPageRedirect
              }
              donationPrompts {
                amount
                description
                type
              }
            }
          }
          totalCount
        }
        teams: parentsConnection(productName: TEAM, first: 1) {
          nodes {
            page {
              legacyId
              title
              url
              status
            }
          }
        }
        events: parentsConnection(productName: EVENT, first: 1) {
          nodes {
            requiredForChildren
            page {
              legacyId
              externalId
              launchDate
              endDate
              title
              startFundraisingUrl
              fundraisingSettings {
                totaliserTheme
              }
            }
          }
        }
        campaigns: parentsConnection(
          productNames: [CAMPAIGN, SITEBUILDER]
          first: 6
        ) {
          nodes {
            requiredForChildren
            page {
              legacyId
              title
              url
              summary
              logo
              relationships {
                beneficiaries(first: 1) {
                  nodes {
                    ... on Charity {
                      __typename
                      registrationNumber
                      displayName
                      profilePageUrl
                    }
                  }
                }
              }
              fundraisingSettings {
                fundraisingPageUrl
              }
            }
          }
        }
        parents {
          # Needed by data generator to build parent lookup items
          page {
            legacyId
          }
        }
        members(first: 5) @include(if: $includeMembers) {
          ...Members
        }
      }
      occasion {
        type
        date
        names
        description
      }
      totals {
        timeline {
          measurementDomain
          amounts {
            unit
            value
          }
        }
      }
      stravaSettings: application(name: "strava", type: FITNESS) {
        ... on FitnessApplication {
          settings {
            measurementSystem
            showTotaliser
            subscribedActivities
          }
        }
      }
      fitbitSettings: application(name: "fitbit", type: FITNESS) {
        ... on FitnessApplication {
          settings {
            measurementSystem
            showTotaliser
            subscribedActivities
          }
        }
      }
      membershipPolicy
      fundraisers: leaderboard(type: FUNDRAISERS, first: 5)
        @skip(if: $includeMembers) {
        ...Fundraisers
      }
      teams: leaderboard(type: TEAMS, first: 5) {
        nodes {
          ...Team
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
      fundraisingSettings {
        isTargetHidden
        isEnabled
        totaliserTheme
        totaliserLabel
      }
      startFundraisingUrl
      thumbnailImage
    }
  }
`;

export function getQueryHash(): string {
  const hash = createHash('sha1');
  hash.update(pageDataQuery);
  return hash.digest('hex');
}
