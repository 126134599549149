import {
  EmailIcon,
  InstagramIcon,
  LinkIcon,
  PrintIcon,
  QrCodeIcon,
  SmsIcon,
  SnapchatIconWithBackground,
  TiktokIcon,
  XIcon,
} from '../Icons';
import { HTMLProps, PropsWithChildren } from 'react';
import {
  IconLinkedin,
  IconMessenger,
  IconWhatsApp,
} from '@justgiving/carepack-icons';

import styles from './SocialButton.module.scss';
import { FacebookIcon } from '../Icons/FacebookIcon';

type SocialButtonType =
  | 'facebook'
  | 'twitter'
  | 'email'
  | 'whatsapp'
  | 'messenger'
  | 'linkedin'
  | 'copy'
  | 'sms'
  | 'qrCode'
  | 'instagram'
  | 'snapchat'
  | 'print'
  | 'tiktok';

interface SocialButtonProps extends PropsWithChildren {
  type: SocialButtonType;
  onClick?: () => void;
  as?: React.ElementType;
}

export const SocialButton: React.FC<
  SocialButtonProps & HTMLProps<HTMLElement>
> = ({ type, children, onClick, as = 'button', className = '', ...rest }) => {
  const Element = as;
  const Icon = iconMap[type];

  return (
    <Element
      id={`${type}-button`}
      className={`${styles.socialButton} ${className}`}
      onClick={onClick}
      {...rest}
    >
      <div className={`${styles.iconWrapper} ${styles[type]}`}>
        <Icon
          className={`${styles.icon} ${styles[type]}`}
          height={24}
          width={24}
        />
      </div>
      {children}
    </Element>
  );
};

const iconMap = {
  facebook: FacebookIcon,
  twitter: XIcon,
  email: EmailIcon,
  whatsapp: IconWhatsApp,
  messenger: IconMessenger,
  linkedin: IconLinkedin,
  copy: LinkIcon,
  sms: SmsIcon,
  qrCode: QrCodeIcon,
  instagram: InstagramIcon,
  snapchat: SnapchatIconWithBackground,
  print: PrintIcon,
  tiktok: TiktokIcon
};
