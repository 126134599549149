import { GraphQLError } from 'graphql';
import { executeQuery } from '../client/graphql';
import { useEffect, useState } from 'react';

interface UseQueryOptions<TQuery, TVariables> {
  variables?: TVariables;
  onSuccess?: (data: TQuery) => void;
  skip?: boolean;
  withAuth?: boolean;
  initialData?: TQuery;
}

export function useQuery<
  TQuery,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
>(query: string, options: UseQueryOptions<TQuery, TVariables> = {}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<GraphQLError[]>();
  const [data, setData] = useState<TQuery | undefined>(options.initialData);

  useEffect(() => {
    async function runQuery() {
      if (options.skip || options.initialData) {
        return;
      }

      try {
        setLoading(true);
        const result = await executeQuery<TQuery>({
          query,
          variables: options.variables,
          withAuth: options.withAuth,
          withAutomaticPersistedQueries: true,
        });

        setData(result.data);
        setErrors(result.errors);

        if (options.onSuccess) {
          options.onSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrors([new GraphQLError(error.message)]);
        } else {
          setErrors([new GraphQLError('Unknown error')]);
        }
      } finally {
        setLoading(false);
      }
    }

    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, options.skip]);

  async function fetchMore(
    fetchMoreOptions: UseQueryOptions<TQuery, Partial<TVariables>>,
    merge: (existing: TQuery | undefined, newData: TQuery) => TQuery,
  ) {
    setLoading(true);
    const { data: newData, errors } = await executeQuery<TQuery>({
      query,
      variables: { ...options.variables, ...fetchMoreOptions.variables },
      withAuth: options.withAuth,
      withAutomaticPersistedQueries: true,
    });

    const mergedData = merge(data, newData);
    setData(mergedData);
    setErrors(errors);
    setLoading(false);
  }

  return { loading, errors, data, fetchMore };
}
