'use client';

import {
  GetFitnessAppsStateQuery,
  GetFitnessAppsStateQueryVariables,
} from '../../lib/graphql';
import { ReactNode, createContext } from 'react';

import { getSlugWithProductPrefix } from '../../utils/slug';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { usePage } from './PageProvider';
import { useQuery } from '../../hooks/useQuery';

type FitnessApplicationState = {
  isConnected: boolean;
  isSubscribedToActivities: boolean;
};

export type FitnessContextType = {
  fitbit: FitnessApplicationState;
  strava: FitnessApplicationState;
  loading: boolean;
};

const FitnessContext = createContext<FitnessContextType | null>(null);

type FitnessProviderProps = {
  children: ReactNode;
};

const query = /* GraphQL */ `
  query GetFitnessAppsState($slug: Slug!) {
    viewer {
      thirdPartyApplications {
        strava {
          isConnected
        }
        fitbit {
          isConnected
        }
      }
    }
    page(slug: $slug) {
      strava: application(name: "strava", type: FITNESS) {
        ... on FitnessApplication {
          settings {
            subscribedActivities
          }
        }
      }
      fitbit: application(name: "fitbit", type: FITNESS) {
        ... on FitnessApplication {
          settings {
            subscribedActivities
          }
        }
      }
    }
  }
`;

export const FitnessProvider = ({ children }: FitnessProviderProps) => {
  const { slug, product } = usePage();
  const isPageOwner = useIsPageOwner();

  const { data, loading } = useQuery<
    GetFitnessAppsStateQuery,
    GetFitnessAppsStateQueryVariables
  >(query, {
    skip: !isPageOwner,
    withAuth: true,
    variables: {
      slug: getSlugWithProductPrefix(slug, product.name),
    },
  });

  return (
    <FitnessContext.Provider
      value={{
        loading,
        fitbit: {
          isConnected: Boolean(
            data?.viewer?.thirdPartyApplications?.fitbit?.isConnected,
          ),
          isSubscribedToActivities: Boolean(
            data?.page?.fitbit?.settings?.subscribedActivities?.length,
          ),
        },
        strava: {
          isConnected: Boolean(
            data?.viewer?.thirdPartyApplications?.strava?.isConnected,
          ),
          isSubscribedToActivities: Boolean(
            data?.page?.strava?.settings?.subscribedActivities?.length,
          ),
        },
      }}
    >
      {children}
    </FitnessContext.Provider>
  );
};

export const useFitnessAppsState = () => useNonNullableContext(FitnessContext);
