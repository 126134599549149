import CpAvatar from '@justgiving/carepack-avatar';
import { DefaultAvatar } from '../DefaultAvatar/DefaultAvatar';
import { LOGO } from '../../constants/imageTemplateStrings';
import React from 'react';
import causeDefaultAvatar from '../../../public/cause-default-logo.svg';
import classNames from 'classnames';
import defaultAvatar from '../../../public/default-avatar.svg';
import { makePhotoUrl } from '../../utils/makePhotoUrl';
import styles from './Avatar.module.scss';

export interface AvatarProps {
  src?: string | null;
  defaultSrc?: string | null;
  userName?: string | null;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  className?: string;
  variant?: 'INDIVIDUAL' | 'ORGANISATION';
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  userName,
  size,
  className,
  variant,
}) => {
  switch (variant) {
    case 'INDIVIDUAL':
      return (
        <CpAvatar
          src={src ?? defaultAvatar.src}
          size={size ?? 'medium'}
          alt=""
          className={classNames(styles.avatar, className)}
        />
      );
    case 'ORGANISATION':
      return (
        <CpAvatar
          src={src ? makePhotoUrl(src, LOGO) : causeDefaultAvatar.src}
          className={classNames(styles.organisationAvatar, className)}
          size={size ?? 'medium'}
          alt=""
        />
      );
    default:
      if (src) {
        return (
          <CpAvatar
            src={src}
            size={size ?? 'medium'}
            alt=""
            className={classNames(styles.avatar, className)}
          />
        );
      } else if (userName) {
        return (
          <DefaultAvatar size={size ?? 'medium'} className={className}>
            {userName?.[0]}
          </DefaultAvatar>
        );
      }
      return null;
  }
};
