import clientConfig from "../client/config"
import { usePage } from "../components/Providers/PageProvider"
import { isCharityPage } from "../utils/pageType"
import { useIsMounted } from "./useIsMounted"

type BuildDonateUrlWithQueryStringResponse = {
  buildDonateUrlWithQueryString: (showEmbeddedCheckout?: boolean, providedUrl?: string) => string
}

export const useBuildDonateUrlWithQueryString = (): BuildDonateUrlWithQueryStringResponse => {
  const isMounted = useIsMounted()
  const { url, product, charity, currencyCode } = usePage();

  const buildDonateUrlWithQueryString = (showEmbeddedCheckout?: boolean, providedUrl?: string)=> {
    const urlParams = new URLSearchParams(isMounted ? window.location.search : {});
    const donateUrlParams = new URLSearchParams();
  
    donateUrlParams.append('startedBy', 'webjustgiving');
  
    if (showEmbeddedCheckout) {
      donateUrlParams.append('checkoutMode', 'Headless');
    }
  
    const utmSource = urlParams.get('utm_source');
    if (utmSource) {
      donateUrlParams.append('reference', utmSource);
    }

    if (isCharityPage(product.name)) {
      donateUrlParams.append('currency', currencyCode);
      return `${clientConfig.linkServiceUrl}/v1/charity/donate/charityId/${charity.externalId}?${donateUrlParams.toString()}`;
    }
  
    return donateUrlParams.toString() ? `${providedUrl ?? url}/donate?${donateUrlParams.toString()}` : `${providedUrl ?? url}/donate`;
  }

  return { buildDonateUrlWithQueryString };
}
