export function formatCurrency(
  value: number,
  currencyCode: string,
  locale: string,
  options?: Intl.NumberFormatOptions,
) {
  const formatter = new Intl.NumberFormat(locale, {
    ...options,
    minimumFractionDigits:
      options?.maximumFractionDigits === 0 ? 0 : options?.minimumFractionDigits,
    style: 'currency',
    currency: currencyCode || 'GBP',
  });

  return formatter.format(value);
}
