'use client';

import { FitnessCard } from '../FitnessCard/FitnessCard';
import { useFitnessAppsState } from '../Providers/FitnessProvider';

export const StravaConnectionStatus = () => {
  const { strava, loading } = useFitnessAppsState();

  if (loading) {
    return null;
  }

  return (
    <FitnessCard.ConnectionStatus
      isConnected={strava.isConnected}
      isSubscribedToActivities={strava.isSubscribedToActivities}
    />
  );
};
