'use client';

import React from 'react';
import styles from './CoverImage.module.scss';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { useTranslation } from 'react-i18next';
import { useCharityAdmin } from '../../hooks/useCharityAdmin';
import { useEditUrl } from '../../hooks/useEditUrl';
import { ProductNames } from '../../constants/ProductNames';
import { usePage } from '../Providers/PageProvider';
import { useAuth } from '../Providers/AuthProvider';

export const ChangeCover: React.FC = () => {
  const { t } = useTranslation();
  const isPageOwner = useIsPageOwner();
  const isCharityAdmin = useCharityAdmin();
  const editUrl = useEditUrl();
  const page = usePage();
  const auth = useAuth();

  if (!isPageOwner && !isCharityAdmin && !auth?.isStaff()) {
    return null;
  }

  if (
    !isPageOwner &&
    !isCharityAdmin &&
    auth?.isStaff() &&
    page.product.name === ProductNames.ProxyFundraising
  ) {
    // CS admins cannot edit proxy FRPs yet
    return null;
  }

  return (
    <a className={styles.actionButton} href={editUrl}>
      {t('cover.change')}
    </a>
  );
};
