import Image, { ImageProps } from 'next/image';

import classNames from 'classnames';
import styles from './Logo.module.scss';

export const Logo: React.FC<ImageProps> = ({
  className,
  src,
  alt,
  ...props
}) => {
  return (
    <Image
      className={classNames(className, 'cp-img', styles.logo)}
      height={72}
      width={72}
      src={`${src}?template=Size120x120`}
      alt={alt}
      {...props}
    />
  );
};
