import React, { FunctionComponent, useEffect } from 'react';

import { Modal } from '../Modal/Modal';
import styles from './PopupRefocusModal.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  popupWindow: Window | null;
  onClose: () => void;
};

export const PopupRefocusModal: FunctionComponent<Props> = ({
  popupWindow,
  onClose,
}) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    const checkForClosedPopup = setInterval(() => {
      if (!popupWindow || popupWindow.closed) {
        onClose();
      }
    }, 1000);
    return () => {
      clearTimeout(checkForClosedPopup);
    };
  }, [popupWindow, onClose]);

  function refocus() {
    popupWindow?.focus();
  }

  return (
    <Modal
      className={styles.modal}
      open={popupWindow !== null}
      onClick={refocus}
      onClose={onClose}
      closeOnBackdropClick={false}
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Body>
          <p className="cp-heading-small">
            {t('popupRefocusModal.lostCheckoutWindow')}
          </p>
          <p className="cp-heading-small">
            {t('popupRefocusModal.clickAnywhereToRetrieve')}
          </p>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
