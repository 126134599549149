import { captureMessage, withScope } from '@sentry/nextjs';

import { GraphQLError } from 'graphql';
import { assert } from '../utils/assert';
import clientConfig from './config';
import { getAuthInstance } from '../utils/auth';
import { sha256 } from '../utils/hash';

export type GraphQLResponse<TData> = {
  data: TData;
  errors?: GraphQLError[];
};

export type ExecuteQueryOptions<TVariables> = {
  query: string;
  variables?: TVariables;
  withAuth?: boolean;
  withAutomaticPersistedQueries?: boolean;
};

export async function executeQuery<
  TData = unknown,
  TVariables = Record<string, unknown>,
>({
  query,
  variables,
  withAuth,
  withAutomaticPersistedQueries,
}: ExecuteQueryOptions<TVariables>): Promise<GraphQLResponse<TData>> {
  if (process.env.NODE_ENV === 'test') {
    // disable APQs for tests, as msw doesn't support them - https://github.com/mswjs/msw/issues/1580
    withAutomaticPersistedQueries = false;
  }

  if (withAuth) {
    // needs to be created inside the function as otherwise it'll break server-side rendering because it uses `document`
    const auth = getAuthInstance();

    if (auth.isUserLoggedIn()) {
      await auth.refreshAccessTokenIfExpired();
    }
  }

  const options: FetchOptions<TVariables> = {
    query,
    variables,
    headers: {
      'content-type': 'application/json',
      'apollographql-client-name': 'JG.Pages.Edge',
      'apollographql-client-version': process.env.SERVICE_VERSION ?? 'local',
    },
    credentials: withAuth ? 'include' : undefined,
  };

  let result: GraphQLResponse<TData>;

  if (withAutomaticPersistedQueries) {
    options.queryHash = await sha256(query);

    result =
      (await fetchWithQueryHash<TData, TVariables>(options)) ??
      (await fetchWithFullQuery<TData, TVariables>(options));
  } else {
    result = await fetchWithFullQuery<TData, TVariables>(options);
  }

  if (result.errors?.length) {
    withScope((scope) => {
      scope.setContext('ApolloGraphQLOperation', {
        variables: options.variables,
        query,
      });

      result.errors?.forEach((error) => {
        captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });
    });
  }

  return result;
}

type FetchOptions<TVariables> = {
  query: string;
  queryHash?: string;
  variables: TVariables | undefined;
  headers: Record<string, string>;
  credentials?: RequestCredentials;
};

async function fetchWithQueryHash<TData, TVariables>({
  queryHash,
  variables,
  headers,
  credentials,
}: FetchOptions<TVariables>): Promise<GraphQLResponse<TData> | null> {
  assert(queryHash);

  const searchParams = new URLSearchParams({
    extensions: JSON.stringify({
      persistedQuery: { version: 1, sha256Hash: queryHash },
    }),
  });

  if (variables) {
    searchParams.set('variables', JSON.stringify(variables));
  }

  const response = await fetch(
    `${clientConfig.graphqlBaseUrl}?${searchParams.toString()}`,
    {
      method: 'GET',
      headers,
      credentials,
    },
  );

  if (!response.ok) {
    const err = `Failed to execute GraphQL query: ${
      response.status
    } ${await response.text()}`;

    throw new Error(err);
  }

  const result: GraphQLResponse<TData> = await response.json();

  if (
    result.errors?.length === 1 &&
    result.errors[0].message === 'PersistedQueryNotFound'
  ) {
    return null;
  }

  return result;
}

async function fetchWithFullQuery<TData, TVariables>({
  query,
  queryHash,
  variables,
  headers,
  credentials,
}: FetchOptions<TVariables>): Promise<GraphQLResponse<TData>> {
  const payload: Record<string, unknown> = {
    query,
    variables,
  };

  if (queryHash) {
    payload.extensions = {
      persistedQuery: { version: 1, sha256Hash: queryHash },
    };
  }

  const response = await fetch(clientConfig.graphqlBaseUrl, {
    method: 'POST',
    headers,
    credentials,
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const err = `Failed to execute GraphQL query: ${
      response.status
    } ${await response.text()}`;

    throw new Error(err);
  }

  return await response.json();
}
