export const StravaIcon = () => (
  <svg
    data-qa="strava-avatar"
    aria-hidden="true"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="9"
      fill="#FC4C02"
      stroke="white"
      strokeWidth="2"
    ></rect>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2532 12.6184L10.3565 10.9158H9.04639L11.2595 15.1193L13.45 10.9158H12.1376L11.2532 12.6184Z"
      fill="#FFFFFE"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34064 8.56173L10.5231 10.9159H12.2491L9.33197 5.11108L6.44434 10.9159H8.17298L9.34064 8.56173Z"
      fill="#FFFFFE"
    ></path>
  </svg>
);
