export function isMobileDevice() {
  return (
    typeof window !== 'undefined' &&
    Boolean(window?.navigator?.userAgent?.match(/Mobi/i) ?? false)
  );
}

export function isIpadDevice() {
  return Boolean(window.navigator.userAgent.match(/iPad/i));
}
