import { HTMLProps } from 'react';
import classNames from 'classnames';
import styles from './Badge.module.scss';

export interface BadgeProps {
  as?: React.ElementType;
  colorScheme?: 'purple' | 'blue' | 'red' | 'orange' | 'green';
}

export const Badge: React.FC<BadgeProps & HTMLProps<HTMLElement>> = ({
  as = 'span',
  className,
  children,
  colorScheme = 'purple',
  ...props
}) => {
  const Element = as;
  return (
    <Element
      className={classNames(styles.badge, styles[colorScheme], className)}
      {...props}
    >
      {children}
    </Element>
  );
};
