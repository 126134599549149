'use client';

import { FunctionComponent, Suspense, lazy } from 'react';
import { TotaliserProps, TotaliserThemeProps } from '../Totaliser/props';

import DefaultTheme from '../Totaliser/themes/DefaultTheme/DefaultTheme';
import { Totaliser } from '../Totaliser/Totaliser';

export type TotaliserThemeName =
  | 'default'
  | 'london-eye'
  | 'wine'
  | 'star'
  | 'brain';

export type ThemedTotaliserProps = Omit<TotaliserProps, 'theme'> & {
  themeName?: TotaliserThemeName;
};

export const ThemedTotaliser: FunctionComponent<ThemedTotaliserProps> = ({
  themeName = 'default',
  ...totaliserProps
}) => {
  return (
    <Suspense>
      <Totaliser
        {...totaliserProps}
        theme={getThemeComponent(themeName)}
        reduceTextSize={themeName === 'wine' ? 2 : 0}
      />
    </Suspense>
  );
};

function getThemeComponent(
  themeName: TotaliserThemeName,
): FunctionComponent<TotaliserThemeProps> {
  switch (themeName) {
    case 'london-eye':
      return lazy(
        () => import('../Totaliser/themes/LondonEyeTheme/LondonEyeTheme'),
      );
    case 'wine':
      return lazy(
        () => import('../Totaliser/themes/WineGlassTheme/WineGlassTheme'),
      );
    case 'star':
      return lazy(() => import('../Totaliser/themes/StarTheme/StarTheme'));
    case 'brain':
      return lazy(() => import('../Totaliser/themes/BrainTheme/BrainTheme'));
    case 'default':
      return DefaultTheme;
  }
}
