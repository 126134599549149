/* eslint-disable @next/next/no-img-element */
import React, { FunctionComponent } from 'react';

import { Modal } from '../Modal/Modal';

import styles from './PopupBlockedModal.module.scss';
import { useTranslation } from 'react-i18next';
import clientConfig from '../../client/config';
import classNames from 'classnames';

type PopupBlockedModalProps = {
  open: boolean;
  onClose: () => void;
  checkoutUrl: string;
};

export const PopupBlockedModal: FunctionComponent<PopupBlockedModalProps> = ({
  open,
  onClose,
  checkoutUrl,
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Body>
          <img
            src={`${clientConfig.imageServiceUrl}JG_Roundel_Laptop_User1.png`}
            alt=""
            className={styles.image}
          />
          <h1>{t('popupBlockedModal.heading')}</h1>
          <p className={styles.message}>{t('popupBlockedModal.message')}</p>
          <button
            className={classNames(
              styles.button,
              'cp-btn cp-btn-brand branded-text-neutral',
            )}
            onClick={onClose}
          >
            {t('popupBlockedModal.cta.tryAgain')}
          </button>
          <a
            href={checkoutUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
          >
            {t('popupBlockedModal.cta.openCheckoutNewWindow')}
          </a>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
