import { DistanceUnit } from '../lib/graphql';

export function formatMetricDistance(metres: number): string {
  if (metres > 999) {
    const km = metres / 1000;
    const formattedKm = km.toFixed(2);
    return `${formattedKm} km`;
  }

  return `${metres} m`;
}

export function formatImperialDistance(feet: number): string {
  const miles = feet / 5280;
  return `${miles.toFixed(2)} mi`;
}

export function formatDistance(value: number, unit: DistanceUnit): string {
  if (unit === 'FEET') {
    return formatImperialDistance(value);
  }

  return formatMetricDistance(value);
}

export function formatElevation(value: number, unit: DistanceUnit): string {
  if (unit === 'FEET') {
    return `${value} ft`;
  }

  return `${value} m`;
}
