'use client';

import { PropsWithChildren } from 'react';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';

export const PageOwnerOnly = ({ children }: PropsWithChildren) => {
  const isPageOwner = useIsPageOwner();

  if (!isPageOwner) {
    return null;
  }

  return children;
};
