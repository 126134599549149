'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { Avatar } from '../Avatar/Avatar';
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import { EnvelopeIcon } from '../Icons/EnvelopeIcon';
import Link from 'next/link';
import { Modal } from '../Modal/Modal';
import { Stack } from '../Stack/Stack';
import clientConfig from '../../client/config';
import styles from './JoinPageModal.module.scss';
import { usePage } from '../Providers/PageProvider';
import { useTranslation } from 'react-i18next';

export const JoinPageModal = () => {
  const search = useSearchParams();
  const isInvite = search.get('invite') === 'true';
  const { title, fundraisers, id } = usePage();
  const { t } = useTranslation();
  const pathname = usePathname();
  const router = useRouter();

  function dismissModal() {
    const query = new URLSearchParams(search);
    query.delete('invite');
    router.replace(`${pathname}?${query}`);
  }

  return (
    <Modal onClose={dismissModal} open={isInvite}>
      <Modal.CloseButton />
      <Modal.Content className={styles.content} >
        <Modal.Body className={styles.body}>
          <div className={styles.iconWrapper}>
            <EnvelopeIcon />
          </div>
          <h1 className="cp-heading-small mb-4 mt-0">
            {t('joinPage.title', { pageTitle: title })}
          </h1>
          <Stack direction="row" alignItems="center" gap="sizeSpacing05">
            <AvatarGroup max={4} avatarSize="x-small">
              {fundraisers.items.map((x) => (
                <Avatar key={x.id} src={x.ownerAvatarUrl} />
              ))}
            </AvatarGroup>
            <span className="cp-body-small text-color-grey-400">
              {t('joinPage.members', { count: fundraisers.totalCount })}
            </span>
          </Stack>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Link
            className="cp-btn cp-btn-primary mb-4"
            href={`${clientConfig.justGivingRootUrl}/create-page/team/${id}/join-team`}
          >
            {t('joinTeam')}
          </Link>
          <button className="cp-btn cp-btn-secondary" onClick={dismissModal}>
            {t('joinPage.viewTeamPage')}
          </button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
