import { color } from '@justgiving/carepack-themes';
import isLightColor from './isLightColor';
import prepareHex from './prepareHex';

export const invertColor = (hex: string, monochrome?: boolean) => {
  const preparedHex = prepareHex(hex);
  if (!preparedHex) {
    return '';
  }
  const red: number = parseInt(preparedHex.slice(0, 2), 16);
  const green: number = parseInt(preparedHex.slice(2, 4), 16);
  const blue: number = parseInt(preparedHex.slice(4, 6), 16);

  if (monochrome) {
    return isLightColor(preparedHex)
      ? color.colorTextDark
      : color.colorNeutralLighter;
  }
  // invert color components
  const newRed = Math.max(255 - red, 0).toString(16);
  const newGreen = Math.max(255 - green, 0).toString(16);
  const newBlue = Math.max(255 - blue, 0).toString(16);

  const newHex = '#'
    .concat(
      newRed.padStart(2, '0'),
      newGreen.padStart(2, '0'),
      newBlue.padStart(2, '0'),
    )
    .toUpperCase();

  return newHex;
};

export default invertColor;
