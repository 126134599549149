'use client';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Page } from '../../lib/pageData';
import { DonateToGroupModal } from '../DonateToGroupModal/DonateToGroupModal';
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import styles from './DonateToGroupMemberAvtatars.module.scss';
import classNames from 'classnames';
import { Avatar } from '../Avatar/Avatar';

type DonateToGroupMemberAvatarsProps = {
  page: Page;
};

export const DonateToGroupMemberAvatars = ({
  page,
}: DonateToGroupMemberAvatarsProps) => {
  const { t } = useTranslation();

  const [donateToGroupModalOpen, setDonateToGroupModalOpen] = useState(false);

  const isDonateActive = page.status === 'ACTIVE';

  function handleClick() {
    setDonateToGroupModalOpen(true);
  }
  return (
    <>
      <AvatarGroup
        max={3}
        totalCount={page.fundraisers?.totalCount}
        className={classNames(
          { [styles.avatarGroupWithModalDisabled]: !isDonateActive },
          'btn-unstyled',
        )}
        as="button"
        onClick={handleClick}
        ariaLabel={t('giveNow')}
        isDisabled={!isDonateActive}
        avatarSize="medium"
      >
        {page.fundraisers?.nodes.map((x) => (
          <Avatar key={x.legacyId} src={x.owner?.avatar} variant="INDIVIDUAL" />
        ))}
      </AvatarGroup>
      {donateToGroupModalOpen && (
        <DonateToGroupModal
          page={page}
          open
          onClose={() => setDonateToGroupModalOpen(false)}
        />
      )}
    </>
  );
};
