import { useCarouselContext } from '../Carousel/Carousel';
import styles from './DonationPromptDots.module.scss';

export type DonationPromptDotsProps = {
  numberOfTiles: number;
};

export function DonationPromptDots({
  numberOfTiles
}: DonationPromptDotsProps) {
  const { activeTile, selectTile } = useCarouselContext();
  const activeTileToUse = activeTile > numberOfTiles || activeTile < 0 ? 0 : activeTile;
  const dots: React.ReactElement[] = [];
  for (let i = 0; i < numberOfTiles; i++) {
    const dot = (
      <button
        key={`dot-${i}`}
        aria-label={`prompt-${i}`}
        className={i === activeTileToUse ? styles.dotActive : styles.dot}
        onClick={() => selectTile(i)}
      />
    );
    dots.push(dot);
  }

  return (
    <div className={styles.dots} role="navigation">
      {dots}
    </div>
  );
};
