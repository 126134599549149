import { Auth, createAuth } from '@justgiving/auth';

import clientConfig from '../client/config';

let auth: Auth;

export function getAuthInstance() {
  if (!auth) {
    auth = createAuth({
      documentOrReq: document,
      authCookieName: clientConfig.authCookieName,
      activeGroupCookieName: clientConfig.activeGroupsCookieName,
      refreshAccessTokenUrl: `${clientConfig.iamUrl}/account/refresh-access-token`,
    });
  }
  return auth;
}
