import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  SupporterDetails,
  SupporterDetailsProps,
} from '../SupporterDetails/SupporterDetails';
import {
  SupportersListContext,
  useSupportersListContext,
} from './SupportersListContext';

import { Chip } from '@justgiving/carepack-chips';
import { DonatePopupButton } from '../DonatePopupButton/DonatePopupButton';
import { DonateToGroupMemberButton } from '../DonateToGroupMemberButton/DonateToGroupMemberButton';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { Page } from '../../lib/pageData';
import { Skeleton } from '../Skeleton/Skeleton';
import { Stack } from '../Stack/Stack';
import classNames from 'classnames';
import { executeQuery } from '../../client/graphql';
import { formatNumber } from '../../utils/formatNumber';
import styles from './SupportersList.module.scss';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '@optimizely/react-sdk';

type Supporters<TSupporter> = {
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: string | null;
  };
  nodes: TSupporter;
};

type PaginatedSupporters<TSupporter> = {
  page?: {
    supporters?: Supporters<TSupporter> | null;
  } | null;
};

type SupportersListProps<TSupporter> = {
  page: Page;
  supporters?: Supporters<TSupporter> | null;
  supporterCount: number;
  fetchNextPageQuery: string;
  supporterToSupporterDetailsMapper: (
    supporter?: TSupporter | null,
  ) => ({ id: string } & SupporterDetailsProps)[];
};

export function SupportersList<TSupporter>({
  page,
  supporters,
  supporterCount,
  fetchNextPageQuery,
  supporterToSupporterDetailsMapper,
  children,
}: PropsWithChildren<SupportersListProps<TSupporter>>) {
  const [currentNextPageCursor, setCurrentNextPageCursor] = useState<
    string | null
  >(null);

  const [displayedSupporters, setDisplayedSupporters] = useState(
    supporterToSupporterDetailsMapper(supporters?.nodes),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showTopDonations, setShowTopDonations] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const nextPageCursor =
    supporters?.pageInfo.hasNextPage && supporters?.pageInfo.endCursor
      ? supporters?.pageInfo.endCursor
      : null;

  useEffect(() => {
    setCurrentNextPageCursor(nextPageCursor); // only set on the client
  }, [nextPageCursor]);

  const setSupportersAndNextPageCursor = (
    data: PaginatedSupporters<TSupporter>,
    isFirstFetch?: boolean,
  ) => {
    const fetchedSupporters = supporterToSupporterDetailsMapper(
      data.page?.supporters?.nodes,
    );
    const hasNextPage = data.page?.supporters?.pageInfo.hasNextPage;
    const endCursor = data.page?.supporters?.pageInfo.endCursor;

    setCurrentNextPageCursor(hasNextPage && endCursor ? endCursor : null);
    isFirstFetch
      ? setDisplayedSupporters([...fetchedSupporters])
      : setDisplayedSupporters([...displayedSupporters, ...fetchedSupporters]);
  };

  async function fetchNextPage(doSort?: boolean, isFirstFetch?: boolean) {
    setIsLoading(true);
    setHasError(false);
    setShowSkeleton(!!isFirstFetch);
    const sortObj = doSort ? { donationValue: 'DESCENDING' } : {};
    try {
      const { data, errors } = await executeQuery<
        PaginatedSupporters<TSupporter>
      >({
        query: fetchNextPageQuery,
        variables: {
          pageId: page.legacyId,
          after: isFirstFetch ? null : currentNextPageCursor,
          pageType: page.type === 'IN_MEMORY' ? 'IN_MEMORY' : undefined,
          sortBy: sortObj,
          limit: !isFirstFetch ? 10 : 5,
        },
        withAutomaticPersistedQueries: true,
      });

      setIsLoading(false);

      if (errors && errors.length > 0) {
        setHasError(true);
      } else {
        setSupportersAndNextPageCursor(data, isFirstFetch);
      }
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
      throw e;
    }
  }

  return (
    <SupportersListContext.Provider
      value={{
        page,
        supporters: displayedSupporters,
        supporterCount,
        fetchMore: fetchNextPage,
        nextPageCursor: currentNextPageCursor,
        isLoading,
        hasError,
        showTopDonations,
        setShowTopDonations,
        showSkeleton,
      }}
    >
      <Stack
        className="w-full"
        as="section"
        gap="sizeSpacing03"
        direction="column"
        justifyContent="flex-start"
      >
        {children}
      </Stack>
    </SupportersListContext.Provider>
  );
}

const Title: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { supporterCount } = useSupportersListContext();

  return (
    <header>
      <h1 className="cp-heading-small m-0" id="supportersList">
        {children}
        {supporterCount > 0 && (
          <span className={styles.count} data-testid="supporters-count">
            {formatNumber(supporterCount)}
          </span>
        )}
      </h1>
    </header>
  );
};

type SupportersProps = {
  variant: 'direct' | 'group';
};

const Supporters: FunctionComponent<SupportersProps> = ({ variant }) => {
  const { supporters, isLoading, showSkeleton } = useSupportersListContext();

  if (isLoading && showSkeleton) {
    const dummySupporters = new Array(5).fill(0);
    return (
      <div className={styles.skeletonContainer}>
        {dummySupporters.map((_item, _index) => (
          <div key={_index}>
            <div className={styles.skeletonBox}>
              <div className={styles.avatarSkeleton}>
                <Skeleton width={5} height={5} circle={true} />
              </div>
              <div className={styles.usernameSkeleton}>
                <Skeleton width={25} height={2} borderRadius={10} />
                <div className={styles.donationSkeleton}>
                  <Skeleton width={12} height={2} borderRadius={10} />
                </div>
              </div>
            </div>
            {_index !== 4 && <span className={styles.separator} />}
          </div>
        ))}
      </div>
    );
  }
  return (
    <ul className="m-0 p-0">
      {supporters.map((details) => {
        switch (variant) {
          case 'direct':
            return (
              <SupporterDetails key={details.id} {...details}>
                <SupporterDetails.Avatar />
                <SupporterDetails.Title />
                <SupporterDetails.Date />
                <SupporterDetails.Message />
                <SupporterDetails.DonationAmounts />
              </SupporterDetails>
            );
          case 'group':
            return (
              <SupporterDetails key={details.id} {...details}>
                <SupporterDetails.Avatar />
                <SupporterDetails.Title />
                <SupporterDetails.DonationAmounts displayDonationWhenZero />
                <SupporterDetails.GroupSupporterCount />
                <SupporterDetails.Totals />
              </SupporterDetails>
            );
          default:
            return null;
        }
      })}
    </ul>
  );
};

const Placeholder: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { supporterCount } = useSupportersListContext();

  return (
    supporterCount === 0 && (
      <>
        <p className="cp-body-medium">{children}</p>
        <SupporterDetails
          userName={
            <PlaceholderBar width="50%" color="grey-300" marginBottom="28px" />
          }
          message={
            <>
              <PlaceholderBar />
              <PlaceholderBar width="80%" marginBottom="26px" />
              <PlaceholderBar width="60%" marginBottom="17px" />
            </>
          }
        >
          <SupporterDetails.Avatar />
          <SupporterDetails.Title />
          <SupporterDetails.Message />
        </SupporterDetails>
      </>
    )
  );
};

type PlaceholderBarProps = {
  color?: string;
  width?: string;
  marginBottom?: string;
};

const PlaceholderBar: FunctionComponent<PlaceholderBarProps> = ({
  color = 'neutral-600',
  width = '100%',
  marginBottom = '15px',
}) => {
  return (
    <span
      className={classNames(styles.placeholderBar, `bg-color-${color}`)}
      style={{ width, marginBottom }}
      aria-hidden
    />
  );
};

const LoadMore: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { nextPageCursor, fetchMore, isLoading, showTopDonations } =
    useSupportersListContext();
  const [track, clientReady] = useTrackEvent();
  if (!nextPageCursor) {
    return null;
  }

  return (
    <LoadingButton
      className="cp-btn cp-btn-secondary w-full"
      loading={isLoading}
      onClick={() => {
        if (clientReady) {
          track('click_supporters_show_more');
        }
        fetchMore(showTopDonations, false);
      }}
    >
      {children}
    </LoadingButton>
  );
};

type DonateProps = {
  variant: 'direct' | 'group';
};

const Donate: FunctionComponent<DonateProps> = ({ variant }) => {
  const { page } = useSupportersListContext();

  switch (variant) {
    case 'direct':
      return <DonatePopupButton id="supporters-donate-button" />;
    case 'group':
      return (
        <DonateToGroupMemberButton
          page={page}
          id="donate-to-the-group-member"
        />
      );
    default:
      return null;
  }
};

const TopDonations: FunctionComponent = () => {
  const { showTopDonations, setShowTopDonations, fetchMore } =
    useSupportersListContext();

  const { t } = useTranslation();
  const handleClick = () => {
    const toggledState = !showTopDonations;
    setShowTopDonations(toggledState);
    fetchMore(toggledState, true);
  };
  return (
    <Chip
      selected={showTopDonations}
      showSelectedIcon={showTopDonations}
      onClick={handleClick}
      className={styles.topDonation}
    >
      {t('topDonations')}
    </Chip>
  );
};

const Error: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { hasError } = useSupportersListContext();

  if (!hasError) {
    return null;
  }

  return <p className={styles.error}>{children}</p>;
};

SupportersList.Title = Title;
SupportersList.Supporters = Supporters;
SupportersList.Placeholder = Placeholder;
SupportersList.LoadMore = LoadMore;
SupportersList.Donate = Donate;
SupportersList.Error = Error;
SupportersList.TopDonations = TopDonations;
