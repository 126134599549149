import React, { FunctionComponent } from 'react';

export const HikeIcon: FunctionComponent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64758 7.74702C3.69111 7.46945 3.83414 7.13015 4.17209 6.87994C4.71194 6.48026 5.32223 6.59521 5.71797 6.90595L5.72149 6.90872C6.19671 7.28656 6.7852 7.67332 7.28931 7.86086C7.82413 8.04926 8.00804 7.96911 8.07996 7.91586L9.86508 6.59422C10.3405 6.24221 11.034 6.25948 11.4851 6.66805C11.4858 6.6687 11.4865 6.66934 11.4872 6.66999L15.1972 10.0038C15.2018 10.003 15.2065 10.0023 15.2112 10.0015C15.4848 9.94978 15.8585 9.82523 16.3324 9.66727C16.4108 9.64112 16.492 9.61405 16.5759 9.58625C17.1358 9.39458 17.7964 9.18684 18.4782 9.13072L18.4824 9.13037C19.1822 9.07681 20.1633 9.23034 20.769 10.08L22.2782 12.1966C22.6889 12.7727 22.5601 13.6167 21.9878 14.0404L18.2705 16.7926C17.0549 17.6926 15.5498 18.1011 13.1672 17.839L12.8408 17.8031L12.8312 17.7896L3.61725 16.5707C3.21619 16.5265 2.91667 16.2842 2.73986 16.0362C2.55547 15.7776 2.45337 15.4388 2.49693 15.0881L2.49844 15.0759L3.64758 7.74702ZM5.06477 8.30491L4.00014 15.0948L13.5175 16.3538C15.5214 16.5464 16.5864 16.1734 17.3997 15.5712L20.9619 12.9339L19.5635 10.9725C19.3904 10.7297 19.0465 10.604 18.5878 10.6387C18.102 10.679 17.5904 10.8323 17.0399 11.0208L17.0328 11.0233C16.9583 11.048 16.8823 11.0733 16.8055 11.0991C16.3426 11.2539 15.8492 11.4191 15.4653 11.4905L15.454 11.4926C15.3357 11.5127 15.2213 11.5255 15.1175 11.5302C15.0375 11.5337 14.9175 11.5351 14.7955 11.5115L14.7672 11.5069C14.7613 11.5059 14.7519 11.5043 14.7416 11.5023C14.7346 11.501 14.7104 11.4964 14.681 11.4884C14.6658 11.4843 14.6377 11.4762 14.6036 11.4628C14.573 11.4507 14.5096 11.4237 14.4395 11.3727C14.3846 11.3328 14.317 11.2718 14.2584 11.1842L10.6119 7.9074L8.95076 9.13721C8.27532 9.63729 7.47672 9.53163 6.78873 9.28813L6.77897 9.28468C6.17367 9.06076 5.55794 8.6717 5.06477 8.30491ZM14.1333 10.6933C14.1358 10.6634 14.1394 10.6419 14.1408 10.6332C14.1468 10.5974 14.1376 10.6466 14.1333 10.6933Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4523 15.4671L3.22865 14.2469L3.42847 12.7468L13.1386 14.0314C15.1425 14.224 16.2075 13.8509 17.0208 13.2488L20.7382 10.4966L21.609 11.7179L17.8916 14.4701C16.676 15.3701 15.1709 15.7787 12.7883 15.5165L12.4619 15.4806L12.4523 15.4671Z"
        fill="#979797"
      />
    </svg>
  );
};
