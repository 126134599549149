'use client';

import React, { FunctionComponent } from 'react';
import { SupportersList } from '../SupportersList/SupportersList';
import { Trans, useTranslation } from 'react-i18next';
import { Page } from '../../lib/pageData';
import { makePhotoUrl } from '../../utils/makePhotoUrl';
import { SIZE_136_136 } from '../../constants/imageTemplateStrings';
import { FundraiserFragment } from '../../lib/graphql';
import { SupporterDetailsProps } from '../SupporterDetails/SupporterDetails';
import { fundraiserFragment } from '../../lib/graphql/gqlPageDataQuery';

const fetchNextPageQuery = /* GraphQL */ `
  ${fundraiserFragment}

  query IndividualSupportersListNextPage($pageId: ID, $after: String!) {
    page( id: $pageId) {
      supporters: leaderboard(type: FUNDRAISERS, first: 5, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...Fundraiser
        }
      }
    }
  }
`;

type IndividualSupportersListProps = {
  page: Page;
};

export const IndividualSupportersList: FunctionComponent<
  IndividualSupportersListProps
> = ({ page }) => {
  const { t } = useTranslation();

  if (!page.fundraisers) return null;

  const supporterCount = page.fundraisers?.totalCount ?? 0;

  if (supporterCount === 0) return null;

  return (
    <SupportersList<FundraiserFragment[]>
      page={page}
      supporters={page.fundraisers}
      supporterCount={supporterCount}
      fetchNextPageQuery={fetchNextPageQuery}
      supporterToSupporterDetailsMapper={mapIndividualsToSupporterDetails}
    >
      <SupportersList.Title>
        {t('supporters.individual.title')}
      </SupportersList.Title>
      <SupportersList.Supporters variant="group" />
      <SupportersList.LoadMore>
        <Trans i18nKey="supporters.individual.showMore">
          Show more
          <span className="cp-sr-only"> fundraisers</span>
        </Trans>
      </SupportersList.LoadMore>
      <SupportersList.Error>
        {t('supporters.showMoreError')}
      </SupportersList.Error>
    </SupportersList>
  );
};

const mapIndividualsToSupporterDetails = (
  fundraisers?: FundraiserFragment[] | null,
): ({ id: string } & SupporterDetailsProps)[] => {
  return (fundraisers ?? []).map((fundraiser) => ({
    id: fundraiser.legacyId,
    userName: fundraiser.owner?.name,
    image: fundraiser.owner?.avatar
      ? makePhotoUrl(fundraiser.owner.avatar, SIZE_136_136)
      : undefined,
    currencyCode:
      fundraiser.donationSummary?.totalAmount?.currencyCode ?? 'GBP',
    donationAmount: (fundraiser.donationSummary?.totalAmount?.value ?? 0) / 100,
    target: (fundraiser.targetWithCurrency?.value ?? 0) / 100,
    groupSupporterCount: fundraiser.donationSummary?.donationCount ?? 0,
    slug: fundraiser.slug,
    isCancelled: fundraiser.status === 'CANCELLED',
  }));
};
