import React, { SVGProps } from 'react';

export const InstagramIcon: React.FC<SVGProps<SVGSVGElement>> = ({
  color,
  width = 40,
  height = 40,
  fill,
  ...props
}) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <path
        fill="#fff"
        d="M5.98.063C5.024.108 4.37.26 3.8.486c-.591.23-1.092.54-1.59 1.04a4.41 4.41 0 0 0-1.034 1.596c-.221.572-.37 1.227-.413 2.185C.72 6.265.71 6.573.716 9.017c.005 2.444.015 2.75.062 3.71.045.958.197 1.612.422 2.184.23.591.539 1.093 1.04 1.592.5.5 1 .806 1.593 1.035.572.221 1.226.372 2.182.414.957.042 1.265.052 3.705.047 2.44-.005 2.747-.016 3.705-.061.959-.046 1.608-.199 2.18-.423a4.404 4.404 0 0 0 1.59-1.04 4.411 4.411 0 0 0 1.033-1.597c.221-.572.372-1.227.413-2.184.042-.96.052-1.268.047-3.711-.004-2.444-.015-2.75-.06-3.71-.046-.96-.199-1.612-.423-2.184a4.42 4.42 0 0 0-1.039-1.592c-.5-.5-1.002-.806-1.594-1.035C15 .241 14.347.09 13.39.048c-.956-.041-1.264-.052-3.705-.047-2.44.005-2.746.015-3.705.062Zm.105 16.27c-.876-.038-1.352-.184-1.67-.306a2.798 2.798 0 0 1-1.034-.671 2.773 2.773 0 0 1-.674-1.034c-.123-.317-.272-.793-.313-1.67-.044-.95-.054-1.234-.059-3.637-.005-2.403.004-2.687.046-3.636.037-.877.184-1.354.305-1.671.162-.421.357-.72.67-1.036a2.774 2.774 0 0 1 1.033-.675c.317-.124.792-.272 1.668-.313.948-.045 1.232-.054 3.63-.06 2.4-.005 2.684.004 3.633.046.875.038 1.352.183 1.668.306.42.162.72.357 1.035.671.316.315.51.613.674 1.034.124.317.271.792.313 1.67.045.95.055 1.234.06 3.636.004 2.402-.005 2.688-.046 3.636-.039.878-.184 1.354-.306 1.672-.162.42-.357.72-.67 1.036-.314.316-.613.511-1.033.675-.316.124-.792.271-1.667.313-.948.045-1.232.054-3.632.06-2.4.005-2.683-.005-3.63-.046M13.411 4.19a1.082 1.082 0 0 0 1.29 1.057 1.078 1.078 0 0 0 .784-1.474 1.08 1.08 0 0 0-1.76-.347 1.08 1.08 0 0 0-.314.764ZM5.088 9.009a4.618 4.618 0 0 0 4.623 4.612 4.619 4.619 0 0 0 4.606-4.63 4.618 4.618 0 0 0-4.624-4.612 4.618 4.618 0 0 0-4.605 4.63Zm1.618-.003A3.003 3.003 0 0 1 8.55 6.23a2.992 2.992 0 0 1 3.266.644A3.002 3.002 0 0 1 9.708 12a2.993 2.993 0 0 1-2.771-1.847 3.003 3.003 0 0 1-.23-1.147Z"
      />
    </svg>
  );
};
