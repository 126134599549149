'use client';

import {
  IconValidationAlert,
  IconValidationCheck,
} from '@justgiving/carepack-icons';
import React, {
  AnimationEvent,
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';
import styles from './Toast.module.scss';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { uuid } from '../../utils/uid';

type ToastContext = {
  showToast: (options: ShowToastOptions) => void;
};

const ToastContext = React.createContext<ToastContext | undefined>(undefined);
ToastContext.displayName = 'ToastContext';

export type ToastType = 'success' | 'warning';

export type ShowToastOptions = {
  type: ToastType;
  message: string;
  dismissAfter?: number;
};

type ToastRecord = {
  id: string;
  parentElement: Element;
  type: ToastType;
  message: string;
  dismissAfter: number;
};

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastRecord[]>([]);

  function showToast({ type, message, dismissAfter = 5000 }: ShowToastOptions) {
    setToasts((toasts) => {
      const dialogs = Array.from(
        document.querySelectorAll('dialog:not([role=alert])'),
      );
      const topmostDialog = dialogs[dialogs.length - 1];

      return [
        ...toasts,
        {
          id: uuid(),
          parentElement: topmostDialog ?? document.body,
          type,
          message,
          dismissAfter,
        },
      ];
    });
  }

  function removeToast(id: string) {
    setToasts((toasts) => toasts.filter((t) => t.id !== id));
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.map(({ id, ...props }) => (
        <Toast key={id} onHide={() => removeToast(id)} {...props} />
      ))}
    </ToastContext.Provider>
  );
};

export function useToast(): { showToast: ToastContext['showToast'] } {
  const context = useNonNullableContext(ToastContext);

  return {
    showToast: context.showToast,
  };
}

const icons: Record<ToastType, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  success: IconValidationCheck,
  warning: IconValidationAlert,
};

type ToastProps = Omit<ToastRecord, 'id'> & {
  onHide: () => void;
};

type ToastState = 'hide' | 'show';

const Toast: React.FC<ToastProps> = ({
  type,
  message,
  dismissAfter,
  onHide,
  parentElement,
}) => {
  const [state, setState] = useState<ToastState>('show');
  const Icon = icons[type];

  useEffect(() => {
    const timer = setTimeout(() => {
      setState('hide');
    }, dismissAfter);
    return () => clearTimeout(timer);
  }, [dismissAfter]);

  return createPortal(
    <dialog role="alert" open className={styles.dialog}>
      <div
        className={classNames(styles.toast, styles[type], styles[state])}
        onClick={() => setState('hide')}
        onAnimationEnd={(evt: AnimationEvent<HTMLDivElement>) => {
          if (evt.animationName === styles.toastDown) {
            onHide();
          }
        }}
      >
        <div className={styles.icon}>
          <Icon />
        </div>
        <div className={styles.message}>{message}</div>
      </div>
    </dialog>,
    parentElement,
  );
};
