'use client';

import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { DonatePopupButton } from '../DonatePopupButton/DonatePopupButton';
import { DonateToGroupMemberButton } from '../DonateToGroupMemberButton/DonateToGroupMemberButton';
import Image from 'next/image';
import { Modal } from '../Modal/Modal';
import { Page } from '../../lib/pageData';
import { Stack } from '../Stack/Stack';
import classNames from 'classnames';
import newDonationImage from '../../../public/new_donation.webp';
import styles from './DonateToYourselfModal.module.scss';
import { useIsNewPage } from '../../hooks/useIsNewPage';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';

type DonateToYourselfModalProps = {
  page: Page;
};

export const DonateToYourselfModal = ({ page }: DonateToYourselfModalProps) => {
  const isNewPage = useIsNewPage();
  const isPageOwner = useIsPageOwner();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  // Cannot initialise the open state as isPageOwner returns false on the first render
  useEffect(() => {
    if (isPageOwner && isNewPage) {
      setOpen(true);
    }
  }, [isPageOwner, isNewPage]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.CloseButton />
      <Modal.Content>
        <Modal.Body applyMaxWidth>
          <div
            className={styles.container}
            data-testid="donate-to-yourself-modal"
          >
            <Image src={newDonationImage} alt="" width={175} height={175} />
            <h1 className="cp-heading-small mt-6 mb-4">
              {t('donateToYourself.heading')}
            </h1>
            <div className={classNames('cp-body-medium', 'mb-6', styles.body)}>
              <Trans i18nKey="donateToYourself.body">
                People who make a donation to their own page raise up to{' '}
                <strong className="cp-font-bold">84%</strong> more for their
                chosen charity
              </Trans>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer applyMaxWidth>
          <Stack alignItems="center">
            {page.product?.ownerType !== 'PEER_GROUP' ? (
              <DonatePopupButton className="w-auto" colorVariant="brand">
                {t('donateToYourself.cta')}
              </DonatePopupButton>
            ) : (
              <DonateToGroupMemberButton
                page={page}
                id="donate-to-the-group-member"
              />
            )}
            <button
              className="cp-btn cp-btn-ghost"
              onClick={() => setOpen(false)}
            >
              {t('donateToYourself.dismiss')}
            </button>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
