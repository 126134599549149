'use client';

import { IconValidationAlert } from '@justgiving/carepack-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../Icons';
import styles from './Alert.module.scss';

type Props = {
  onClose?: () => void;
  children: React.ReactNode;
};

export const Alert: React.FC<Props> = ({ onClose, children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.alert}>
      <div className={styles.container}>
        <IconValidationAlert className={styles.errorIcon} />
        <div className={styles.body}>{children}</div>
        {onClose && (
          <button
            className={classNames(styles.closeButton)}
            aria-label={t('close')!}
            onClick={onClose}
          >
            <CloseIcon className={styles.closeIcon} />
          </button>
        )}
      </div>
    </div>
  );
};
