import { createContext } from 'react';
import { SupporterDetailsProps } from '../SupporterDetails/SupporterDetails';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';

type SupporterDetailsContextProps = SupporterDetailsProps;

export const SupporterDetailsContext =
  createContext<SupporterDetailsContextProps | null>(null);

export const useSupporterDetailsContext = () =>
  useNonNullableContext(SupporterDetailsContext);
