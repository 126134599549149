'use client';

import { Logo } from '../Logo/Logo';
import { Modal } from '../Modal/Modal';
import { Stack } from '../Stack/Stack';
import styles from './CauseDetailsList.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FindOutMoreButtonProps = {
  logoUrl: string;
  name: string;
  registrationNumber: string;
  description: string;
};

export const FindOutMoreButton = ({
  logoUrl,
  name,
  registrationNumber,
  description,
}: FindOutMoreButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="cp-text-link btn-unstyled"
        onClick={() => setIsOpen(true)}
      >
        {t('findOutMore')}
      </button>
      <Modal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        className={styles.modal}
      >
        <Modal.CloseButton />
        <Modal.Content>
          <Modal.Body>
            <h1 className="cp-heading-medium mt-0 mb-5">
              {t('causeDetails.charity.heading')}
            </h1>
            <Stack direction="row" alignItems="start" className="mb-5">
              <Logo src={logoUrl} width={100} height={100} alt="" />
              <div>
                <h2 className="cp-heading-small mt-0 mb-1">{name}</h2>
                <div className="cp-body-small text-color-grey-400 mb-4">
                  {t('charity.registrationNumber')} {registrationNumber}
                </div>
              </div>
            </Stack>
            <p className="m-0 cp-body-medium">{description}</p>
          </Modal.Body>
          <Modal.Footer className={styles.modalFooter}>
            <button
              type="button"
              className="cp-btn cp-btn-secondary"
              onClick={() => setIsOpen(false)}
            >
              {t('close')}
            </button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};
