'use client';

import IconLabel from '../IconLabel/IconLabel';
import { IconPeopleGroup } from '@justgiving/carepack-icons';
import React from 'react';
import clientConfig from '../../client/config';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { usePage } from '../Providers/PageProvider';
import { useTranslation } from 'react-i18next';

export const CreateTeam: React.FC = () => {
  const { t } = useTranslation();
  const isPageOwner = useIsPageOwner();
  const { id } = usePage();

  if (!isPageOwner) {
    return null;
  }

  return (
    <IconLabel>
      <IconLabel.Icon>
        <IconPeopleGroup />
      </IconLabel.Icon>
      <IconLabel.Label>
        <a
          className="cp-text-link"
          href={`${clientConfig.justGivingRootUrl}/create-page/team?fundraisingPageId=${id}`}
        >
          {t('team.createTeam')}
        </a>
      </IconLabel.Label>
    </IconLabel>
  );
};
