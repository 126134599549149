'use client';

import { ProductNames } from '../../constants/ProductNames';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { usePage } from '../Providers/PageProvider';
import { useTranslation } from 'react-i18next';
import { whenOwnerType } from '../../utils/ownerType';
import styles from './SocialSharePage.module.scss';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { isFundraisingPage, isTeamsPage } from '../../utils/pageType';
import { useDecision } from '@optimizely/react-sdk';
import { Experiments } from '../../constants/experiments';
import CampaignUTMTrackingContext from './context/campaignUTMTrackingContext';

const SharePage = dynamic(() => import('./SharePage').then((x) => x.SharePage));

export const SocialSharePage = () => {
  const { t } = useTranslation();
  const page = usePage();
  const isPageOwner = useIsPageOwner();
  const { product } = page;
  const decision = useDecision(Experiments.facebookSharing.flagKey);
  const showVariantA = !decision[0].enabled || decision[0].variationKey === 'default';

  const updateCampaignUTMTracking = {
    forTeams: {
      newVariant: isTeamsPage(product?.name),
      updateTracking: isTeamsPage(product?.name),
    },
    forFBVariantA: {
      enabled: isFundraisingPage(product?.name) && showVariantA
    },
    forFBVariantB: {
      enabled: isFundraisingPage(product?.name) && decision[0].variationKey === 'b',
    },
    forFBVariantC: {
      enabled: isFundraisingPage(product?.name) && decision[0].variationKey === 'c',
    }
  }

  function getHeading(): string {
    if (isPageOwner) {
      return t('sharePage.heading.pageOwner');
    }

    if (page.product.name === ProductNames.InMemoryTeam) {
      return t('inMemoryTeam.share.heading', {
        personName: page.occasion?.names.at(0),
      });
    }

    return (
      whenOwnerType(page, {
        isIndividual: () =>
          t('sharePage.heading', { pageOwnerName:  page.owner?.name ?? t('metadata.defaultFundraiserName') }),
        isPeerGroup: () =>
          t('sharePage.heading.team', { pageOwnerName: page.owner.name }),
        isOrganisation: () =>
          t('sharePage.heading.organisation', {
            organisation: page.charity.name,
          }),
      }) ?? ''
    );
  }

  return (
    <ErrorBoundary>
      <div id="sharePage" className={`mb-4 ${styles.section}`}>
        <h1 className={`cp-heading-small mt-0 mb-3 ${styles.sectionHeading}`}>
          {t('sharePage.heading.section')}
        </h1>
        <div className={classNames(styles.subSection)}>
          <CampaignUTMTrackingContext.Provider value={updateCampaignUTMTracking}>
            <SharePage
              heading={getHeading()}
              subheading={t('sharePage.subHeading')}
              showAvatar={false}
            />
          </CampaignUTMTrackingContext.Provider>
        </div>
      </div>
    </ErrorBoundary>
  );
};

