import { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';
import getTextContent from '../../utils/children';
import styles from './DefaultAvatar.module.scss';
import { typography } from '@justgiving/carepack-design-tokens';

type DefaultAvatarProps = {
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'auto';
  className?: string;
  fillColor?: string;
  textColor?: string;
  children?: ReactNode;
};

const colors = [
  'color-red-400',
  'color-blue-400',
  'color-green-400',
  'color-orange-400',
  'color-purple-400',
];

export const DefaultAvatar: FunctionComponent<DefaultAvatarProps> = ({
  size = 'medium',
  className,
  fillColor,
  textColor = 'white',
  children,
}) => {
  const textContent = getTextContent(children);
  const text = textContent && textContent !== '' ? textContent : 'A';
  const _fillColor = colors[text.charCodeAt(0) % colors.length];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(size ? styles[size] : undefined, className)}
    >
      <circle
        style={{ fill: fillColor ?? `var(--${_fillColor})` }}
        cx="50%"
        cy="50%"
        r="50%"
      ></circle>
      <text
        x="50%"
        y="50%"
        fontSize={
          size === 'x-small' ? typography.fontSize02 : typography.fontSize05
        }
        fill={textColor}
        dominantBaseline="central"
        textAnchor="middle"
      >
        {text}
      </text>
    </svg>
  );
};
