export const GalleryIcon = () => (
  <svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.563 19.754a1.94 1.94 0 01-.014.203l.028-.218a1.742 1.742 0 01-.064.269c-.048.146.085-.192.022-.052a1.386 1.386 0 01-.092.167c-.032.052-.124.162.036-.042a1.38 1.38 0 01-.075.085c-.046.05-.227.197-.006.023a1.372 1.372 0 01-.258.156c.23-.11.107-.048.049-.027a1.356 1.356 0 01-.225.053l.211-.03c-.25.034-.513.015-.765.015H2.278c-.1 0-.197-.001-.297-.014l.211.03a1.606 1.606 0 01-.26-.067c-.142-.05.186.088.05.023a1.308 1.308 0 01-.162-.095c-.05-.033-.158-.128.041.037a1.37 1.37 0 01-.083-.077c-.049-.047-.19-.235-.022-.007a1.406 1.406 0 01-.151-.266c.106.237.046.111.026.05a1.46 1.46 0 01-.052-.231l.029.218c-.041-.327-.014-.671-.014-1V9.019c0-.308-.01-.618.002-.926.002-.04.007-.081.012-.121l-.029.218a1.742 1.742 0 01.064-.269c.048-.146-.085.192-.022.052.026-.058.058-.113.092-.167.032-.052.124-.162-.036.042.024-.03.05-.057.075-.085.046-.05.228-.197.007-.023a1.346 1.346 0 01.258-.156c-.23.11-.108.048-.05.027a1.35 1.35 0 01.226-.053l-.212.03c.25-.034.513-.015.765-.015h15.132c.1 0 .198.001.297.014l-.211-.03a1.604 1.604 0 01.26.067c.143.05-.185-.088-.05-.023.057.027.11.06.163.095.05.033.157.128-.042-.037.03.024.056.05.083.077.049.047.191.235.022.007a1.404 1.404 0 01.151.266c-.106-.237-.046-.111-.026-.05.025.075.04.153.052.231l-.028-.218c.04.327.014.671.014 1v10.782c0 .448.362.821.796.821.434 0 .796-.373.796-.82V8.94c0-.413.014-.817-.048-1.226-.13-.855-.9-1.607-1.72-1.74a3.277 3.277 0 00-.536-.043H2.691c-.362 0-.717-.015-1.073.075a2.1 2.1 0 00-1.066.681c-.33.4-.495.846-.545 1.365-.011.108-.006.22-.006.33v11.173c0 .12-.002.241.01.361.092.957.721 1.782 1.644 2.015.34.087.698.066 1.046.066h15.155c.23 0 .458-.018.682-.075.952-.24 1.603-1.18 1.617-2.169a.815.815 0 00-.797-.82c-.438 0-.79.373-.796.82z"></path>
    <path d="M1.657 18.822l2.762-3.142 1.377-1.567H4.67l3.297 2.77.557.467c.171.144.335.24.563.24.21 0 .415-.086.563-.24.284-.292.338-.877 0-1.16l-3.296-2.77-.558-.468c-.17-.143-.335-.24-.563-.24a.77.77 0 00-.563.24l-2.762 3.142L.53 17.661c-.29.331-.318.833 0 1.161.296.305.835.332 1.127 0z"></path>
    <path d="M8.223 19.41l2.32-2.391 3.692-3.806.84-.866h-1.126l3.259 2.647 1.451 1.18c.175.14.333.24.564.24a.79.79 0 00.563-.24c.28-.289.341-.884 0-1.162l-3.26-2.647-1.451-1.179c-.174-.141-.332-.24-.563-.24a.787.787 0 00-.563.24l-2.32 2.392a102802.196 102802.196 0 01-4.532 4.672.847.847 0 000 1.16.787.787 0 001.126 0z"></path>
    <path d="M5.022 4.642h15.724c.092 0 .182.002.273.014l-.211-.03a1.604 1.604 0 01.26.067c.142.049-.186-.088-.05-.023.056.027.11.06.162.095.05.033.158.128-.041-.037.029.024.056.05.083.077.049.047.19.235.022.007a1.394 1.394 0 01.151.266c-.106-.237-.046-.111-.026-.05.024.075.04.153.052.231l-.029-.218c.041.327.014.671.014 1v10.78c0 .448.363.822.797.822.434 0 .796-.374.796-.821V6.01c0-.412.014-.817-.048-1.226-.131-.855-.9-1.606-1.72-1.739A3.25 3.25 0 0020.695 3H5.022c-.434 0-.796.373-.796.82 0 .448.362.822.796.822z"></path>
    <circle cx="9" cy="11" r="2"></circle>
    <defs>
      <clipPath id="clip0"></clipPath>
    </defs>
  </svg>
);
