export const prepareHex = (hex: string): string => {
  let preparedHex = hex;

  if (hex.indexOf('#') === 0) {
    preparedHex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (preparedHex.length === 3) {
    preparedHex =
      preparedHex[0] +
      preparedHex[0] +
      preparedHex[1] +
      preparedHex[1] +
      preparedHex[2] +
      preparedHex[2];
  }
  if (!isHexValid(preparedHex)) {
    // log.warn('Invalid Hex colour code generated. Returning empty string', {
    //   input: hex,
    //   output: preparedHex,
    // });
    return '';
  }

  return preparedHex;
};

const isHexValid = (hex: string): boolean => {
  if (hex.length !== 6) {
    return false;
  }

  let isValid: boolean = true;
  for (const char of hex) {
    const hexFromChar = parseInt(char, 16);
    if (isNaN(hexFromChar)) {
      isValid = false;
    }
  }

  return isValid;
};

export default prepareHex;
