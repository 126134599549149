import { useMemo } from 'react';

export function usePrefersReducesMotion(): boolean | null {
  return useMemo(() => {
    if (typeof window === 'undefined' || !window.matchMedia) {
      return null;
    }

    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }, []);
}
