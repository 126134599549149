'use client';

import { FitnessCard } from '../FitnessCard/FitnessCard';
import { useFitnessAppsState } from '../Providers/FitnessProvider';

export const FitbitConnectionStatus = () => {
  const { fitbit, loading } = useFitnessAppsState();

  if (loading) {
    return null;
  }

  return (
    <FitnessCard.ConnectionStatus
      isConnected={fitbit.isConnected}
      isSubscribedToActivities={fitbit.isSubscribedToActivities}
    />
  );
};
