'use client';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './ContinueReading.module.scss';
import { useTranslation } from 'react-i18next';

interface ContinueReadingProps {
  maxHeight?: number;
}

export const ContinueReading = ({
  children,
}: PropsWithChildren<ContinueReadingProps>) => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isContentRendered, setIsContentRendered] = useState(false);
  const MAX_HEIGHT = 320;

  useEffect(() => {
    if (divRef.current && divRef.current.offsetHeight < MAX_HEIGHT) {
      setIsCollapsed(false);
    }
    setIsContentRendered(true);
  }, [divRef.current?.offsetHeight]);

  return (
    <div>
      <div
        ref={divRef}
        style={{ maxHeight: isCollapsed ? `${MAX_HEIGHT}px` : 'none' }}
        className={styles.content}
      >
        {children}
        {isCollapsed && <div className={styles.gradient}></div>}
      </div>

      {isCollapsed && isContentRendered && (
        <a className="cp-text-link" onClick={() => setIsCollapsed(false)}>
          {t('continueReading.label')}
        </a>
      )}
    </div>
  );
};
