'use client';

import { Media, OwnerType, PageStatus, PointOfView, Story } from '../../lib/graphql';
import React, { PropsWithChildren } from 'react';

import { useNonNullableContext } from '../../hooks/useNonNullableContext';

export interface Page {
  id: string;
  slug: string;
  logo?: string | null;
  url: string;
  title: string;
  subtitle?: string;
  status: PageStatus;
  endDate: Date | null;
  cover: {
    type: Exclude<Media['__typename'], undefined>;
    url: string;
  } | null;
  owner: {
    id: string;
    name: string;
    avatar?: string | null;
  };
  product: {
    name: string;
    ownerType: OwnerType;
    allowedPointsOfView: PointOfView[];
  };
  charity: {
    name: string;
    countryCode: string | null;
    landingPageUrl: string;
    externalId: number;
    logo?: string | null;
  };
  currencyCode: string;
  targetAmount?: number;
  donationSummary: {
    totalAmount: number;
    aggregatedDonationsCount: number;
  };
  donationCount: number;
  branding: {
    primaryColor?: string | null;
  };
  event?: {
    id: string;
    name: string;
    externalId: number;
    endDate?: Date;
    startFundraisingUrl?: string | null;
  };
  campaign?: {
    id: string;
  };
  fundraisers: {
    totalCount: number;
    items: {
      id: string;
      ownerAvatarUrl?: string | null;
    }[];
  };
  startFundraisingUrl?: string | null;
  fundraisingSettings: {
    isEnabled?: boolean | null;
  };
  occasion?: {
    names: string[];
  };
  story?: Story | null;
  summary?: string | null;
}

const PageContext = React.createContext<Page | null>(null);
PageContext.displayName = 'PageContext';

interface PageProviderProps extends PropsWithChildren {
  page: Page;
}

export const PageProvider: React.FC<PageProviderProps> = ({
  children,
  page,
}) => {
  return <PageContext.Provider value={page}>{children}</PageContext.Provider>;
};

export const usePage = () => useNonNullableContext(PageContext);
