import { useCallback, useEffect, useRef } from 'react';

export const useAnimationFrame = (
  callback: (elapsedTime: number) => boolean,
) => {
  const requestIdRef = useRef<number>();
  const startTimeRef = useRef<number>();

  const animate = useCallback(
    (time: number) => {
      startTimeRef.current ??= time;
      const elapsedTime = time - startTimeRef.current;
      const isComplete = callback(elapsedTime);

      if (!isComplete) {
        requestIdRef.current = requestAnimationFrame(animate);
      }
    },
    [callback],
  );

  useEffect(() => {
    requestIdRef.current = requestAnimationFrame(animate);

    return () => {
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current);
      }
    };
  }, [animate]);
};
