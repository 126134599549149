export const EnvelopeIcon = () => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.1033 34.0825L46.8403 48.2578H24.1597L2.89673 34.0825L14.237 26.286H56.763L68.1033 34.0825Z"
        fill="#EDD9FF"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.763 48.1416V5.73177C56.763 4.97986 56.4643 4.25875 55.9326 3.72707C55.4009 3.19539 54.6798 2.8967 53.9279 2.8967H17.072C16.3201 2.8967 15.599 3.19539 15.0673 3.72707C14.5356 4.25875 14.2369 4.97986 14.2369 5.73177V48.1416H56.763Z"
        fill="#EDD9FF"
      />
      <path
        d="M56.196 4.03073C55.9319 3.67862 55.5895 3.39284 55.1958 3.196C54.8021 2.99917 54.368 2.8967 53.9279 2.8967H17.072C16.3201 2.8967 15.599 3.19539 15.0673 3.72707C14.5356 4.25875 14.2369 4.97986 14.2369 5.73177V45.9898L56.196 4.03073Z"
        fill="white"
      />
      <path
        d="M56.763 48.1416V5.73177C56.763 4.97986 56.4643 4.25875 55.9326 3.72707C55.4009 3.19539 54.6798 2.8967 53.9279 2.8967H17.072C16.3201 2.8967 15.599 3.19539 15.0673 3.72707C14.5356 4.25875 14.2369 4.97986 14.2369 5.73177V48.1416"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6854 45.0258L68.1033 34.0825V63.8507C68.1033 64.9786 67.6553 66.0602 66.8578 66.8577C66.0602 67.6553 64.9786 68.1033 63.8507 68.1033H7.14933C6.02147 68.1033 4.93981 67.6553 4.14229 66.8577C3.34477 66.0602 2.89673 64.9786 2.89673 63.8507V34.0825L19.9242 45.4227"
        fill="white"
      />
      <path
        d="M51.6854 45.0258L68.1033 34.0825V63.8507C68.1033 64.9786 67.6553 66.0602 66.8578 66.8577C66.0602 67.6553 64.9786 68.1033 63.8507 68.1033H7.14933C6.02147 68.1033 4.93981 67.6553 4.14229 66.8577C3.34477 66.0602 2.89673 64.9786 2.89673 63.8507V34.0825L19.9242 45.4227"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4019 59.5981L25.5772 48.2578H45.4227L59.598 59.5981"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3247 14.237H34.0825"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3247 19.9071H34.0825"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3247 25.5773H28.4124"
        stroke="#252B33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
