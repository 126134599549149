import { addDays, formatToYYYYMMDD } from '../../../utils/date';
import { isMobileDevice } from '../../../utils/mobile';
import { CalendarType } from './CalendarType';
import { getRandomKey } from './getRandomKey';

export interface AddToCalendarEvent {
  title: string;
  description: string;
  location: string;
  startTime: Date;
  endTime: Date;
}

const buildEventUrl = (event: AddToCalendarEvent, type: CalendarType) => {
  let calendarUrl = '';
  const formattedStartTime = formatToYYYYMMDD(event.startTime);
  const formattedEndTime = formatToYYYYMMDD(addDays(event.endTime, 1));
  const encodedLocation = encodeURIComponent(event.location);
  const encodedTitle = encodeURIComponent(event.title);
  const encodedDescription = encodeURIComponent(event.description);

  switch (type) {
    case CalendarType.Google:
      calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formattedStartTime}/${formattedEndTime}&location=${encodedLocation}&text=${encodedTitle}&details=${encodedDescription}&transparency=transparent`;
      break;
    case CalendarType.Yahoo:
      calendarUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodedTitle}&dur=allday&st=${formattedStartTime}&et=${formattedEndTime}&desc=${encodedDescription}&in_loc=${encodedLocation}`;
      break;
    case CalendarType.OutlookWeb:
      calendarUrl = `https://outlook.live.com/owa/?rru=addevent&startdt=${formattedStartTime}&enddt=${formattedEndTime}&subject=${encodedTitle}&location=${encodedLocation}&body=${encodedDescription}&allday=false&uid=${getRandomKey()}&path=/calendar/view/Month`;
      break;
    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'DTSTART;VALUE=DATE:' + formattedStartTime,
        'DTEND;VALUE=DATE:' + formattedEndTime,
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        'LOCATION:' + event.location,
        ...(type === CalendarType.Outlook
          ? ['X-MICROSOFT-CDO-BUSYSTATUS:FREE']
          : []),
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      if (isMobileDevice()) {
        calendarUrl = encodeURI(
          'data:text/calendar;charset=utf8,' + calendarUrl,
        );
      }
  }

  return calendarUrl;
};

export const addToCalendarHandler = (
  event: AddToCalendarEvent,
  type: CalendarType,
) => {
  const url = buildEventUrl(event, type);

  if (
    !isMobileDevice() &&
    (url.startsWith('data') || url.startsWith('BEGIN'))
  ) {
    const filename = 'reminder.ics';
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(url, '_blank');
  }
};
