'use client';

import { FunctionComponent } from 'react';
import { Stack } from '../Stack/Stack';
import { usePage } from '../Providers/PageProvider';
import { usePreview } from '../Providers/PreviewProvider';
import { useTranslation } from 'react-i18next';
import styles from './StartFundraising.module.scss';
import classNames from 'classnames';
import Link from 'next/link';
import { isCharityPage } from '../../utils/pageType';

export const StartFundraising: FunctionComponent = () => {
  const { status, fundraisingSettings } = usePage();

  if (!fundraisingSettings.isEnabled) return null;

  switch (status) {
    case 'SCHEDULED':
    case 'DRAFT':
    case 'ACTIVE': {
      return (
        <Stack direction="column">
          <Active />
        </Stack>
      );
    }
    case 'COMPLETED': {
      return (
        <Stack direction="column">
          <Completed />
        </Stack>
      );
    }
    default:
      return null;
  }
};

const Active: FunctionComponent = () => {
  const { t } = useTranslation();
  const { startFundraisingUrl, product } = usePage();
  const { isPreview } = usePreview();

  if (!startFundraisingUrl) return;

  const className = classNames(
    isCharityPage(product.name) ? 'cp-btn-primary' : styles.cpBtnTertiary,
    'cp-btn',
  );

  return (
    <>
      <h1 className="cp-heading-small m-0">{t('startFundraising.title')}</h1>
      <p className="cp-body-medium m-0">{t('startFundraising.copy.active')}</p>
      {isPreview ? (
        <button className={className}>
          {t('startFundraising.cta.active')}
        </button>
      ) : (
        <Link className={className} href={startFundraisingUrl}>
          {t('startFundraising.cta.active')}
        </Link>
      )}
    </>
  );
};

const Completed: FunctionComponent = () => {
  const { t } = useTranslation();
  const { charity } = usePage();

  if (!charity?.landingPageUrl) return null;

  return (
    <>
      <h1 className="cp-heading-small m-0">{t('startFundraising.title')}</h1>
      <p className="cp-body-medium m-0">{t('startFundraising.copy.expired')}</p>
      <a className="cp-btn cp-btn-link-secondary" href={charity.landingPageUrl}>
        {t('startFundraising.cta.expired')}
      </a>
    </>
  );
};
