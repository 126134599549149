export const ShareIcon = () => (
  <svg
    viewBox="0 0 25 25"
    height={25}
    width={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.038 2.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0z"
      fill="#737373"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.624 2.293a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414zM3.331 11a1 1 0 011-1h3a1 1 0 110 2h-2v7h14v-7h-2a1 1 0 110-2h3a1 1 0 011 1v9a1 1 0 01-1 1h-16a1 1 0 01-1-1v-9z"
      fill="#737373"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.331 2a1 1 0 011 1v10a1 1 0 01-2 0V3a1 1 0 011-1z"
      fill="#737373"
    ></path>
  </svg>
);
