'use client';

import { PropsWithChildren, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { DonateButton } from '../DonateButton/DonateButton';
import { Page } from '../../lib/pageData';
import { DonateToGroupModal } from '../DonateToGroupModal/DonateToGroupModal';

type DonateToGroupMemberButtonProps = {
  id?: string;
  className?: string;
  colorVariant?: 'brand' | 'primary';
  page: Page;
} & PropsWithChildren;

export const DonateToGroupMemberButton: React.FC<
  DonateToGroupMemberButtonProps
> = ({ id, page }) => {
  const { t } = useTranslation();

  const [donateToGroupModalOpen, setDonateToGroupModalOpen] = useState(false);

  function handleClick() {
    setDonateToGroupModalOpen(true);
  }

  return (
    <>
      <DonateButton onClick={handleClick} id={id} as="button">
        {t('giveNow')}
      </DonateButton>
      {donateToGroupModalOpen && (
        <DonateToGroupModal
          page={page}
          open
          onClose={() => setDonateToGroupModalOpen(false)}
        />
      )}
    </>
  );
};
