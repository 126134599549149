import { createContext, Dispatch, SetStateAction } from 'react';
import { SupporterDetailsProps } from '../SupporterDetails/SupporterDetails';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { Page } from '../../lib/pageData';

type SupportersListContextProps = {
  page: Page;
  supporters: ({ id: string } & SupporterDetailsProps)[];
  supporterCount: number;
  fetchMore: (doSort?: boolean, isFirstFetch?: boolean) => void;
  nextPageCursor?: string | null;
  isLoading: boolean;
  hasError: boolean;
  showTopDonations: boolean;
  setShowTopDonations: Dispatch<SetStateAction<boolean>>;
  showSkeleton: boolean;
};

export const SupportersListContext =
  createContext<SupportersListContextProps | null>(null);

export const useSupportersListContext = () =>
  useNonNullableContext(SupportersListContext);
