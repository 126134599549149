'use client';
import React from 'react';
import { EventParentPage } from '../../utils/relationships';
import { EventReminderModal } from './EventReminderModal/EventReminderModal';
import { EventReminderButton } from './EventReminderButton/EventReminderButton';
import { getDateWithoutHours } from '../../utils/date';

interface EventProps {
  event: EventParentPage;
}

export const EventReminder: React.FC<EventProps> = ({ event }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const launchDate = event.launchDate;

  if (!launchDate) return null;

  const eventStartDateHasPassed =
    getDateWithoutHours(new Date(launchDate)).getTime() <
    getDateWithoutHours(new Date()).getTime();

  if (eventStartDateHasPassed) return null;

  return (
    <>
      <EventReminderModal open={isOpen} setOpen={setIsOpen} event={event} />
      <EventReminderButton setOpen={setIsOpen} />
    </>
  );
};
