import { Context, useContext } from 'react';

export function useNonNullableContext<T>(context: Context<T>) {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error(
      `${
        context.displayName
      } is null, remember to wrap the component with the appropriate provider for context ${
        context.displayName ?? 'unknown'
      }`,
    );
  }

  return ctx;
}
