import React, { PropsWithChildren } from 'react';

import { Stack } from '../Stack/Stack';
import styles from './IconLabel.module.scss';

const IconLabel = ({ children }: PropsWithChildren) => (
  <Stack gap="sizeSpacing03" direction="row" alignItems="center">
    {children}
  </Stack>
);

export default IconLabel;

const Icon: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.icon}>{children}</div>
);

IconLabel.Icon = Icon;

const Label: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="cp-body-medium text-color-grey-400">{children}</div>
);

IconLabel.Label = Label;
