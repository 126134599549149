'use client';

import { ProductNames } from '../constants/ProductNames';
import clientConfig from '../client/config';
import { usePage } from '../components/Providers/PageProvider';
import { whenOwnerType } from '../utils/ownerType';

export function useEditUrl() {
  const page = usePage();

  return whenOwnerType(page, {
    isIndividual: () => {
      if (page?.product.name === ProductNames.ProxyFundraising) {
        const slug = page?.slug?.toLowerCase()?.startsWith('fundraising/')
          ? page?.slug
          : 'fundraising/' + page?.slug;
        return `${clientConfig.justGivingRootUrl}/${slug}/admin/edit`;
      }

      if (page?.product.name === ProductNames.Crowdfunding) {
        const slug = page?.slug?.toLowerCase()?.startsWith('crowdfunding/')
          ? page?.slug
          : 'crowdfunding/' + page?.slug;
        return `${clientConfig.justGivingRootUrl}/${slug}/admin`;
      }

      return `${clientConfig.justGivingRootUrl}/${page.slug}/admin/edit`;
    },
    isPeerGroup: () =>
      `${clientConfig.justGivingRootUrl}/teams-creation/manage/${page.id}/edit-team`,
    isOrganisation: () =>
      `${clientConfig.justGivingRootUrl}/create-page/campaign/${page.id}/landing-page`,
  });
}
