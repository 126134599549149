import styles from './skeleton.module.scss';

type Props = {
    height: number;
    width: number;
    circle?: boolean;
    borderRadius?: number;
}

export const Skeleton:React.FC<Props> = ({ height, width, circle, borderRadius }) => {

    // if circle is true, take Math.min(height,width)
    const stylesObj = {
        height: circle ? `${Math.min(height, width)}rem` : `${height}rem`,
        width: circle ? `${Math.min(height, width)}rem` : `${width}rem`,
        borderRadius: circle ? `50%` : `${borderRadius}px`,
    }
    return (
        <div className={styles.animated} style={stylesObj} />
    );
};