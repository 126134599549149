import { Children, ReactNode } from 'react';

export default function getTextContent(children: ReactNode) {
  const text = Children.map(children, (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      return child;
    }
    return '';
  })?.join('');

  return text;
}
