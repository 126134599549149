import { formatCurrency } from '../../utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import styles from './DonationPromptTile.module.scss';
import { DonationPromptType } from '../DonationPrompts/helper/donationPromptHelper';
import clientConfig from '../../client/config';

export type DonationPromptTileProps = {
  amount: number;
  description: string;
  type: DonationPromptType;
  charityId: number;
  currency?: string;
};

export function DonationPromptTile({
  amount,
  description,
  charityId,
  currency = "GBP",
  type,
}: DonationPromptTileProps) {
  const { i18n, t } = useTranslation();

  const newDonationLink = `${clientConfig.linkServiceUrl}/v1/charity/donate/charityId/${charityId}?amount=${amount}&Isrecurring=${type===DonationPromptType.Monthly}&currency=${currency}`

  return (
    <div className={styles.tile}>
      <div className={styles.amount}>
        <a href={newDonationLink} className="cp-text-link cp-heading-large">
          {amount &&
            formatCurrency(amount, currency, i18n.language, {
              maximumFractionDigits: amount % 1 === 0 ? 0 : 2,
            })}
        </a>
        {type === DonationPromptType.Monthly ? (
          <p className={styles.type}>{t('monthly')}</p>
        ) : null}
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};
