'use client';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EventReminderButton: React.FC<EventProps> = ({ setOpen }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="cp-text-link btn-unstyled"
      onClick={() => setOpen(true)}
    >
      {t('eventReminder.setReminder')}
    </button>
  );
};
