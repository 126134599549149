import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface CardProps extends PropsWithChildren {
  borderRadius?: number;
  className?: string;
}

export const Card = ({ children, borderRadius, className }: CardProps) => (
  <div className={classNames(styles.card, className)} style={{ borderRadius }}>
    {children}
  </div>
);

const Body: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.body}>{children}</div>;
};

Card.Body = Body;
