'use client';

import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { Auth } from '@justgiving/auth';
import { ProductNames } from '../../constants/ProductNames';
import { PropsWithChildren } from 'react';
import clientConfig from '../../client/config';
import { isMobileDevice } from '../../utils/mobile';
import { usePage } from './PageProvider';
import { v4 } from 'uuid';
import { useAuth } from './AuthProvider';

export const optimizelyClient = createInstance({
  sdkKey: clientConfig.optimizelyFullstackKey,
  eventBatchSize: 10,
  eventFlushInterval: 300,
});

export const ExperimentProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { charity, campaign, product: { name: productName } } = usePage();
  const auth = useAuth();

  const getDeviceType = () => {
    if (typeof window !== "undefined") return isMobileDevice() ? 'Mobile' : 'Desktop';
    return '';
  };

  const getCauseType = () => {
    if (productName === ProductNames.Fundraising) {
      return campaign ? 'DonationCampaignFRP' : 'DonationCharityFRP'
    }
    // TODO: Add logic for additional causeType values as required
    return '';
  }

  const attributes = {
    deviceType: getDeviceType(),
    charityId: charity.externalId.toString(),
    marketCode: charity.countryCode,
    causeType: getCauseType()
  };

  const cookie = typeof document !== "undefined"
    && document?.cookie?.match('(^|;)\\s*' + clientConfig.analyticsCookieName + '\\s*=\\s*([^;]+)');
  const analyticsCookie = cookie ? cookie.pop() : '';

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{
        id: getUserId(auth?.getAccessToken()?.guid, analyticsCookie as string, auth),
        attributes
      }}
    >
      {children}
    </OptimizelyProvider>
  );
};

function getUserId(userId?: string, analyticsCookie?: string, auth?: Auth | null): string {
  const domain = 'domain=.justgiving.com;';
  const path = `path=/;`;
  const maxAge = `max-age=${60 * 60 * 24 * 365 * 10};`; // 10 Years
  let id;

  if (analyticsCookie) {
    return decodeURIComponent(analyticsCookie)?.split('logged_out_guid=')[1];
  }
  else {
    id = userId ?? v4();
    if (typeof document !== "undefined") {
      document.cookie = `${clientConfig.analyticsCookieName
        }=logged_in_guid=${getLoggedInGuid(
          auth
        )}&logged_out_guid=${id};${domain}${path}${maxAge}`;
    }
  }
  return id as string;
}

function getLoggedInGuid(auth: Auth | null | undefined) {
  if (auth?.isUserLoggedIn()) {
    const userData = auth?.getAccessToken();

    return userData ? userData.guid : '00000000-0000-0000-0000-000000000000';
  }
  return '00000000-0000-0000-0000-000000000000';
}