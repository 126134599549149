import { prepareHex } from './prepareHex';

export const isLightColor = (hex: string): boolean => {
  const preparedHex = prepareHex(hex);
  const red: number = parseInt(preparedHex.slice(0, 2), 16);
  const green: number = parseInt(preparedHex.slice(2, 4), 16);
  const blue: number = parseInt(preparedHex.slice(4, 6), 16);

  // Logic from http://stackoverflow.com/a/3943023/112731
  // and https://github.com/JustGiving/GG.Web.Fundraising/blob/master/GG.Web.Fundraising/Views/Fundraising/_theme-styles.cshtml
  const BRIGHTNESS_THRESHOLD = 186;
  return red * 0.299 + green * 0.587 + blue * 0.114 > BRIGHTNESS_THRESHOLD
    ? true
    : false;
};

export default isLightColor;
