import React, { FunctionComponent } from 'react';

export const RideIcon: FunctionComponent = () => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 4.5C16.6 4.5 17.5 3.6 17.5 2.5C17.5 1.4 16.6 0.5 15.5 0.5C14.4 0.5 13.5 1.4 13.5 2.5C13.5 3.6 14.4 4.5 15.5 4.5ZM5 11C2.2 11 0 13.2 0 16C0 18.8 2.2 21 5 21C7.8 21 10 18.8 10 16C10 13.2 7.8 11 5 11ZM5 19.5C3.1 19.5 1.5 17.9 1.5 16C1.5 14.1 3.1 12.5 5 12.5C6.9 12.5 8.5 14.1 8.5 16C8.5 17.9 6.9 19.5 5 19.5ZM10.8 9.5L13.2 7.1L14 7.9C15.3 9.2 17 10 19.1 10V8C17.6 8 16.4 7.4 15.5 6.5L13.6 4.6C13.1 4.2 12.6 4 12 4C11.4 4 10.9 4.2 10.6 4.6L7.8 7.4C7.4 7.8 7.2 8.3 7.2 8.8C7.2 9.4 7.4 9.9 7.8 10.2L11 13V18H13V11.8L10.8 9.5ZM19 11C16.2 11 14 13.2 14 16C14 18.8 16.2 21 19 21C21.8 21 24 18.8 24 16C24 13.2 21.8 11 19 11ZM19 19.5C17.1 19.5 15.5 17.9 15.5 16C15.5 14.1 17.1 12.5 19 12.5C20.9 12.5 22.5 14.1 22.5 16C22.5 17.9 20.9 19.5 19 19.5Z"
        fill="#999999"
      />
    </svg>
  );
};
