'use client';

import { PageData } from '../../lib/pageData';
import { PropsWithChildren } from 'react';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { PageTotal } from '../../lib/graphql';

type PageOwnerOrFitnessDataOnlyProps = {
  fitnessSettings: PageData['page']['stravaSettings'];
  fitnessTotals?: PageData['page']['totals'];
} & PropsWithChildren;

// This component is needed as the auth state can only be accessed client-side,
// but the StravaCard component needs to be rendered as a server component
export const PageOwnerOrFitnessDataOnly = ({
  children,
  fitnessSettings,
  fitnessTotals,
}: PageOwnerOrFitnessDataOnlyProps) => {
  const isPageOwner = useIsPageOwner();

  if (
    !isPageOwner &&
    (!hasTimelineAmounts(fitnessTotals?.timeline ?? []) ||
      !fitnessSettings?.settings?.showTotaliser)
  ) {
    return null;
  }

  return <>{children}</>;
};

function hasTimelineAmounts(timeline: PageTotal[]) {
  return (
    timeline.length !== 0 &&
    timeline.some((entry) => entry.amounts.some((amount) => amount.value > 0))
  );
}
