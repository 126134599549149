'use client';

import { useEffect, useRef, useState } from 'react';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LoadingIndicator } from '@justgiving/page-components';
import dynamic from 'next/dynamic';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const TimelineSection = dynamic(
  async () => (await import('./TimelineSection')).TimelineSection,
  {
    loading: () => <LoadingIndicator className="m-5" />,
  },
);

export const TimelineSectionLoader: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isSmallDevice = useMediaQuery('screen and (max-width: 767px)');

  const intersection = useIntersectionObserver({
    target: ref,
    // load later on bigger devices as the preceeding sections are less vertically stretched out on those,
    // making it more likely that the timeline will be loaded right away on page load if rootMargin is bigger,
    // also in general larger devices should have be able to load the timeline faster
    rootMargin: isSmallDevice ? '50%' : '25%',
  });
  const [showTimeline, setShowTimeline] = useState(false);

  const isInView = intersection?.isIntersecting ?? false;

  useEffect(() => {
    if (isInView) {
      setShowTimeline(true);
    }
  }, [isInView]);

  return (
    <>
      <div ref={ref} id="timeline-section-marker" />
      {showTimeline && (
        <ErrorBoundary>
          <TimelineSection />
        </ErrorBoundary>
      )}
    </>
  );
};
