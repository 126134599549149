'use client';

import { Badge, BadgeProps } from '../Badge/Badge';

import { useTranslation } from 'react-i18next';

type ConnectionStatusProps = {
  isConnected: boolean;
  isSubscribedToActivities: boolean;
};

type ConnectionStatus = 'connected' | 'paused' | 'disconnected';

export const ConnectionStatus = ({
  isConnected,
  isSubscribedToActivities,
}: ConnectionStatusProps) => {
  const { t } = useTranslation();

  const getStatus = (): ConnectionStatus => {
    if (isConnected && !isSubscribedToActivities) {
      return 'paused';
    }

    if (isConnected) {
      return 'connected';
    }

    return 'disconnected';
  };

  const colorSchemeMap: Record<ConnectionStatus, BadgeProps['colorScheme']> = {
    connected: 'green',
    disconnected: 'red',
    paused: 'orange',
  };

  const status = getStatus();

  return (
    <Badge colorScheme={colorSchemeMap[status]}>{t(`fitness.${status}`)}</Badge>
  );
};
