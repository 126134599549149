import { RefObject, useCallback, useEffect, useState } from 'react';

export type UseIntersectionObserverOptions = {
  target?: RefObject<Element>;
  rootMargin?: string;
};

export function useIntersectionObserver({
  target,
  rootMargin,
}: UseIntersectionObserverOptions) {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const callback: IntersectionObserverCallback = useCallback((entries) => {
    setEntry(entries[0]);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, { rootMargin });
    if (!target?.current) {
      return;
    }

    observer.observe(target.current);
    return () => observer.disconnect();
  }, [target, rootMargin, callback]);

  return entry;
}
