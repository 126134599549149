import { SVGProps } from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    {...props}
  >
    <path d="M19.8 2.908c-.185-.185-.554-.185-.738 0l-7.108 7.2L4.846 3c-.184-.185-.554-.185-.738 0l-1.2 1.2c-.185.185-.185.554 0 .738l7.107 7.108-7.107 7.108c-.185.184-.185.554 0 .738l1.2 1.2c.184.185.554.185.738 0l7.108-7.107 7.108 7.107c.184.185.553.185.738 0l1.2-1.2c.185-.184.185-.554 0-.738l-7.108-7.108L21 4.938c.277-.184.277-.553 0-.83l-1.2-1.2z" />
  </svg>
);
