import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react';

interface Carousel {
  activeTile: number;
  selectTile: (index: number) => void;
  loopAround: boolean;
  items: ReactNode[];
}
const CarouselContext = createContext<Carousel | null>(null);

type CarouselContextProps = PropsWithChildren & {
  loopAround?: boolean;
  className: string;
  items: ReactNode[];
};

export function Carousel({
  loopAround,
  className,
  items,
  children,
}: CarouselContextProps) {
  const [activeTile, setActiveTile] = useState(0);
  const itemCount = items.length;

  const setActiveTileWrapper = (newIndex: number) => {
    if (loopAround) {
      const valueToUse = newIndex < 0 ? itemCount - 1 : newIndex % itemCount;
      setActiveTile(valueToUse);
    } else {
      const valueToUse = Math.max(0, Math.min(itemCount - 1, newIndex));
      setActiveTile(valueToUse);
    }
  };

  const id = useId();
  
  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        event.stopPropagation();
        setActiveTileWrapper(activeTile - 1);
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault();
        event.stopPropagation();
        setActiveTileWrapper(activeTile + 1);
      }
    }

    document
      .getElementById(id)
      ?.addEventListener('keydown', handleKeyPress);

    return () => {
      document
        .getElementById(id)
        ?.removeEventListener('keydown', handleKeyPress);
    };
  });

  return (
    <CarouselContext.Provider
      value={{
        activeTile: activeTile,
        selectTile: setActiveTileWrapper,
        loopAround: loopAround ?? false,
        items: items,
      }}
    >
      <div className={className} tabIndex={0} id={id}>
        {children}
      </div>
    </CarouselContext.Provider>
  );
}

export function useCarouselContext() {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error(
      'Tried to access CarouselContext but the parent is not wrapped with CarouselContextProvider',
    );
  }

  return context;
}
