import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { CloseIcon } from '../Icons';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { layout } from '@justgiving/carepack-design-tokens';
import styles from './Modal.module.scss';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { useTranslation } from 'react-i18next';

interface ModalProps extends PropsWithChildren {
  open?: boolean;
  closeOnBackdropClick?: boolean;
  className?: string;
  onClose: () => void;
  onClick?: (event: React.MouseEvent) => void;
  ariaLabel?: string;
}

type TModalContext = {
  onClose: () => void;
};

const ModalContext = React.createContext<TModalContext | null>(null);

export const Modal = ({
  children,
  open,
  closeOnBackdropClick = true,
  className,
  onClose,
  onClick,
  ariaLabel
}: ModalProps) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function toggle() {
      if (!hasSupport() && ref.current) {
        const polyfill = await import('dialog-polyfill');
        polyfill.default.registerDialog(ref.current);
      }

      if (open && mounted) {
        if (!ref.current?.open) {
          ref.current?.showModal();
        }

        if (closeOnBackdropClick) {
          ref.current?.addEventListener('click', (event) => {
            if (
              event.target instanceof Element &&
              event.target.nodeName === 'DIALOG'
            ) {
              ref.current?.close();
            }
          });
        }
      } else {
        ref.current?.close();
      }
    }

    toggle().catch(console.error);
  }, [open, closeOnBackdropClick, mounted]);

  function handleClose() {
    onClose();
    ref.current?.close();
  }

  if (!open || !mounted) {
    return null;
  }

  return createPortal(
    <ModalContext.Provider value={{ onClose }}>
      <RemoveScroll>
        <dialog
          ref={ref}
          onClose={handleClose}
          className={classNames(styles.modal, className, {
            [styles.polyfilled]: !hasSupport(),
          })}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          {children}
        </dialog>
      </RemoveScroll>
    </ModalContext.Provider>,
    document.getElementById('modal-dialog-slot') ?? document.body,
  );
};

interface ContentProps extends PropsWithChildren {
  className?: string;
  padding?: keyof typeof layout;
}

const Content = ({ children, className, padding = 'sizeSpacing05' }: ContentProps) => {
  return (
    <div
      className={classNames(styles.content, className)}
      style={
        {
          '--modal-padding': layout[padding],
        } as any
      }
    >
      {children}
    </div>
  );
};

Modal.Content = Content;

interface CloseButtonProps {
  className?: string;
}

const CloseButton = ({ className }: CloseButtonProps) => {
  const { onClose } = useNonNullableContext(ModalContext);
  const { t } = useTranslation();

  return (
    <button
      className={classNames(
        'cp-btn',
        'cp-btn-ghost-small',
        styles.closeButton,
        className,
      )}
      aria-label={t('close') ?? 'Close'}
      onClick={(e) => {
        onClose();
        e.stopPropagation();
      }}
    >
      <CloseIcon className={styles.closeIcon} />
    </button>
  );
};

Modal.CloseButton = CloseButton;

type BodyProps = {
  className?: string;
  applyMaxWidth?: boolean;
} & PropsWithChildren;

const Body = ({ children, className, applyMaxWidth }: BodyProps) => {
  return (
    <div
      className={classNames(styles.body, className, {
        [styles.bodyMaxWidth]: applyMaxWidth,
      })}
    >
      {children}
    </div>
  );
};

Modal.Body = Body;

type FooterProps = {
  className?: string;
  applyMaxWidth?: boolean;
} & PropsWithChildren;

const Footer = ({ children, className, applyMaxWidth }: FooterProps) => {
  return (
    <div
      className={classNames(className, {
        [styles.footerMaxWidth]: applyMaxWidth,
      })}
    >
      {children}
    </div>
  );
};

Modal.Footer = Footer;

const hasSupport = () => {
  if (typeof window === 'undefined') return false;
  return !!window.HTMLDialogElement;
};
