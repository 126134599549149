import Image from 'next/image';

import styles from './AverageDonation.module.scss';
import loveHeartDonate from '../../../public/love-heart-donate.svg';

import { formatCurrencySymbol } from '../../utils/currency';
import { AcceptedCurrencyCode } from '../../lib/graphql/types';
import { Page } from '../../lib/pageData';
import { DonatePopupButton } from '../DonatePopupButton/DonatePopupButton';
import { useTranslation } from 'react-i18next';

type AverageDonationProps = {
  page: Page;
};

export const AverageDonation = ({ page }: AverageDonationProps) => {
  const { t } = useTranslation();

  const donationSummary = page.donationSummary;
  const donationCount = donationSummary?.donationCount ?? 0;
  const totalAmount = donationSummary?.onlineAmount?.value ?? 0;
  const currency = donationSummary?.onlineAmount
    ?.currencyCode as AcceptedCurrencyCode;
  const averageDonationValue = totalAmount / 100 / donationCount;

  if (donationCount < 5) {
    return null;
  }

  return (
    <div className={styles.averageDonationBanner} data-testid="averageDonation">
      <Image className={styles.donateIcon} src={loveHeartDonate} alt="" />
      {t('averageDonation')}{' '}
      <span className={styles.averageDonationAmount}>
        {formatCurrencySymbol(currency)}
        {averageDonationValue.toFixed(0)}
      </span>
      . {page.status === 'ACTIVE' && <DonatePopupButton variant="link" />}
    </div>
  );
};
