'use client';

import IconLabel from '../IconLabel/IconLabel';
import { IconPeopleGroup } from '@justgiving/carepack-icons';
import Link from 'next/link';
import clientConfig from '../../client/config';
import { useIsPageOwner } from '../../hooks/useIsPageOwner';
import { useTranslation } from 'react-i18next';

type InactiveTeamProps = {
  teamId: string;
  teamTitle: string;
};

export const InactiveTeam = ({ teamId, teamTitle }: InactiveTeamProps) => {
  const { t } = useTranslation();
  const isPageOwner = useIsPageOwner();

  if (!isPageOwner) {
    return null;
  }

  return (
    <>
      <IconLabel>
        <IconLabel.Icon>
          <IconPeopleGroup />
        </IconLabel.Icon>
        <IconLabel.Label>
          <Link
            href={`${clientConfig.justGivingRootUrl}/teams-creation/create/${teamId}/team-name`}
            className="cp-text-link"
          >
            {t('team.finishCreation')}
          </Link>
          <span>: {teamTitle}</span>
        </IconLabel.Label>
      </IconLabel>
    </>
  );
};
