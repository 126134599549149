'use client';

import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { isLightColor } from '../../utils/colors';
import styles from './DonateButton.module.scss';
import { useBuildDonateUrlWithQueryString } from '../../hooks/useBuildDonateUrlWithQueryString';
import { usePage } from '../Providers/PageProvider';
import { useTranslation } from 'react-i18next';

type DonateButtonProps = {
  id?: string;
  className?: string;
  colorVariant?: 'brand' | 'primary';
  variant?: 'link' | 'button';
  as?: React.ElementType;
  onClick(event: React.MouseEvent): void;
} & PropsWithChildren;

export const DonateButton: React.FC<DonateButtonProps> = ({
  id,
  children,
  className,
  colorVariant = 'primary',
  variant = 'button',
  as = 'a',
  onClick,
}) => {
  const { t } = useTranslation();
  const { status, branding } = usePage();
  const isDonateActive = status === 'ACTIVE';
  const isPrimaryBrandColorLight = branding.primaryColor
    ? isLightColor(branding.primaryColor)
    : false;
  const isButton = as === 'button';
  const Element = as;
  const { buildDonateUrlWithQueryString } = useBuildDonateUrlWithQueryString();

  if (!isDonateActive) {
    return (
      <>
        <div className={classNames('cp-btn w-full', styles.disabled)}>
          <span aria-hidden>{children}</span>
          <span className="cp-sr-only">{t('common.donateButtonInactive')}</span>
        </div>
      </>
    );
  }

  const classes =
    variant === 'link'
      ? classNames('cp-text-link')
      : classNames(
        'cp-btn w-full branded-text-neutral',
        {
          ['cp-btn-primary']:
            colorVariant === 'primary' && !isPrimaryBrandColorLight,
          ['cp-btn-brand']:
            colorVariant === 'brand' && !isPrimaryBrandColorLight,
          ['cp-btn-secondary']: isPrimaryBrandColorLight,
        },
        className,
      );

  return (
    <>
      <Element
        className={classes}
        href={!isButton ? buildDonateUrlWithQueryString() : undefined}
        target={!isButton ? '_blank' : undefined}
        onClick={onClick}
        id={id}
      >
        {children}
      </Element>
    </>
  );
};
