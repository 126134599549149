const TIMELINE_IMAGE_FRAGMENT = /* GraphQL */ `
  fragment TimelineImage on ImageMedia {
    __typename
    id
    url
  }
`;

const TIMELINE_ENTRY_FRAGMENT = /* GraphQL */ `
  ${TIMELINE_IMAGE_FRAGMENT}
  fragment TimelineEntry on TimelineEntry {
    id
    legacyId
    type
    message
    createdAt
    media {
      ...TimelineImage
    }
    fitnessActivity {
      title
      activityType
      mapPolyline
      distance(measurementSystem: $measurementSystem) {
        unit
        value
      }
      elevation(measurementSystem: $measurementSystem) {
        unit
        value
      }
      duration {
        unit
        value
      }
      steps
    }
  }
`;

export const GET_TIMELINE_QUERY = /* GraphQL */ `
  ${TIMELINE_ENTRY_FRAGMENT}
  query GetTimeline(
    $pageId: ID!
    $measurementSystem: MeasurementSystem
    $after: String
  ) {
    page( id: $pageId) {
      timeline(first: 3, after: $after) {
        nodes {
          ...TimelineEntry
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const GET_TIMELINE_TOTAL_QUERY = /* GraphQL */ `
  query GetTimelineTotal(
    $pageId: ID!
  ) {
    page(id: $pageId) {
      timeline {
        totalCount
      }
    }
  }
`;

export const CREATE_TIMELINE_ENTRY_MUTATION = /* GraphQL */ `
  ${TIMELINE_ENTRY_FRAGMENT}
  mutation CreateTimelineEntry(
    $input: CreateTimelineEntryInput!
    $measurementSystem: MeasurementSystem
  ) {
    createTimelineEntry(input: $input) {
      ...TimelineEntry
    }
  }
`;

export const DELETE_TIMELINE_ENTRY_MUTATION = /* GraphQL */ `
  mutation DeleteTimelineEntry($input: DeleteTimelineEntryInput!) {
    deleteTimelineEntry(input: $input)
  }
`;

export const GET_FITNESS_SETTINGS = /* GraphQL */ `
  fragment FitnessSettings on FitnessApplicationSettings {
    measurementSystem
  }

  query GetFitnessSettings($pageId: ID!) {
    page(id: $pageId) {
      application(type: FITNESS, name: "strava") {
        ... on FitnessApplication {
          settings {
            ...FitnessSettings
          }
        }
      }
    }
  }
`;
