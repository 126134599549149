import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { useCarouselContext } from '../Carousel/Carousel';
import { useState } from 'react';
import styles from './CarouselTrack.module.scss';

export function CarouselTrack() {
  const { activeTile, selectTile, items } = useCarouselContext();
  const [position, setPosition] = useState<number>(0);
  const [isSwiping, setIsSwiping] = useState<boolean>();
  const handlers = useSwipeable({
    onSwipedLeft: () => selectTile(activeTile + 1),
    onSwipedRight: () => selectTile(activeTile - 1),
    onSwiping: handleSwiping,
    onSwiped: handleSwiped,
    trackMouse: true,
  });
  function handleSwiping(event: SwipeEventData) {
    if (items.length === 1) {
      return;
    }

    setIsSwiping(true);
    setPosition(event.deltaX);
  }

  function handleSwiped() {
    setIsSwiping(false);
    setPosition(0);
  }

  return (
    <div className={styles.trackWrapper}>
      <div
        className={styles.track}
        style={{
          transform: `translateX(calc(-${activeTile * 100}% + ${position}px)`,
          transition: isSwiping ? 'none' : 'transform 250ms ease-in-out',
        }}
        {...handlers}
      >
        {items.map((item, index) => (
          <div
            className={styles.tileWrapper}
            key={`tile-${index}`}
            data-testid={index === activeTile ? 'track-item-active' : undefined}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
