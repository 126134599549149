'use client';

import { useAuth } from '../components/Providers/AuthProvider';
import { usePage } from '../components/Providers/PageProvider';

export function useCharityAdmin() {
  const auth = useAuth();
  const page = usePage();

  const activeCharityPermission = auth?.getActivePermission('charity');
  const isCharityAdmin =
    activeCharityPermission?.groupTypeExternalId != null &&
    page?.charity.externalId != null &&
    activeCharityPermission?.groupTypeExternalId ===
      page?.charity.externalId.toString() &&
    activeCharityPermission?.roles.includes('admin');

  return Boolean(auth?.isStaff()) || isCharityAdmin;
}
