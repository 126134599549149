'use client';

import { PropsWithChildren, useState } from 'react';

import dynamic from 'next/dynamic';
import { LightboxProps } from '../Lightbox/Lightbox';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

const Lightbox = dynamic(() =>
  import('../Lightbox/Lightbox').then((x) => x.Lightbox),
);

type LightboxOpenerProps = {
  label?: string;
} & Omit<LightboxProps, 'open' | 'onClose'> &
  PropsWithChildren;

// The purpose of this component is to provide interactivity while maintaining the children as a server component
export const LightboxOpener: React.FC<LightboxOpenerProps> = ({
  label,
  ...props
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  if (!props.images.length) {
    return <>{props.children}</>;
  }

  return (
    <>
     <ErrorBoundary>
      <button
        className="btn-unstyled w-full"
        onClick={() => setLightboxOpen(true)}
        aria-label={label}
      >
        {props.children}
      </button>
      {lightboxOpen && (
        <Lightbox
          {...props}
          open={lightboxOpen}
          onClose={() => setLightboxOpen(false)}
        />
      )}
      </ErrorBoundary>
    </>
  );
};
