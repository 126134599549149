import { useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const media = typeof window !== 'undefined' ? window.matchMedia(query) : null;
  const [matches, setMatches] = useState(media?.matches ?? false);

  useEffect(() => {
    function handleChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }

    if (media && media.addEventListener) {
      media.addEventListener('change', handleChange);
      return () => media.removeEventListener('change', handleChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}
