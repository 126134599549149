'use client';

import {
  DistanceUnit,
  FitnessApplicationActivityType,
} from '../../lib/graphql';

import classNames from 'classnames';
import { formatDistance } from '../../utils/formatters';
import { isLightColor } from '../../utils/colors';
import styles from './FitnessTotaliser.module.scss';
import { usePage } from '../Providers/PageProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ActivityType = FitnessApplicationActivityType | 'ALL';

type Activity = {
  type: ActivityType;
  totalValue: number;
  totalUnit: DistanceUnit | 'STEPS';
};

type FitnessTotaliserProps = {
  activities: Activity[];
};

export const FitnessTotaliser = ({ activities }: FitnessTotaliserProps) => {
  const { branding } = usePage();
  const [selectedActivity, setSelectedActivity] = useState<Activity>(
    activities[0],
  );

  const { t } = useTranslation();

  return (
    <div data-testid="fitness-totaliser">
      {activities.length > 1 && (
        <ul className={classNames(styles.activityList, 'list-unstyled')}>
          {activities.map((activity) => (
            <li key={activity.type}>
              <button
                className={classNames(styles.activityButton, 'cp-body-small', {
                  [styles.active]: selectedActivity?.type === activity.type,
                  [styles.light]: branding.primaryColor
                    ? isLightColor(branding.primaryColor)
                    : false,
                })}
                onClick={() => setSelectedActivity(activity)}
              >
                {t(`activity.${activity.type.toLowerCase()}`)}
              </button>
            </li>
          ))}
        </ul>
      )}
      <span>
        <strong className="cp-font-bold">
          {selectedActivity.totalUnit === 'STEPS'
            ? `${selectedActivity.totalValue} ${t(
                'activity.steps',
              ).toLowerCase()}`
            : formatDistance(
                selectedActivity.totalValue,
                selectedActivity.totalUnit,
              )}
        </strong>
        {selectedActivity.type === 'ALL' &&
          ` ${t('fitness.totals.completedOverall')}`}
      </span>
    </div>
  );
};
