'use client';

import React, { useState } from 'react';

import { CpToggleSwitch } from '@justgiving/carepack-forms';
import styles from './CoverVideo.module.scss';
import { useTranslation } from 'react-i18next';

export type CoverVideoProps = {
  videoURL: string;
  chatUrl?: string;
};

export const CoverVideo: React.FC<CoverVideoProps> = ({
  videoURL,
  chatUrl,
}) => {
  const { t } = useTranslation();

  const [chatVisible, setChatVisible] = useState(false);

  return (
    <div>
      <div className={styles.videoContainer}>
        <iframe
          src={videoURL}
          title={t('coverVideo.title')!}
          height="400"
          width="100%"
          allowFullScreen
          className={styles.video}
          frameBorder="0"
          scrolling="no"
        />
        {chatUrl && (
          <div className={styles.chatToggle}>
            <CpToggleSwitch
              label={
                chatVisible ? t('coverVideo.chatOn') : t('coverVideo.chatOff')
              }
              checked={chatVisible}
              onChange={() => setChatVisible((v) => !v)}
            />
          </div>
        )}
      </div>
      {chatUrl && chatVisible && (
        <iframe
          title={t('streamChat.title')!}
          src={chatUrl}
          height="320"
          width="100%"
          frameBorder="0"
          scrolling="no"
        />
      )}
    </div>
  );
};
