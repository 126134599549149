'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { DonateButton } from '../DonateButton/DonateButton';
import { Experiments } from '../../constants/experiments';
import { PopupBlockedModal } from '../PopupBlockedModal/PopupBlockedModal';
import { PopupRefocusModal } from '../PopupRefocusModal/PopupRefocusModal';
import { isMobileDevice } from '../../utils/mobile';
import { useBuildDonateUrlWithQueryString } from '../../hooks/useBuildDonateUrlWithQueryString';
import { useTrackEvent } from '@optimizely/react-sdk';
import { useTranslation } from 'react-i18next';
import getDonateUrl from '../../utils/donateUrl';
import { useDonationJwt } from '../Providers/DonationProvider';

type DonatePopupButtonProps = {
  id?: string;
  className?: string;
  colorVariant?: 'brand' | 'primary';
  variant?: 'link' | 'button';
  onClick?: () => void;
} & PropsWithChildren;

export const DonatePopupButton: React.FC<DonatePopupButtonProps> = ({
  id,
  children,
  className,
  colorVariant = 'primary',
  variant = 'button',
  onClick,
}) => {
  const { t } = useTranslation();

  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [popupBlockedModalOpen, setPopupBlockedModalOpen] = useState(false);
  const [track, clientReady] = useTrackEvent();
  const jwt = useDonationJwt();
  const { buildDonateUrlWithQueryString } = useBuildDonateUrlWithQueryString();
  const legacyDonateUrl = buildDonateUrlWithQueryString()
  const [donateUrl, setDonateUrl] = useState<string>(legacyDonateUrl);

  useEffect(() => {
    if (popupBlockedModalOpen) {
      try {
        getDonateUrl(jwt, legacyDonateUrl)
          .then((donateUrl) => {
            setDonateUrl(donateUrl)
          });
      } catch (e) {
        captureException(e);
      }
    }
  }, [jwt, legacyDonateUrl, popupBlockedModalOpen, popupWindow])

  function handleClick(event: React.MouseEvent) {
    if (clientReady) {
      if (onClick && typeof onClick === 'function') {
        onClick();
      }
      track(Experiments.TrackingEvents.giveNowClick);

      if (variant === 'link') {
        track(Experiments.TrackingEvents.donateNowAverageDonation);
      }
    }
    try {
      const legacyDonateUrlHeadless = buildDonateUrlWithQueryString(true)
      getDonateUrl(jwt, legacyDonateUrlHeadless)
        .then((donateUrl) => {
          if (isMobileDevice()) {
            window.open(donateUrl, '_blank');
          } else {
            showCheckoutPopup(donateUrl);
          }
        });
      event.preventDefault();
    } catch (error) {
      // Do nothing, fallback to the link's default behaviour
    }
  }

  function showCheckoutPopup(href: string) {
    const screenX = window.screenX;
    const screenY = window.screenY;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupHeight = 640;
    const popupWidth = 938;

    const popupLeft = screenX + (screenWidth / 2 - popupWidth / 2);
    const popupTop = screenY + (screenHeight / 2 - popupHeight / 2);
    const windowFeatures = `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`;

    const urlParams = new URLSearchParams(window.location.search);
    const popupWindow =
      urlParams.get('popupBlocked') === 'true'
        ? null
        : window.open(href, '_blank', windowFeatures);

    setPopupWindow(popupWindow);

    if (popupWindow) {
      setPopupWindow(popupWindow);
      popupWindow?.focus();
    } else {
      setPopupBlockedModalOpen(true);
    }
  }

  return (
    <>
      <DonateButton
        onClick={handleClick}
        colorVariant={colorVariant}
        className={className}
        id={id}
        variant={variant}
      >
        {children ?? t('giveNow')}
      </DonateButton>
      <PopupRefocusModal
        popupWindow={popupWindow}
        onClose={() => setPopupWindow(null)}
      />
      {popupBlockedModalOpen && (
        <PopupBlockedModal
          open
          onClose={() => setPopupBlockedModalOpen(false)}
          checkoutUrl={donateUrl}
        />
      )}
    </>
  );
};
