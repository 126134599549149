import React, { FunctionComponent } from 'react';

export const WalkIcon: FunctionComponent = () => {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.5C8.6 4.5 9.5 3.6 9.5 2.5C9.5 1.4 8.6 0.5 7.5 0.5C6.4 0.5 5.5 1.4 5.5 2.5C5.5 3.6 6.4 4.5 7.5 4.5ZM3.8 7.9L1 22H3.1L4.9 14L7 16V22H9V14.5L6.9 12.5L7.5 9.5C8.8 11 10.8 12 13 12V10C11.1 10 9.5 9 8.7 7.6L7.7 6C7.3 5.4 6.7 5 6 5C5.7 5 5.5 5.1 5.2 5.1L0 7.3V12H2V8.6L3.8 7.9Z"
        fill="#999999"
      />
    </svg>
  );
};
