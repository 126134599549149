import { ComponentProps, PropsWithChildren } from 'react';

import { CloseIcon } from '../Icons';
import classNames from 'classnames';
import styles from './Banner.module.scss';

type BannerProps = {
  children: React.ReactNode;
  variant?: 'info' | 'gradient';
};

export const Banner = ({ children, variant = 'info' }: BannerProps) => {
  return (
    <div className={classNames(styles.banner, styles[variant])}>{children}</div>
  );
};

const Content = ({ children }: PropsWithChildren) => (
  <div className={classNames(styles.content, 'cp-body-medium')}>{children}</div>
);

Banner.Content = Content;

const CloseButton = ({ className, ...props }: ComponentProps<'button'>) => (
  <button
    className={classNames('btn-unstyled', styles.closeButton, className)}
    {...props}
  >
    <CloseIcon width={20} height={20} />
  </button>
);
Banner.CloseButton = CloseButton;
