'use client';

import React, { FunctionComponent } from 'react';
import { SupportersList } from '../SupportersList/SupportersList';
import { Trans, useTranslation } from 'react-i18next';
import { Page } from '../../lib/pageData';
import { makePhotoUrl } from '../../utils/makePhotoUrl';
import { SIZE_136_136 } from '../../constants/imageTemplateStrings';
import { TeamFragment } from '../../lib/graphql';
import { teamFragment } from '../../lib/graphql/gqlPageDataQuery';
import { SupporterDetailsProps } from '../SupporterDetails/SupporterDetails';

const fetchNextPageQuery = /* GraphQL */ `
  ${teamFragment}

  query PeerGroupSupportersListNextPage($pageId: ID, $after: String!) {
    page(id: $pageId) {
      supporters: leaderboard(type: TEAMS, first: 5, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...Team
        }
      }
    }
  }
`;

type PeerGroupSupportersListProps = {
  page: Page;
};

export const PeerGroupSupportersList: FunctionComponent<
  PeerGroupSupportersListProps
> = ({ page }) => {
  const { t } = useTranslation();

  if (!page.teams) return null;

  const supporterCount = page.teams?.totalCount ?? 0;

  if (supporterCount === 0) return null;

  return (
    <SupportersList<TeamFragment[]>
      page={page}
      supporters={page.teams}
      supporterCount={supporterCount}
      fetchNextPageQuery={fetchNextPageQuery}
      supporterToSupporterDetailsMapper={mapPeerGroupsToSupporterDetails}
    >
      <SupportersList.Title>
        {t('supporters.peerGroup.title')}
      </SupportersList.Title>
      <SupportersList.Supporters variant="group" />
      <SupportersList.LoadMore>
        <Trans i18nKey="supporters.peerGroup.showMore">
          Show more
          <span className="cp-sr-only"> teams</span>
        </Trans>
      </SupportersList.LoadMore>
      <SupportersList.Error>
        {t('supporters.showMoreError')}
      </SupportersList.Error>
    </SupportersList>
  );
};

const mapPeerGroupsToSupporterDetails = (
  teams?: TeamFragment[] | null,
): ({ id: string } & SupporterDetailsProps)[] => {
  return (teams ?? []).map((team) => ({
    id: team.legacyId,
    userName: team.title,
    image: team.owner?.avatar
      ? makePhotoUrl(team.owner.avatar, SIZE_136_136)
      : undefined,
    currencyCode: team.donationSummary?.totalAmount?.currencyCode ?? 'GBP',
    donationAmount: (team.donationSummary?.totalAmount?.value ?? 0) / 100,
    target: (team.targetWithCurrency?.value ?? 0) / 100,
    groupSupporterCount: team.donationSummary?.aggregatedDonationsCount ?? 0,
    slug: team.slug,
    isCancelled: team.status === 'CANCELLED',
  }));
};
