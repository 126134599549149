export enum ProductNames {
  Campaign = 'campaign',
  Event = 'event',
  ProxyEvent = 'proxy_event',
  ProxyTeam = 'proxy_team',
  Fundraising = 'fundraising',
  ProxyFundraising = 'proxy_fundraising',
  Team = 'team',
  InMemoryTeam = 'in_memory_team',
  Crowdfunding = 'crowdfunding',
}
